var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useContext } from "react";
import Button from "./Button";
import { httpPost } from "../utils/requests";
import { SnackbarContext } from "../context/snackbarContext";
import { GoogleIdentityContext } from "../context/googleIdentityContext";
export default function CheckoutButton({ style, moduleId, children, }) {
    const { pushToast } = useContext(SnackbarContext);
    const { tryWithTokenRefresh } = useContext(GoogleIdentityContext);
    const [buttonLoading, setButtonLoading] = useState(false);
    const purchaseModule = tryWithTokenRefresh(() => __awaiter(this, void 0, void 0, function* () {
        setButtonLoading(true);
        const { data } = yield httpPost("/purchase", { moduleId: moduleId, redirectTo: window.location.href });
        if (data.checkoutUrl) {
            window.location = data.checkoutUrl;
        }
    }), {
        onError: () => {
            pushToast({
                level: "error",
                message: "Woops! Something went wrong!",
            });
            setButtonLoading(false);
        }
    });
    return (React.createElement(Button, { label: "checkout", isLoading: buttonLoading, onClick: purchaseModule, style: style }, children));
}
