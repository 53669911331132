import styled, { keyframes } from "styled-components";
import Button from "../Button/Button";
import { theme } from "../../theme";
const rise = () => keyframes `
  from {
    transform: translateY(100vh);
  }
  
  to {
    transform: translateY(15vh);
  }
`;
export const MenuDialog = styled.div `
  background-color: ${p => p.theme.primaryColor};
  z-index: 2005;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 85vh;
  opacity: ${p => (p.open ? "1" : "0")};
  display: ${p => (p.open ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-around;
  transform: translateY(15vh);
  border-radius: 1.5rem 1.5rem 0 0;
  box-shadow: 0 -1.rem #000000;

  animation-delay: 0.5s;
  animation: ${rise} 0.5s ease-in 1;
`;
export const CircularMenuButton = styled(Button) `
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1rem;
  right: 1rem;
  border-radius: 4rem;
  width: 4rem;
  height: 4rem;
  padding: 0;
  z-index: 2004;

  > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const mobilePhoneStyle = {
    width: "8rem",
    fill: theme.secondaryColor,
    marginBottom: "0.75rem",
};
export const WindowSizeWarningContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${p => p.theme.colors["white"]};
  gap: 0.75rem;
  padding: 0 1rem;
`;
export const DisclaimerHeader = styled.h1 `
  font-size: ${p => p.theme.fontSizes.jumbo};
  font-weight: bold;
  line-height: ${p => p.theme.fontSizes.jumbo};
  margin: 0;
`;
export const DisclaimerSubHeader = styled.h2 `
  font-size: ${p => p.theme.fontSizes.large};
  margin: 0;
`;
export const Explanation = styled.h3 `
  font-size: ${p => p.theme.fontSizes.tiny};
  color: ${p => p.theme.colors["white"]};
  padding: 0 2.25rem;
  margin: 0;
`;
export const Backdrop = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  z-index: 10000;
`;
