import React, { useContext, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { NodeBase, Label, SubLabel, NodeLogo } from "./styled";
import { NodeStatuses } from "./types";
import { theme } from "../../theme";
import StatusIndicator from "./StatusIndicator";
import { UserContext } from "../../context/userContext";
import { capitalize, logTrackingFail } from "../../utils/constants";
import { Availabilities } from "../../types";
import { SnackbarContext } from "../../context/snackbarContext";
export default function Node({ linkTo, label, labelPosition, radius, x, y, svgName, showStatus = true, onFocus, onBlur, availability, unpurchased, }) {
    const { nodeStatus, userState } = useContext(UserContext);
    const { pushToast } = useContext(SnackbarContext);
    const navigate = useNavigate();
    const nav = useCallback(() => linkTo && navigate(linkTo), [navigate, linkTo]);
    const status = useMemo(() => {
        return (showStatus ? unpurchased ? NodeStatuses.locked : nodeStatus(linkTo) : NodeStatuses.seen);
    }, [linkTo, showStatus, userState, nodeStatus]);
    const subLabel = useMemo(() => {
        if (availability && availability !== Availabilities.available)
            return capitalize(availability);
        if (unpurchased)
            return "Unpurchased";
        return null;
    }, [availability, unpurchased, userState]);
    const focusColor = useMemo(() => {
        if (availability === Availabilities.comingNext || availability === Availabilities.comingSoon)
            return "purple";
        if (unpurchased)
            return "magenta";
        return "blueLight";
    }, [availability, unpurchased, userState]);
    const unfocusColor = useMemo(() => {
        if (unpurchased || (availability && availability !== Availabilities.available))
            return "grey";
        return "greyLight";
    }, [availability, unpurchased, userState]);
    const R = useMemo(() => radius !== null && radius !== void 0 ? radius : theme.defaultNodeRadius, [radius]);
    const X = useMemo(() => (isNaN(x) ? null : x - R), [x, R]);
    const Y = useMemo(() => (isNaN(y) ? null : y - R), [y, R]);
    const _onFocus = useCallback((event) => {
        var _a;
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(event);
        (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.track(`node "${label}" focused`);
    }, [onFocus]);
    const _onBlur = useCallback((event) => {
        var _a;
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
        try {
            (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.track(`node "${label}" blurred`);
        }
        catch (ampErr) {
            logTrackingFail();
        }
    }, [onBlur]);
    const onClick = useCallback((event) => {
        if (availability === Availabilities.comingNext || availability === Availabilities.comingSoon) {
            const message = availability === Availabilities.comingNext ? "I'm working on this content now. Check back soon." : "This content is on my roadmap and I will worked on soon.";
            pushToast({
                level: "info",
                message,
            });
            event.currentTarget.blur();
        }
        else {
            nav();
        }
    }, [nav, availability]);
    return (React.createElement(NodeBase, { onFocus: _onFocus, onBlur: _onBlur, onClick: onClick, onMouseEnter: _onFocus, onMouseLeave: _onBlur, radius: R, x: X, y: Y, "aria-label": label, "data-cy": `node-${label}`, title: label, focusColorName: focusColor, unfocusColorName: unfocusColor },
        React.createElement(Label, { hasSubLabel: !!subLabel, onClick: onClick, radius: R, labelPosition: labelPosition !== null && labelPosition !== void 0 ? labelPosition : "E" /* Positions.E */, focusColorName: focusColor, unfocusColorName: unfocusColor },
            label,
            subLabel ? React.createElement(SubLabel, null,
                "(",
                subLabel,
                ")") : null,
            React.createElement(StatusIndicator, { onClick: onClick, status: status, "aria-label": status, title: status, labelPosition: labelPosition !== null && labelPosition !== void 0 ? labelPosition : "E" /* Positions.E */ })),
        !!svgName ? React.createElement(NodeLogo, { size: R, svgName: svgName }) : null));
}
