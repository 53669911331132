var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useCallback, useState, useMemo } from "react";
import { httpPost } from "../../utils/requests";
import { SnackbarContext } from "../../context/snackbarContext";
import { GoogleIdentityContext } from "../../context/googleIdentityContext";
import Button from "../Button";
import { BoardsContext } from "../../context/boardsContext";
export default function MakeKanbanBoard({ boardId }) {
    const { pushToast } = useContext(SnackbarContext);
    const { tryWithTokenRefresh } = useContext(GoogleIdentityContext);
    const { boards, getBoards } = useContext(BoardsContext);
    const [loading, setLoading] = useState(false);
    const cloned = useMemo(() => boardId in boards, [boardId, boards]);
    const createBoard = useCallback(tryWithTokenRefresh(() => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        yield httpPost(`/board/${boardId}`);
        getBoards();
        pushToast({
            level: "success",
            message: "Board cloned",
        });
    }), {
        onError: (error) => {
            var _a;
            pushToast({
                level: "error",
                message: ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data)
                    ? error.response.data
                    : "Failed to clone board",
            });
        },
        onEnd: () => setLoading(false)
    }), [boardId]);
    return cloned ? (React.createElement(Button, { label: "go to board", linkTo: `/boards/${boardId}` },
        "Go to ",
        boardId,
        " board")) : (React.createElement(Button, { isLoading: loading, label: "create board", onClick: createBoard },
        "Clone ",
        boardId,
        " board"));
}
