export function preprocessRawMarkdown(rawMarkdown) {
    let processedMarkdown = "";
    const quoteStack = [];
    for (let i = 0; i < rawMarkdown.length; i++) {
        if (rawMarkdown[i] === "`" && rawMarkdown[i + 1] === "`" && rawMarkdown[i + 2] === "`") {
            if (quoteStack[quoteStack.length - 1] === "```")
                quoteStack.pop();
            else
                quoteStack.push("```");
        }
        else if ("`\"".includes(rawMarkdown[i])) {
            if (quoteStack[quoteStack.length - 1] === rawMarkdown[i])
                quoteStack.pop();
            else
                quoteStack.push(rawMarkdown[i]);
        }
        if (rawMarkdown[i] === "<" && quoteStack.includes("```"))
            processedMarkdown += "&lt;";
        else if (rawMarkdown[i] === ">" && quoteStack.includes("```"))
            processedMarkdown += "&gt;";
        else
            processedMarkdown += rawMarkdown[i];
    }
    return processedMarkdown;
}
