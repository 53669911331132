import styled from "styled-components";
import CopyToClipboard from "../Logos/CopyToClipboard";
export const CopyableContainer = styled.div `
  position: relative;
  background-color: ${p => p.theme.colors["greyDark"]};
  border-radius: 5px;
  padding: 0;
  margin: 0;
`;
export const ClipboardLogo = styled(CopyToClipboard) `
  left: calc(100% - 8px);
  top: 8px;
  position: absolute;
  z-index: 1000;
  transform: translateX(-100%);
`;
