import styled from "styled-components";
import { MAX_MOBILE_SCREEN_SIZE } from "../../utils/constants";
import Button from "../Button";
export const Drawer = styled.div `
  position: fixed;
  z-index: 12345;
  right: ${p => p.open ? 0 : 'calc(0px - 4rem)'};
  top: 0;
  width: ${p => p.open ? "85vw" : 0};
  height: 100vh;
  padding: 2rem;
  transition: width .1s ease-out;
  overflow-x: none;
  background-color: ${p => p.theme.colors.black};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: ${p => p.open ? '0px 0 20px 0px rgb(20,20,20)' : 'none'};

  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    display: none;
    opacity: 0;
  }
`;
export const Crossbar = styled.div `
  height: 2px;
  width: 1.1rem;
  border-radius: 10%;
  transform: rotate(-45deg);
  background-color: ${p => p.theme.colors.white};
`;
export const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  height: 100%;
  justify-content: space-between;

  .cm-editor {
    min-height: 57vh;
    background-color: ${p => p.theme.primaryColor};
    outline: solid 1px ${p => p.theme.colors.white};
    border-radius: .25rem;

    * {
      font-family: "Source Code Pro";
      font-size: 16px;
      font-weight: 400;
    }
  }
  
  .cm-gutters {
    background-color: transparent;
    border-right-color: transparent;
  }

  .cm-def {
    color: ${p => p.theme.colors.greenNeon};;
  }
  
  .cm-content {
    padding: 0.25rem 0;
  }
`;
export const EditorSection = styled.div `
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
export const CodeButtons = styled.div `
  display: flex;
  gap: 0.75rem;
`;
export const StdoutText = styled.p `
  margin: 0;
  color: ${p => p.theme.colors.white};
  font-size: ${p => p.theme.fontSizes.tiny};
  font-family: "Source Code Pro";
  font-weight: 400;
`;
export const ErrorText = styled.p `
  margin: 0;
  font-size: ${p => p.theme.fontSizes.tiny};
  color: ${p => p.theme.colors.red};
  font-family: "Source Code Pro";
  font-weight: 400;
`;
export const OutputArea = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;
  height: 25vh;
  overflow-y: auto;
  background-color: ${p => p.theme.colors.blackDark};
  border-radius: .25rem;
`;
export const ErrorOutput = styled.div `
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  align-items: start;
`;
export const OutputControls = styled.div `
  position: sticky;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  top: 0;
  width: 100%;
  z-index: 10;
  padding: 0.5rem 1rem;
  background-color: ${p => p.theme.colors.greyDark};
`;
export const OutputMain = styled.div `
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  align-items: start;
  padding: 0.5rem;
  padding-left: 1rem;
`;
export const ClearOutputButton = styled(Button) `
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  &:hover * * {
    background-color: ${p => p.theme.colors.blueLight} !important;
  }
`;
