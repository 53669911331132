import React from "react";
import styled from "styled-components";
const RotatedSVG = styled.svg `
  transform: rotate(45deg) translate(-5%, -10%);
`;
export default function Check({ style }) {
    return (React.createElement(RotatedSVG, { style: style, viewBox: "0 0 300 462.637", width: "300", height: "462.637" },
        React.createElement("path", { d: "M 255.388 0 H 255.388 A 44.611 50 0 0 1 300 50 V 410.74 A 44.611 50 0 0 1 255.388 460.74 H 255.388 A 44.611 50 0 0 1 210.777 410.74 V 50 A 44.611 50 0 0 1 255.388 0 Z" }),
        React.createElement("path", { d: "M 255.388 0 H 255.388 A 44.611 50 0 0 1 300 50 V 250 A 44.611 50 0 0 1 255.388 300 H 255.388 A 44.611 50 0 0 1 210.777 250 V 50 A 44.611 50 0 0 1 255.388 0 Z", transform: "matrix(0, 1, -1, 0, 300, 162.636551)" })));
}
