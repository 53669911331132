import { useLayoutEffect, useState } from "react";
import { MAX_MOBILE_SCREEN_SIZE } from "../utils/constants";
const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
    const updateSize = () => {
        const nextIsMobile = window.innerWidth < MAX_MOBILE_SCREEN_SIZE;
        setIsMobile(nextIsMobile);
    };
    useLayoutEffect(() => {
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return isMobile;
};
export default useIsMobile;
