import { useCallback, useEffect, useState } from "react";
const useDelayedState = (initialState) => {
    const [state, setState] = useState(initialState);
    const [theTimeout, setTheTimeout] = useState();
    const delayedSetState = useCallback((nextState, milliseconds) => {
        setTheTimeout(setTimeout(() => {
            setState(nextState);
        }, milliseconds));
    }, []);
    useEffect(() => () => theTimeout && clearTimeout(theTimeout), []);
    return [state, delayedSetState];
};
export default useDelayedState;
