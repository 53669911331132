import styled from "styled-components";
import { hexWithOpacity } from "../../utils/constants";
export const LogoAndCopy = styled.div `
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
`;
export const CTAcopy = styled.p `
  font-size: ${p => p.theme.fontSizes.small};
  color: ${p => p.theme.fontColor};
  margin: 0;
`;
export const CTAcontainer = styled.div `
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 2rem;
  max-width: 58rem;
  transition: all 0.2s ease-in;
  height: ${p => (!p.visible ? 0 : "auto")};
  display: ${p => p.visible ? 'flex' : 'none'};
  margin: ${p => (!p.visible ? 0 : "0 auto 3rem")};

  background-color: ${p => hexWithOpacity(p.theme.colors.magentaSoft, .15)};
  border-radius: 0.5rem;
  outline: 4px solid ${p => p.theme.colors.magentaSoft};
`;
export const CTAarea = styled.div `
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
`;
export const buttonStyle = {
    width: '11rem'
};
