import React, { useState } from "react";
import { QAContainer, QuestionContainer, Delimiter, AnswerContainer, ExpandIcon } from "./styled";
import { theme } from "../../theme";
import useSizeAndPosition from "../../hooks/useSizeAndPosition";
export default function QandA({ question, answer, style, }) {
    const [answerRevealed, setAnswerRevealed] = useState(false);
    const { height, setNode } = useSizeAndPosition();
    return (React.createElement(QAContainer, { style: style },
        React.createElement(QuestionContainer, { onClick: () => setAnswerRevealed(!answerRevealed) },
            question,
            React.createElement(ExpandIcon, { open: answerRevealed },
                React.createElement(MUIicon, { color: theme.colors.white }))),
        React.createElement(AnswerContainer, { open: answerRevealed, answerHeight: height },
            React.createElement("div", { style: { margin: 0, padding: "0 0 .5rem 0" }, ref: setNode },
                React.createElement(Delimiter, null),
                answer))));
}
function MUIicon({ color }) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", height: "24px", viewBox: "0 0 24 24", width: "24px", fill: color },
        React.createElement("path", { d: "M0 0h24v24H0V0z", fill: "none" }),
        React.createElement("path", { d: "M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" })));
}
