import React from "react";
import { SelectContainer, StyledSelect, Option } from "./styled";
import { logTrackingFail } from "../../utils/constants";
export default function Select(props) {
    const _onChange = (event) => {
        var _a;
        try {
            (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.track(`Select "${props.label}" value changed to ${event.target.value}`);
        }
        catch (ampErr) {
            logTrackingFail();
        }
        if (props.onChange)
            props.onChange(event);
    };
    return (React.createElement(SelectContainer, null,
        React.createElement(StyledSelect, Object.assign({ onChange: _onChange }, props, { value: props.value }), props.options.map((option, i) => (React.createElement(Option, { key: i, value: option }, option))))));
}
