import React, { useContext, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import BreadCrumbs from "../BreadCrumbs";
import { PaywallModalContext } from "../../context/paywallContext";
import { useLocation, useParams } from "react-router-dom";
import Paywall from "../Paywall";
import { Container, OpenCodeEditorButton, BreadCrumbsAndSearchContainer } from "./styled";
import CodeEditorDrawer from "../CodeEditorDrawer";
import Code from "../Logos/Code";
import ContentSearchBar from "../ContentSearchBar";
import { ContentTypes } from "../../context/contentContext";
const encircledLogoStyles = {
    width: "66%",
    height: "66%",
    display: "flex",
    flexShrink: 0,
};
export default function PageWrapper({ children, showCodeEditorButton, allowSearch, style, }) {
    const { isOpen: paywallActive } = useContext(PaywallModalContext);
    const { moduleId, unitId } = useParams();
    const { pathname } = useLocation();
    const [editorOpen, setEditorOpen] = useState(false);
    const relevantBreadCrumbs = useMemo(() => ["Dev Yourself"].concat(pathname.split("/").filter((_, i, a) => i === 1 || i === a.length - 1)).join(" | ").replace(/\|$/, ""), [pathname]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, relevantBreadCrumbs),
            React.createElement("link", { rel: "canonical", href: window.location.href })),
        React.createElement(Container, { style: style },
            React.createElement(BreadCrumbsAndSearchContainer, null,
                React.createElement(BreadCrumbs, null),
                allowSearch && React.createElement(ContentSearchBar, { searchContentType: moduleId ? ContentTypes.unit : ContentTypes.module, searchWithinModuleId: moduleId })),
            showCodeEditorButton && React.createElement(OpenCodeEditorButton, { label: "open editor", onClick: () => setEditorOpen(true) },
                React.createElement(Code, { style: encircledLogoStyles })),
            paywallActive ? React.createElement(Paywall, { moduleId: moduleId, unitId: unitId }) : children,
            React.createElement(CodeEditorDrawer, { editorOpen: editorOpen, closeEditor: () => setEditorOpen(false) }))));
}
