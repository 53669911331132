var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useContext, useMemo, useState, useEffect, useRef, useCallback, } from "react";
import { requestConfig } from "../utils/requests";
import { SnackbarContext } from "./snackbarContext";
import Button from "../components/Button";
import styled from "styled-components";
import { logErrorReportFail } from "../utils/constants";
const DefaultGoogleIdentity = undefined;
const GOOGLE_SCRIPT_ID = "google-identity-script";
const StyledAuthButton = styled.div `
  width: max-content;
  max-width: 175px;
  height: 32.01px;
`;
const serializeCallbackWithArgs = (callback, optionalCallbacks, args) => `${callback.toString()}(${args.toString()}) || ${Object.values(optionalCallbacks !== null && optionalCallbacks !== void 0 ? optionalCallbacks : {}).map((callback) => callback.toString()).join(" && ")}`;
const requestQueue = {};
export const GoogleIdentityContext = createContext(DefaultGoogleIdentity);
export const GoogleIdentityProvider = ({ children }) => {
    const { pushToast } = useContext(SnackbarContext);
    const [idToken, setIdToken] = useState();
    const loggedIn = useMemo(() => !!idToken, [idToken]);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const tryWithTokenRefresh = useCallback((callback, optionalCallbacks) => (...args) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b, _c, _d;
        try {
            yield callback(...args);
            if (optionalCallbacks === null || optionalCallbacks === void 0 ? void 0 : optionalCallbacks.onSuccess)
                yield optionalCallbacks.onSuccess(...args);
        }
        catch (error) {
            if (((_b = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.statusCode) == "403" &&
                ((_d = (_c = error === null || error === void 0 ? void 0 : error.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message) === "Expired token") {
                requestQueue[serializeCallbackWithArgs(callback, optionalCallbacks, args)] = [callback, optionalCallbacks, args];
                window.google.accounts.id.prompt();
            }
            else {
                if (optionalCallbacks === null || optionalCallbacks === void 0 ? void 0 : optionalCallbacks.onError)
                    optionalCallbacks.onError(error);
                try {
                    Bugsnag === null || Bugsnag === void 0 ? void 0 : Bugsnag.notify(error, (event) => {
                        event.context = "function was attempted with token refresh.";
                        event.addMetadata("arguments", args);
                    });
                }
                catch (_e) {
                    logErrorReportFail();
                }
            }
        }
        if (optionalCallbacks === null || optionalCallbacks === void 0 ? void 0 : optionalCallbacks.onEnd)
            yield optionalCallbacks.onEnd(...args);
    }), [scriptLoaded, idToken]);
    useEffect(() => {
        Object.entries(requestQueue).forEach(([key, [callback, optionalCallbacks, args]]) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            try {
                yield callback(...args);
                if (optionalCallbacks === null || optionalCallbacks === void 0 ? void 0 : optionalCallbacks.onSuccess)
                    optionalCallbacks.onSuccess(...args);
            }
            catch (error) {
                if (optionalCallbacks === null || optionalCallbacks === void 0 ? void 0 : optionalCallbacks.onError)
                    optionalCallbacks.onError();
                try {
                    Bugsnag === null || Bugsnag === void 0 ? void 0 : Bugsnag.notify(error, (event) => {
                        event.context = "function was attempted after token refresh.";
                    });
                }
                catch (_e) {
                    if (optionalCallbacks === null || optionalCallbacks === void 0 ? void 0 : optionalCallbacks.onEnd)
                        optionalCallbacks.onEnd(...args);
                }
            }
            (_a = optionalCallbacks.onEnd) === null || _a === void 0 ? void 0 : _a.call(optionalCallbacks, ...args);
            delete requestQueue[key];
        }));
    }, [idToken]);
    const onCredentialResp = useCallback((nonce) => (resp) => {
        setIdToken(resp.credential);
        requestConfig.defaults.headers.common[AUTH_HEADER] = `Bearer ${resp.credential}`;
        requestConfig.defaults.headers.common.nonce = nonce;
        pushToast({
            level: "success",
            message: "Logged in",
        });
    }, [setIdToken]);
    const signOut = useCallback(() => {
        window === null || window === void 0 ? void 0 : window.google.accounts.id.disableAutoSelect();
        setIdToken(undefined);
        pushToast({
            level: "success",
            message: "Logged out",
        });
    }, []);
    const GoogleAuthButton = useCallback((props) => {
        const googleButtonRef = useRef();
        useEffect(() => {
            var _a;
            if (!loggedIn && scriptLoaded) {
                (_a = window.google) === null || _a === void 0 ? void 0 : _a.accounts.id.renderButton(googleButtonRef.current, {
                    theme: "outline",
                    shape: "pill",
                    size: "medium",
                });
            }
        }, [loggedIn, scriptLoaded]);
        return loggedIn ? (React.createElement(Button, Object.assign({}, props, { label: "sign out", onClick: signOut }), "Sign out")) : ENV === "test" /* Envs.test */ ? (React.createElement(Button, Object.assign({}, props, { label: "sign in", onClick: () => {
                pushToast({
                    message: "signed in (test mode)",
                    level: "success",
                });
                setIdToken("test");
            } }), "Sign in")) : (React.createElement(StyledAuthButton, Object.assign({}, props, { ref: googleButtonRef })));
    }, [loggedIn, scriptLoaded]);
    useEffect(() => {
        if (!window.google) {
            const arr = new Uint8Array(20);
            window.crypto.getRandomValues(arr);
            const nonce = Array.from(arr, dec => dec.toString(16).padStart(2, "0")).join("");
            const script = document.createElement("script");
            script.id = GOOGLE_SCRIPT_ID;
            script.src = "https://accounts.google.com/gsi/client";
            script.async = true;
            script.onload = () => {
                window.google.accounts.id.initialize({
                    client_id: GOOGLE_ID_CLIENT_ID,
                    callback: onCredentialResp(nonce),
                    context: "signin",
                    auto_select: true,
                    nonce: nonce,
                    ux_mode: "popup",
                });
                setScriptLoaded(true);
            };
            document.body.appendChild(script);
        }
        return () => {
            const script = document.getElementById(GOOGLE_SCRIPT_ID);
            if (script)
                document.body.removeChild(script);
        };
    }, []);
    useEffect(() => {
        if (scriptLoaded)
            window.google.accounts.id.prompt();
    }, [scriptLoaded]);
    const values = useMemo(() => ({
        idToken,
        loggedIn,
        signOut,
        tryWithTokenRefresh,
        GoogleAuthButton,
    }), [idToken, setIdToken, signOut, scriptLoaded]);
    return React.createElement(GoogleIdentityContext.Provider, { value: values }, children);
};
