import styled from "styled-components";
import Button from "../../components/Button";
import { MAX_MOBILE_SCREEN_SIZE } from "../../utils/constants";
export const ColumnsContainer = styled.div `
  display: flex;
  justify-content: space-evenly;
  gap: 2rem;
  height: calc(100vh - 18.2rem);

  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    height: calc(100vh - 4rem);
  }
`;
export const ColumnContainer = styled.div `
  width: 33.3%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const Column = styled.div `
  width: 100%;
  height: 100%;
  overflow-y: auto;
  display: flex;
  border-radius: 0.5rem;
  background-color: ${p => p.beingDraggedOver ? p.theme.colors["blackDark"] : p.theme.primaryColor};
  flex-direction: column;
  gap: 0.5rem;
  transition: background-color 0.1s ease-out;
`;
export const ColumnHeaderTitle = styled.h2 `
  font-size: ${p => p.theme.fontSizes.large};
  color: ${p => p.theme.fontColor};
  margin: 0;
`;
export const ColumnHeader = styled.div `
  display: flex;
  gap: 0.65rem;
  align-items: center;
`;
export const AddTicketAction = styled(Button) `
  height: 1.1rem;
  width: 1.1rem;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 66%;
    height: 66%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const BoardTitleArea = styled.div `
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
`;
const statusColor = (p) => p.theme.colors[p.status === "todo"
    ? "yellowBright"
    : p.status === "doing"
        ? "blueIntense"
        : p.status === "done"
            ? "green"
            : "red"];
export const StatusIndicator = styled.div `
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin: 0.5rem;
  background-color: ${statusColor};
  box-shadow: 0 0 6px ${statusColor}, 0 0 6px ${statusColor};
`;
export const BoardTitle = styled.div `
  font-size: ${p => p.theme.fontSizes.huge};
  color: ${p => p.theme.fontColor};
  width: fit-content;
  margin: 0 0.5rem 0 0;
`;
