import styled from "styled-components";
import Button from "../Button";
export const BoardCardContainer = styled.div `
  width: 100%;
  display: flex;
  background-color: ${p => p.theme.accentColor};
  border-radius: 0.5rem;
  justify-content: space-between;
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0px 2px 2px ${p => p.theme.colors["blackDark"]};
  transition: all 0.15s ease-out;

  &:hover {
    box-shadow: 0 8px 6px ${p => p.theme.colors["blackDark"]};
    transform: translateY(-4px);
  }
`;
export const Title = styled.div `
  font-size: ${p => p.theme.fontSizes.large};
  color: ${p => p.theme.fontColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const Path = styled.div `
  font-size: ${p => p.theme.fontSizes.medium};
  color: ${p => p.theme.fontColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const Status = styled.div `
  font-size: ${p => p.theme.fontSizes.medium};
  color: ${p => p.theme.linkColor};
  backgound-color: ${p => p.theme.secondaryColor};
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const StatusSection = styled.div `
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;
export const InfoSection = styled.div `
  display: flex;
  flex-direction: column;
`;
export const DeleteAction = styled(Button) `
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 66%;
    height: 66%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
