export var Colors;
(function (Colors) {
    Colors["black"] = "#212121";
    Colors["blackDark"] = "#0f0f0f";
    Colors["grey"] = "#4d4d4d";
    Colors["greyDark"] = "#333333";
    Colors["greyLight"] = "#858585";
    Colors["codeEditorGrey"] = "#3c3c3c";
    Colors["blueLight"] = "#6cdae8";
    Colors["blueMuted"] = "#549bb9";
    Colors["blueIntense"] = "#006eff";
    Colors["blueIntenseLight"] = "#4499ff";
    Colors["blueDark"] = "#091063";
    Colors["greenLight"] = "#32d08e";
    Colors["green"] = "#3cc63e";
    Colors["greenDark"] = "#1f6e20";
    Colors["greenNeon"] = "#76ee00";
    Colors["greenBlue"] = "#33CC99";
    Colors["red"] = "#f15f61";
    Colors["redSoft"] = "#FF6F77";
    Colors["magenta"] = "#fc1baa";
    Colors["magentaSoft"] = "#e937a7";
    Colors["yellowMuted"] = "#e1df4d";
    Colors["yellowBright"] = "#fffd38";
    Colors["yellowNeon"] = "#FFFF00";
    Colors["white"] = "#c6ccca";
    Colors["whiteBright"] = "#ffffff";
    Colors["beige"] = "#BBAA99";
    Colors["salmon"] = "#e06959";
    Colors["orange"] = "#f77214";
    Colors["darkOrange"] = "#f55543";
    Colors["purple"] = "#bb77ff";
    Colors["purpleSoft"] = "#a117ad";
    Colors["lavender"] = "#cbc4ff";
    Colors["pink"] = "#ff00aa";
})(Colors || (Colors = {}));
const colors = {
    blackDark: Colors.blackDark,
    black: Colors.black,
    greyDark: Colors.greyDark,
    grey: Colors.grey,
    greyLight: Colors.greyLight,
    codeEditorGrey: Colors.codeEditorGrey,
    blueLight: Colors.blueLight,
    blueMuted: Colors.blueMuted,
    blueIntense: Colors.blueIntense,
    blueIntenseLight: Colors.blueIntenseLight,
    blueDark: Colors.blueDark,
    greenLight: Colors.greenLight,
    green: Colors.green,
    greenDark: Colors.greenDark,
    greenNeon: Colors.greenNeon,
    greenBlue: Colors.greenBlue,
    red: Colors.red,
    redSoft: Colors.redSoft,
    magenta: Colors.magenta,
    magentaSoft: Colors.magentaSoft,
    yellowMuted: Colors.yellowMuted,
    yellowBright: Colors.yellowBright,
    yellowNeon: Colors.yellowNeon,
    white: Colors.white,
    whiteBright: Colors.white,
    beige: Colors.beige,
    salmon: Colors.salmon,
    orange: Colors.orange,
    darkOrange: Colors.darkOrange,
    purple: Colors.purple,
    purpleSoft: Colors.purpleSoft,
    lavender: Colors.lavender,
    pink: Colors.pink,
};
const pallet = {
    primaryColor: Colors.black,
    accentColor: Colors.greyDark,
    secondaryColor: Colors.greyLight,
    fontColor: Colors.white,
    linkColor: Colors.blueLight,
};
// see: https://isotropic.co/tool/hex-color-to-css-filter
/**
 * Also:
 * run this function in the console on the page above to arrive at a perfect result
 *
function findPerfect() {
    const button = document.getElementById("compute-btn");
    button.click();
    const text = document.getElementsByClassName("lossDetail")[0].innerText;
    if (text.includes(" 0.0")) return;
    else {
        button.click();
        findPerfect();
    };
}
 */
const colorFilters = {
    primaryColor: "invert(0%) sepia(70%) saturate(829%) hue-rotate(9deg) brightness(65%) contrast(88%)",
    accentColor: "invert(16%) sepia(0%) saturate(0%) hue-rotate(212deg) brightness(97%) contrast(86%)",
    secondaryColor: "invert(52%) sepia(0%) saturate(0%) hue-rotate(178deg) brightness(100%) contrast(89%)",
    fontColor: "invert(96%) sepia(8%) saturate(98%) hue-rotate(109deg) brightness(83%) contrast(94%)",
    linkColor: "invert(72%) sepia(81%) saturate(260%) hue-rotate(137deg) brightness(95%) contrast(91%)",
    black: "invert(11%) sepia(0%) saturate(0%) hue-rotate(327deg) brightness(100%) contrast(95%)",
    "blackDark": "invert(0%) sepia(4%) saturate(87%) hue-rotate(80deg) brightness(94%) contrast(88%)",
    grey: "invert(28%) sepia(0%) saturate(0%) hue-rotate(283deg) brightness(99%) contrast(87%)",
    "greyDark": "invert(20%) sepia(0%) saturate(0%) hue-rotate(166deg) brightness(95%) contrast(96%)",
    "greyLight": "invert(56%) sepia(0%) saturate(0%) hue-rotate(192deg) brightness(94%) contrast(88%)",
    "codeEditorGrey": "invert(27%) sepia(3%) saturate(0%) hue-rotate(238deg) brightness(84%) contrast(98%)",
    "blueLight": "invert(72%) sepia(81%) saturate(260%) hue-rotate(137deg) brightness(95%) contrast(91%)",
    "blueMuted": "invert(55%) sepia(51%) saturate(401%) hue-rotate(152deg) brightness(93%) contrast(87%)",
    "blueIntense": "invert(26%) sepia(70%) saturate(4200%) hue-rotate(208deg) brightness(104%) contrast(104%)",
    "blueIntenseLight": "invert(56%) sepia(25%) saturate(5351%) hue-rotate(192deg) brightness(101%) contrast(101%)",
    "blueDark": "invert(10%) sepia(65%) saturate(3279%) hue-rotate(231deg) brightness(92%) contrast(115%)",
    "greenLight": "invert(71%) sepia(26%) saturate(1122%) hue-rotate(101deg) brightness(95%) contrast(81%)",
    green: "invert(68%) sepia(21%) saturate(2571%) hue-rotate(70deg) brightness(96%) contrast(74%)",
    "greenDark": "invert(27%) sepia(20%) saturate(4551%) hue-rotate(90deg) brightness(96%) contrast(76%)",
    "greenNeon": "invert(68%) sepia(42%) saturate(1149%) hue-rotate(42deg) brightness(104%) contrast(110%)",
    greenBlue: "invert(65%) sepia(76%) saturate(375%) hue-rotate(108deg) brightness(89%) contrast(91%)",
    red: "invert(46%) sepia(47%) saturate(1270%) hue-rotate(318deg) brightness(100%) contrast(89%)",
    redSoft: "invert(51%) sepia(47%) saturate(695%) hue-rotate(308deg) brightness(103%) contrast(104%)",
    magenta: "invert(25%) sepia(82%) saturate(5218%) hue-rotate(309deg) brightness(103%) contrast(98%)",
    magentaSoft: "invert(46%) sepia(99%) saturate(4806%) hue-rotate(301deg) brightness(96%) contrast(91%)",
    "yellowMuted": "invert(94%) sepia(48%) saturate(894%) hue-rotate(357deg) brightness(97%) contrast(82%)",
    "yellowBright": "invert(100%) sepia(41%) saturate(3689%) hue-rotate(335deg) brightness(109%) contrast(107%)",
    "yellowNeon": "invert(84%) sepia(83%) saturate(671%) hue-rotate(0deg) brightness(106%) contrast(105%)",
    white: "invert(91%) sepia(8%) saturate(96%) hue-rotate(109deg) brightness(88%) contrast(92%)",
    "whiteBright": "invert(100%) sepia(5%) saturate(788%) hue-rotate(251deg) brightness(114%) contrast(100%)",
    beige: "invert(78%) sepia(17%) saturate(315%) hue-rotate(349deg) brightness(87%) contrast(87%)",
    salmon: "invert(48%) sepia(44%) saturate(824%) hue-rotate(319deg) brightness(98%) contrast(79%)",
    orange: "invert(42%) sepia(92%) saturate(911%) hue-rotate(354deg) brightness(102%) contrast(94%)",
    darkOrange: "invert(40%) sepia(12%) saturate(6206%) hue-rotate(332deg) brightness(105%) contrast(92%)",
    purple: "invert(56%) sepia(71%) saturate(2345%) hue-rotate(225deg) brightness(97%) contrast(110%)",
    purpleSoft: "invert(22%) sepia(76%) saturate(2592%) hue-rotate(280deg) brightness(85%) contrast(112%)",
    lavender: "invert(73%) sepia(37%) saturate(688%) hue-rotate(201deg) brightness(104%) contrast(102%)",
    pink: "invert(13%) sepia(79%) saturate(6326%) hue-rotate(313deg) brightness(106%) contrast(106%)",
};
const fontSizes = {
    tiny: "1rem",
    small: "1.25rem",
    medium: "1.5rem",
    large: "1.75rem",
    huge: "2.25rem",
    jumbo: "5rem",
};
const fonts = {
    roboto: "Roboto Mono",
    montserrat: "Montserrat Alternates",
    ubuntu: "Ubuntu",
    serif: "Source Serif Pro",
    code: "Source Code Pro",
    sanSerif: "Sf Pro Display",
    monospace: "monospace",
};
const edgeWidth = 10; // px
const defaultNodeRadius = 48; // px;
const nodeFatness = 0.2; // %/100 of node radius that is filled (from outside in)
const rowHeight = 175; // px (used by module tree and module detail views)
const appMarginSize = "25vw";
const borderRadius = "1rem";
export const theme = Object.assign({ rowHeight,
    nodeFatness,
    edgeWidth,
    defaultNodeRadius,
    colors,
    fontSizes,
    fonts,
    appMarginSize,
    borderRadius,
    colorFilters }, pallet);
export const buttonBackgroundColor = (props) => {
    return props.light ? props.theme.accentColor : props.theme.primaryColor;
};
export const buttonHoverBackgroundColor = (props) => {
    return props.light ? props.theme.primaryColor : props.theme.colors["blackDark"];
};
export const buttonTextColor = (props) => {
    return props.theme.fontColor;
};
export const buttonHoverTextColor = (props) => {
    var _a;
    return props.color ? (_a = props.theme.colors[props.color]) !== null && _a !== void 0 ? _a : props.color : props.theme.linkColor;
};
