import styled, { keyframes } from "styled-components";
const rotate = () => keyframes `
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const size = (factor = 1) => (p) => p.scale * 45 * factor;
const weight = (factor = 0.75) => (p) => p.scale * p.theme.edgeWidth * 0.34 * factor;
export const Ring = styled.div `
  background-color: transparent;
  width: ${size()}px;
  height: ${size()}px;
  border: ${weight()}px solid ${p => p.theme.linkColor};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(320deg);
`;
export const LoaderNodeContainer = styled.div `
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  animation: ${rotate} ${p => p.duration}s cubic-bezier(0.85, 0.1, 0, 0.9) infinite;
  animation-delay: ${p => 1 - (p.initialOffset / 360) * p.duration}s;
  top: 0;
  left: 0;
`;
export const LoaderNode = styled.div `
  transform: translate(-20%, -20%);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: ${size(1 / 3)}px;
  height: ${size(1 / 3)}px;
  background-color: ${p => p.theme.primaryColor};
  border: ${weight()}px solid ${p => p.theme.linkColor};
`;
