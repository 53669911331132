import React, { useCallback, useState, useRef, useLayoutEffect } from "react";
import { Circle, QuestionIcon, ChildrenContainer } from "./styled";
export default function InfoHover({ children, style }) {
    const [focused, setFocused] = useState(false);
    const [inTopHalf, setInTopHalf] = useState(false);
    const [inLeftHalf, setInLeftHalf] = useState(false);
    const circleRef = useRef(null);
    const focus = useCallback(() => setFocused(true), []);
    const unfocus = useCallback(() => setFocused(false), []);
    const calculatePosition = () => {
        const circle = circleRef.current;
        if (circle) {
            const rect = circle.getBoundingClientRect();
            setInTopHalf(rect.y < window.innerHeight / 2);
            setInLeftHalf(rect.x < window.innerWidth / 2);
        }
    };
    useLayoutEffect(() => {
        calculatePosition();
        window.addEventListener('scroll', calculatePosition);
        window.addEventListener('resize', calculatePosition);
        return () => {
            window.removeEventListener('scroll', calculatePosition);
            window.removeEventListener('resize', calculatePosition);
        };
    }, []);
    return (React.createElement(Circle, { ref: circleRef, focused: focused, style: style, onMouseEnter: focus, onMouseLeave: unfocus },
        React.createElement(QuestionIcon, { focused: focused }),
        React.createElement(ChildrenContainer, { focused: focused, top: inTopHalf, left: inLeftHalf }, children)));
}
