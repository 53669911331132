import styled from "styled-components";
export const ProgressBarContainer = styled.div `
  position: fixed;
  width: 1.5rem;
  height: calc(100vh - 15rem);
  transform: translateX(-50%);
  left: ${p => p.longitude};
  top: 7.5rem;
  padding: 0;
`;
export const ProgressBarMeter = styled.div.attrs(({ scrollPercent }) => ({
    style: {
        height: `${Math.min(Math.max(scrollPercent, 0), 100)}%`,
    },
})) `
  position: absolute;
  border-radius: 2px;
  top: 0.5rem;
  left: calc(50% - 2px);
  margin: 0;
  width: 4px;
  background-color: ${p => p.theme.linkColor};
`;
export const ProgressCheckpoint = styled.div `
  border-radius: 50%;
  position: absolute;
  margin: 0;
  width: 1rem;
  height: 1rem;
  left: calc(50% - 0.5rem);
  background-color: ${p => (p.highlight ? p.theme.linkColor : p.theme.secondaryColor)};
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.15s ease-out;
  cursor: pointer;
`;
export const ProgressCheckpointCenter = styled.div `
  border-radius: 50%;
  margin: 0;
  width: 0.5rem;
  height: 0.5rem;
  background-color: ${p => p.theme.primaryColor};
  border: none;
  transition: top 0.15s ease-out;
`;
