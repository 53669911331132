import styled from "styled-components";
import { MAX_MOBILE_SCREEN_SIZE } from "../../utils/constants";
export const PageContainer = styled.div `
  position: relative;
  height: calc((100vh - 10rem) - 7rem);
  display: flex;

  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    height: 100%;
  }
`;
export const ModuleRow = styled.div `
  display: flex;
  margin: 0;
  padding: 0;
`;
export const ModuleTreeContainer = styled.div `
  position: relative;
  flex: 1;
  margin: 0;
  padding: 0;
  overflow: scroll;
`;
