import React, { useEffect, useContext, useMemo } from "react";
import { useRouteError } from "react-router-dom";
import Button from "../../components/Button";
import { SnackbarContext } from "../../context/snackbarContext";
import { FallbackViewContainer, Apology, Explanation, Email } from "./styled";
import CopyToClipboard from "../../components/Logos/CopyToClipboard";
import WarningLogo from "./WarningLogo";
import { CONTACT_EMAIL, logErrorReportFail } from "../../utils/constants";
export default function FallbackErrorView() {
    const { pushToast } = useContext(SnackbarContext);
    const error = useRouteError();
    const copy = () => {
        navigator.clipboard.writeText(CONTACT_EMAIL);
        pushToast({
            message: "copied to clipboard",
            level: "success",
        });
    };
    const resizeObserverIsPresent = useMemo(() => {
        try {
            new ResizeObserver(() => { });
            return true;
        }
        catch (_) {
            return false;
        }
    }, []);
    useEffect(() => {
        if (error) {
            try {
                Bugsnag === null || Bugsnag === void 0 ? void 0 : Bugsnag.notify(error, (event) => {
                    event.context = "fallback error view rendered.";
                });
            }
            catch (_e) {
                logErrorReportFail();
            }
        }
    }, [error]);
    return (React.createElement(FallbackViewContainer, null,
        React.createElement("div", { style: { display: "flex", alignItems: "center" } },
            React.createElement(WarningLogo, null),
            React.createElement(Apology, null, "Sorry! Something went wrong")),
        React.createElement("div", { style: { display: "flex", alignItems: "center" } },
            React.createElement(Explanation, null,
                "I've already logged that something went wrong and will be fixing it ASAP. ",
                !resizeObserverIsPresent ? "This may have happened because the browser version you're using is outdated. Try updating your browser," : "In the meantime, you're welcome to try to return to the main menu,",
                " or contact me directly at",
                " ",
                React.createElement(Email, { onClick: copy, "aria-label": "copy to clipboard", title: "copy to clipboard" }, CONTACT_EMAIL),
                React.createElement(CopyToClipboard, { copy: copy }))),
        React.createElement("div", { style: { display: "flex", flexDirection: "column", alignItems: "center" } },
            React.createElement(Button, { label: "back to home", linkTo: "/" }, "Return to main page"))));
}
