import styled from "styled-components";
import { MAX_MOBILE_SCREEN_SIZE } from "../../utils/constants";
export const NotFoundContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Alert = styled.h1 `
  text-align: center;
  font-size: 3.5rem;
  color: ${p => p.theme.colors["white"]};
  margin: 0;

  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    font-size: ${p => p.theme.fontSizes.jumbo};
  }
`;
export const DirectToHome = styled.h3 `
  font-size: ${p => p.theme.fontSizes.medium};
  color: ${p => p.theme.colors["white"]};
  text-align: center;
`;
export const PageContainer = styled.div `
  margin: 15vh 0 0 0;
  padding: 0 4rem;
  width: 100vw;

  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    padding: max(20%, 7rem) 0 0 0;
  }
`;
