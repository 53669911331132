var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState, useContext, useMemo, useCallback } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import TicketCard from "../../components/TicketCard";
import { GoogleIdentityContext } from "../../context/googleIdentityContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { BoardsContext } from "../../context/boardsContext";
import { httpGet, httpPatch } from "../../utils/requests";
import { capitalize, logTrackingFail } from "../../utils/constants";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { ColumnsContainer, ColumnContainer, Column, ColumnHeader, BoardTitle, BoardTitleArea, StatusIndicator, AddTicketAction, ColumnHeaderTitle, } from "./styled";
import PageWrapper from "../../components/PageWrapper";
import TicketDialog from "../../components/TicketDialog";
import LoginWall from "../../components/LoginWall";
import Select from "../../components/Select";
import Loader from "../../components/Loader";
import Add from "../../components/Logos/Add";
export default function Kanban() {
    const navigate = useNavigate();
    const { hash } = useLocation();
    const { boardId } = useParams();
    const { loggedIn, tryWithTokenRefresh } = useContext(GoogleIdentityContext);
    const { pushToast } = useContext(SnackbarContext);
    const [tickets, setTickets] = useState([]);
    const [loadingTickets, setLoadingTickets] = useState(false);
    const { boards, changeBoardStatus, loadedBoards } = useContext(BoardsContext);
    const [alreadyLoadedTickets, setAlreadyLoadedTickets] = useState(false);
    const [editTicketDialogOpen, setEditTicketDialogOpen] = useState(false);
    const [newTicketAttributes, setNewTicketAttributes] = useState({});
    const ticketDialogUuid = useMemo(() => { var _a; return (_a = hash === null || hash === void 0 ? void 0 : hash.slice(1)) !== null && _a !== void 0 ? _a : ""; }, [hash]);
    const inspectedTicket = useMemo(() => {
        var _a;
        return (_a = tickets.flat().find(t => t.uuid === ticketDialogUuid)) !== null && _a !== void 0 ? _a : {};
    }, [ticketDialogUuid, tickets]);
    const board = useMemo(() => {
        var _a;
        return (_a = boards[boardId]) !== null && _a !== void 0 ? _a : null;
    }, [boards, boardId]);
    const columns = useMemo(() => {
        var _a;
        return (_a = board === null || board === void 0 ? void 0 : board.columns) !== null && _a !== void 0 ? _a : [];
    }, [board]);
    const closeCreateTicketDialog = useCallback((newTicket) => {
        var _a;
        if (newTicket) {
            try {
                (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.track("Ticket created");
            }
            catch (ampErr) {
                logTrackingFail();
            }
            setTickets(prevTickets => {
                return prevTickets.map((ticketColumn, columnIndex) => {
                    return newTicket.column === columns[columnIndex]
                        ? [...ticketColumn, newTicket]
                        : ticketColumn;
                });
            });
        }
        setNewTicketAttributes({});
    }, [ticketDialogUuid, columns]);
    const openCreateTicketDialog = useCallback((column) => {
        var _a;
        try {
            (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.track("Ticket dialog opened for new ticket");
        }
        catch (ampErr) {
            logTrackingFail();
        }
        setNewTicketAttributes({
            column,
            boardId,
            priority: tickets.flat().filter(t => t.column === column).length,
        });
    }, [boardId, tickets]);
    const closeEditTicketDialog = useCallback((updatedTicketAttributes, deleteTicket) => {
        var _a;
        try {
            (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.track("Ticket dialog closed");
        }
        catch (ampErr) {
            logTrackingFail();
        }
        if (ticketDialogUuid) {
            if (deleteTicket) {
                setTickets(prevTickets => {
                    return prevTickets.map(ticketColumn => {
                        const filteredTickets = ticketColumn
                            .filter(ticket => ticket.uuid !== ticketDialogUuid)
                            .map((ticket, priority) => (Object.assign(Object.assign({}, ticket), { priority })));
                        if (filteredTickets.length < ticketColumn.length) {
                            alterTickets(filteredTickets);
                        }
                        return filteredTickets;
                    });
                });
            }
            else {
                setTickets(prevTickets => {
                    return prevTickets.map(ticketColumn => {
                        return ticketColumn.map(ticket => {
                            return ticket.uuid === ticketDialogUuid
                                ? Object.assign(Object.assign({}, ticket), updatedTicketAttributes) : ticket;
                        });
                    });
                });
            }
        }
        setEditTicketDialogOpen(false);
        navigate("#");
    }, [ticketDialogUuid, boardId, tickets]);
    const openEditTicketDialog = useCallback((uuid) => {
        var _a;
        try {
            (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.track(`Ticket dialog opened for uuid: ${uuid}`);
        }
        catch (ampErr) {
            logTrackingFail();
        }
        navigate(`#${uuid !== null && uuid !== void 0 ? uuid : ""}`);
        setEditTicketDialogOpen(true);
    }, []);
    const alterTickets = useCallback(tryWithTokenRefresh((alteredTickets) => __awaiter(this, void 0, void 0, function* () {
        yield httpPatch(`/tickets/${boardId}`, alteredTickets);
    }), {
        onError: () => {
            pushToast({
                level: "error",
                message: "Board not saved",
            });
        }
    }), [boardId, tickets]);
    const onDragEnd = useCallback(result => {
        if (!result.destination)
            return;
        const { source, destination } = result;
        if (source.droppableId !== destination.droppableId) {
            const sourceColumnIndex = columns.indexOf(source.droppableId);
            const destColumnIndex = columns.indexOf(destination.droppableId);
            const sourceTickets = [...tickets[sourceColumnIndex]];
            const destTickets = [...tickets[destColumnIndex]];
            const [removed] = sourceTickets.splice(source.index, 1);
            destTickets.splice(destination.index, 0, removed);
            removed.column = destination.droppableId;
            const nextTickets = [...tickets];
            nextTickets[sourceColumnIndex] = sourceTickets.map((ticket, priority) => (Object.assign(Object.assign({}, ticket), { priority })));
            nextTickets[destColumnIndex] = destTickets.map((ticket, priority) => (Object.assign(Object.assign({}, ticket), { priority })));
            setTickets(nextTickets);
            alterTickets([...sourceTickets, ...destTickets]);
        }
        else {
            const columnIndex = columns.indexOf(source.droppableId);
            const copiedColumn = [...tickets[columnIndex]];
            const [removed] = copiedColumn.splice(source.index, 1);
            copiedColumn.splice(destination.index, 0, removed);
            const nextTickets = [...tickets];
            nextTickets[columnIndex] = copiedColumn.map((ticket, priority) => (Object.assign(Object.assign({}, ticket), { priority })));
            setTickets(nextTickets);
            alterTickets([...copiedColumn]);
        }
    }, [columns, tickets]);
    useEffect(() => {
        tryWithTokenRefresh(() => __awaiter(this, void 0, void 0, function* () {
            const requisiteDataIsLoaded = loggedIn && boardId && board && columns;
            if (requisiteDataIsLoaded && !alreadyLoadedTickets) {
                const { data: boardTickets } = yield httpGet(`/tickets/${boardId}`);
                setTickets(columns.map(column => boardTickets.filter((ticket) => ticket.column === column).sort((ticket1, ticket2) => ticket1.priority - ticket2.priority)));
                setAlreadyLoadedTickets(true);
            }
            else if (!loggedIn) {
                setTickets([]);
            }
        }), {
            onError: () => {
                pushToast({
                    level: "error",
                    message: `Board failed to load, sorry ${String.fromCodePoint(0x1f614)}`,
                });
                setLoadingTickets(false);
            }
        })();
    }, [loggedIn, boardId, board, columns, alreadyLoadedTickets]);
    useEffect(() => {
        if (loggedIn && alreadyLoadedTickets) {
            setLoadingTickets(false);
        }
        else if (loggedIn) {
            setLoadingTickets(true);
        }
    }, [alreadyLoadedTickets, loggedIn]);
    useEffect(() => {
        if (!!ticketDialogUuid && alreadyLoadedTickets) {
            openEditTicketDialog(ticketDialogUuid);
        }
    }, [alreadyLoadedTickets, ticketDialogUuid]);
    useEffect(() => {
        if (loggedIn && loadedBoards && !(boardId in boards)) {
            setTimeout(() => {
                if (loggedIn && loadedBoards && !(boardId in boards)) {
                    navigate("/404", { replace: true });
                }
            }, 1000);
        }
    }, [loggedIn, loadedBoards, boardId]);
    return (React.createElement(PageWrapper, null, !loggedIn ? (React.createElement(LoginWall, null)) : loadingTickets ? (React.createElement(Loader, null)) : (React.createElement(React.Fragment, null,
        "column" in newTicketAttributes ? (React.createElement(TicketDialog, { open: "column" in newTicketAttributes, boardId: boardId, closeDialog: closeCreateTicketDialog, existingTicketAttributes: newTicketAttributes })) : editTicketDialogOpen ? (React.createElement(TicketDialog, { open: editTicketDialogOpen, boardId: boardId, uuid: ticketDialogUuid, closeDialog: closeEditTicketDialog, existingTicketAttributes: inspectedTicket })) : null,
        React.createElement(BoardTitleArea, null,
            React.createElement(BoardTitle, null, board === null || board === void 0 ? void 0 : board.title),
            React.createElement(StatusIndicator, { status: board === null || board === void 0 ? void 0 : board.status }),
            React.createElement(Select, { value: board === null || board === void 0 ? void 0 : board.status, onChange: event => changeBoardStatus(boardId, event.target.value), options: ["todo", "doing", "done"], label: `board ${board ? board.title + " " : ""} status` })),
        React.createElement(ColumnsContainer, null,
            React.createElement(DragDropContext, { onDragEnd: onDragEnd }, columns.map((column, columnIndex) => (React.createElement(ColumnContainer, { key: columnIndex },
                React.createElement(ColumnHeader, null,
                    React.createElement(ColumnHeaderTitle, null, capitalize(column)),
                    React.createElement(AddTicketAction, { label: `create ticket-${column}`, color: "green", onClick: () => openCreateTicketDialog(column) },
                        React.createElement(Add, { style: { width: "66%", height: "66%" } }))),
                React.createElement(Droppable, { droppableId: column }, (provided, snapshot) => {
                    var _a;
                    return (React.createElement(Column, Object.assign({}, provided.droppableProps, { ref: provided.innerRef, beingDraggedOver: snapshot.isDraggingOver }), (_a = tickets[columnIndex]) === null || _a === void 0 ? void 0 :
                        _a.map((ticket, rowIndex) => (React.createElement(Draggable, { draggableId: ticket.uuid, index: rowIndex, key: ticket.uuid }, (provided, snapshot) => (React.createElement("div", Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { onClick: () => openEditTicketDialog(ticket.uuid) }),
                            React.createElement(TicketCard, { beingDragged: snapshot.isDragging, ticket: ticket })))))),
                        provided.placeholder));
                }))))))))));
}
