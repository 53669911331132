var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useMemo, useState, useEffect, useContext, useCallback, } from "react";
import { httpGet } from "../utils/requests";
import { SnackbarContext } from "./snackbarContext";
import { GoogleIdentityContext } from "./googleIdentityContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "./userContext";
import { NodeStatuses } from "../components/Node";
export var ContentTypes;
(function (ContentTypes) {
    ContentTypes["module"] = "module";
    ContentTypes["unit"] = "unit";
    ContentTypes["lesson"] = "lesson";
})(ContentTypes || (ContentTypes = {}));
const querier = (contentDict) => (searchTerm, withinModuleId) => {
    return searchTerm ? Object.values(contentDict).filter(content => {
        if (withinModuleId && content.moduleId !== withinModuleId)
            return false;
        for (const key in content) {
            if (["description", "name", "shortDescription"].includes(key) && content[key].toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                return true;
            }
        }
    }) : [];
};
const DefaultContentState = {
    loadingModules: false,
    loadingUnits: false,
    loadingLessons: false,
    loadingLessonsList: false,
    loadedModules: false,
    loadedUnits: false,
    loadedLessons: false,
    loadedLessonsList: false,
    modules: {},
    units: {},
    lessons: {},
    queryModules: () => ([]),
    queryUnits: () => ([]),
    queryLessons: () => ([]),
};
export const ContentContext = createContext(DefaultContentState);
export const ContentProvider = ({ children }) => {
    const { moduleId, unitId, lessonId } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { pushToast } = useContext(SnackbarContext);
    const { updateUserState, userState, hasPurchasedModule, userIsLoaded } = useContext(UserContext);
    const { loggedIn, tryWithTokenRefresh } = useContext(GoogleIdentityContext);
    const [modules, setModules] = useState({});
    const [units, setUnits] = useState({});
    const [lessons, setLessons] = useState({});
    const [loadingModules, setLoadingModules] = useState(true);
    const [loadingUnits, setLoadingUnits] = useState(false);
    const [loadingLessons, setLoadingLessons] = useState(false);
    const [loadingLessonsList, setLoadingLessonsList] = useState(false);
    const [loadedModules, setLoadedModules] = useState(false);
    const [loadedUnits, setLoadedUnits] = useState(false);
    const [loadedLessons, setLoadedLessons] = useState(false);
    const [loadedLessonsList, setLoadedLessonsList] = useState(false);
    const queryModules = useCallback(querier(modules), [modules]);
    const queryUnits = useCallback(querier(units), [units]);
    const queryLessons = useCallback(querier(lessons), [lessons]);
    const markContentAsSeen = useCallback(() => {
        var _a;
        if (!("nodeStatuses" in userState) || !(pathname in userState.nodeStatuses)) {
            updateUserState({
                nodeStatuses: Object.assign(Object.assign({}, ((_a = userState === null || userState === void 0 ? void 0 : userState.nodeStatuses) !== null && _a !== void 0 ? _a : {})), { [pathname]: NodeStatuses.seen }),
            });
        }
    }, [pathname, userState]);
    const loadModules = useCallback(tryWithTokenRefresh(() => __awaiter(void 0, void 0, void 0, function* () {
        setLoadedModules(false);
        setLoadingModules(true);
        const { data: foundModules } = yield httpGet("/modules");
        if (foundModules.length === 0) {
            pushToast({
                level: "error",
                message: "Modules not found",
            });
        }
        else {
            const moduleLookup = foundModules.reduce((accumulator, module) => (Object.assign(Object.assign({}, accumulator), { [module.id]: module })), Object.assign({}, modules));
            setModules(moduleLookup);
        }
    }), {
        onError: () => {
            pushToast({
                level: "error",
                message: "Failed to load modules",
            });
        },
        onEnd: () => setLoadingModules(false),
        onSuccess: () => setLoadedModules(true),
    }), []);
    const loadUnits = useCallback(tryWithTokenRefresh((moduleId) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingUnits(true);
        setLoadedUnits(false);
        const { data: foundUnits } = yield httpGet(`/units/${moduleId}`);
        if (foundUnits.length === 0) {
            pushToast({
                level: "error",
                message: "Units not found",
            });
            navigate("/404");
        }
        else {
            const unitLookup = foundUnits.reduce((accumulator, unit) => (Object.assign(Object.assign({}, accumulator), { [unit.id]: unit })), Object.assign({}, units));
            setUnits(unitLookup);
        }
    }), {
        onError: () => {
            pushToast({
                level: "error",
                message: "Failed to load units",
            });
        },
        onEnd: () => setLoadingUnits(false),
        onSuccess: () => setLoadedUnits(true)
    }), []);
    const loadLessons = useCallback(tryWithTokenRefresh((moduleId, unitId) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingLessons(true);
        setLoadedLessons(false);
        const { data: foundLessons } = yield httpGet(`/lessons/${moduleId}/${unitId}`);
        if (foundLessons.length === 0) {
            pushToast({
                level: "error",
                message: "Lessons not found",
            });
            navigate("/404");
        }
        else {
            const lessonLookup = foundLessons.reduce((accumulator, lesson) => (Object.assign(Object.assign({}, accumulator), { [lesson.id]: lesson })), Object.assign({}, lessons));
            setLessons(lessonLookup);
        }
    }), {
        onError: () => {
            pushToast({
                level: "error",
                message: "Failed to load lessons",
            });
        },
        onEnd: () => setLoadingLessons(false),
        onSuccess: () => setLoadedLessons(true),
    }), []);
    const loadLessonsList = useCallback(tryWithTokenRefresh((moduleId, unitId) => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingLessonsList(true);
        setLoadedLessonsList(false);
        const { data: foundLessons } = yield httpGet(`/lessons-list/${moduleId}/${unitId}`);
        if (foundLessons.length === 0) {
            pushToast({
                level: "error",
                message: "Lessons not found",
            });
            navigate("/404");
        }
        else {
            const lessonLookup = foundLessons.reduce((accumulator, lesson) => (Object.assign(Object.assign({}, accumulator), { [lesson.id]: lesson })), Object.assign({}, lessons));
            setLessons(lessonLookup);
        }
    }), {
        onError: () => {
            pushToast({
                level: "error",
                message: "Failed to load lessons list",
            });
        },
        onEnd: () => setLoadingLessonsList(false),
        onSuccess: () => setLoadedLessonsList(true),
    }), []);
    useEffect(() => {
        if (!loggedIn) {
            setLessons({});
        }
    }, [loggedIn]);
    useEffect(() => {
        if (Object.keys(modules).length === 0) {
            loadModules();
        }
    }, [loadModules]);
    useEffect(() => {
        if (moduleId && !Object.values(units).some(unit => unit.moduleId === moduleId)) {
            loadUnits(moduleId);
        }
    }, [moduleId, units]);
    useEffect(() => {
        if (!loadingLessonsList && moduleId && unitId && !Object.values(lessons).some(lesson => lesson.unitId === unitId)) {
            loadLessonsList(moduleId, unitId);
        }
    }, [loadingLessonsList, moduleId, unitId, lessons]);
    useEffect(() => {
        if (!loadingLessons &&
            loggedIn &&
            userIsLoaded &&
            hasPurchasedModule(moduleId) &&
            moduleId &&
            unitId &&
            lessonId &&
            Object.values(lessons).every(lesson => lesson.unitId !== unitId || !lesson.markdown)) {
            loadLessons(moduleId, unitId);
        }
    }, [loadingLessons && moduleId, unitId, lessonId, lessons, loggedIn, hasPurchasedModule, userIsLoaded]);
    useEffect(() => {
        if (!loadingLessons && !loadingLessonsList && !loadingUnits) {
            markContentAsSeen();
        }
    }, [pathname]);
    const values = useMemo(() => ({
        loadedModules,
        loadedUnits,
        loadedLessons,
        loadedLessonsList,
        loadingModules,
        loadingUnits,
        loadingLessons,
        loadingLessonsList,
        modules,
        units,
        lessons,
        queryModules,
        queryUnits,
        queryLessons,
    }), [loadingModules, loadingUnits, loadingLessons, loadingLessonsList, modules, units, lessons]);
    return React.createElement(ContentContext.Provider, { value: values }, children);
};
