import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { MAX_MOBILE_SCREEN_SIZE } from "../../utils/constants";
export const StyledMDContainer = styled.div `
  p:not(.typewriter),
  h1,
  h2,
  h3,
  h4,
  h5,
  li,
  ul,
  em,
  strong {
    text-align: left;
    color: ${p => p.theme.fontColor};
    font-family: ${p => p.theme.fonts.sanSerif};
    line-height: 2rem;
  }

  p,
  li,
  ul {
    font-size: ${p => p.theme.fontSizes.small};
    font-weight: 300;
  }

  em {
    font-size: ${p => p.theme.fontSizes.small};
    font-weight: 450;
  }

  strong {
    font-size: ${p => p.theme.fontSizes.small};
    font-weight: 700;
  }

  h5 {
    font-size: ${p => p.theme.fontSizes.small};
  }

  h4 {
    font-size: ${p => p.theme.fontSizes.medium};
  }

  h3 {
    font-size: ${p => p.theme.fontSizes.large};
  }

  h2 {
    font-size: ${p => p.theme.fontSizes.huge};
  }

  h2,
  h3,
  h4,
  h5 {
    line-height: 3rem;
    margin: 3rem 0 1rem;
    font-weight: 500;
    font-family: ${p => p.theme.fonts.sanSerif};
  }

  * .language-javascript {
    font-family: ${p => p.theme.fonts.code};
  }

  code {
    font-size: calc(${p => p.theme.fontSizes.small} * 0.9);
    font-family: ${p => p.theme.fonts.code};
  }

  a:not(.custom-button) {
    color: ${p => p.theme.linkColor};
    font-family: ${p => p.theme.fonts.sanSerif};
  }

  img {
    max-width: 90%;
    margin: 0 auto;
    display: block;
    padding: 1.25rem 0;
  }
`;
export const TutorialFormatContainer = styled.div `
  display: flex;
  flex-direction: column;
`;
export const TutorialNavigationButtonsContainer = styled.div `
  margin-top: 3.5rem;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;

  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
  }
`;
export const Link = styled(NavLink) `
  color: ${p => p.theme.linkColor};
  text-decoration: underline;
  font-weight: 400;
  font-size: ${p => p.theme.fontSizes.small};
  font-family: ${p => p.theme.fonts.serif};
`;
