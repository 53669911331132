var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useMemo, useState } from "react";
import { StyledButton, StyledLink, Typewriter } from "./styled";
import useSizeAndPosition from "../../hooks/useSizeAndPosition";
import { logTrackingFail } from "../../utils/constants";
export default function Button(_a) {
    var { onClick, loadingMessage = "...", children = null, size = "small", isLoading = false, color = null, light = false, label, linkTo, className } = _a, rest = __rest(_a, ["onClick", "loadingMessage", "children", "size", "isLoading", "color", "light", "label", "linkTo", "className"]);
    const [buttonWidth, setButtonWidth] = useState();
    const { width, setNode } = useSizeAndPosition();
    const buttonContentStyle = useMemo(() => (isLoading ? {} : { display: "flex", justifyContent: "center", alignItems: "center" }), [isLoading]);
    const buttonOnClick = (event) => {
        var _a;
        try {
            (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.track(`${label} button clicked`);
        }
        catch (apmError) {
            logTrackingFail();
        }
        setButtonWidth(width);
        onClick(event);
    };
    const linkOnClick = (event) => __awaiter(this, void 0, void 0, function* () {
        var _b;
        try {
            (_b = window === null || window === void 0 ? void 0 : window.amplitude) === null || _b === void 0 ? void 0 : _b.track(`navigated to ${linkTo}`);
        }
        catch (ampErr) {
            logTrackingFail();
        }
        setButtonWidth(width);
        if (onClick)
            yield onClick(event);
    });
    return linkTo ? (React.createElement(StyledLink, Object.assign({ to: linkTo, ref: setNode, label: label }, rest, { color: color, "$light": light, "$isLoading": false, "$minWidth": buttonWidth, size: size, onClick: linkOnClick, "aria-label": label, title: label, "data-cy": label, className: `custom-button ${className}` }), children)) : (React.createElement(StyledButton, Object.assign({ ref: setNode, label: label }, rest, { color: color, light: light, disabled: rest.disabled || isLoading, isLoading: isLoading, size: size, minWidth: buttonWidth, onClick: buttonOnClick, "aria-label": label, title: label, "data-cy": label, className: `custom-button ${className}` }),
        isLoading && (React.createElement(TypewriterLoader, { size: size, message: loadingMessage, stepDurationMillis: 600 })),
        React.createElement("div", { "aria-hidden": isLoading, hidden: isLoading, style: buttonContentStyle }, children)));
}
function TypewriterLoader(_a) {
    var { className } = _a, rest = __rest(_a, ["className"]);
    return React.createElement(Typewriter, Object.assign({ className: `typewriter ${className}` }, rest));
}
