import styled from "styled-components";
const progressColor = (p) => {
    if (p.percentage === null)
        return "transparent";
    if (p.percentage === 0)
        return p.theme.colors["greyLight"];
    if (p.percentage < 33)
        return p.theme.colors["red"];
    if (p.percentage < 66)
        return p.theme.colors["orange"];
    if (p.percentage < 100)
        return p.theme.colors["yellowBright"];
    return p.theme.colors["green"];
};
export const TicketCardContainer = styled.div `
  display: flex;
  flex-direction: column;
  background-color: ${p => p.theme.accentColor};
  border-radius: 0.375rem;
  padding: 1rem;
  cursor: pointer;
  transform: scale(${p => (p.beingDragged ? 1.1 : 1)});
  transition: transform 0.1s ease-out;
  margin: 0 0.25rem;
  gap: 0.5rem;
  box-shadow: 0px 2px 2px ${p => p.theme.colors["blackDark"]};

  &:active {
    box-shadow: none;
  }
`;
export const Title = styled.div `
  font-size: ${p => p.theme.fontSizes.large};
  color: ${p => p.theme.fontColor};
`;
export const Description = styled.div `
  font-size: ${p => p.theme.fontSizes.small};
  color: ${p => p.theme.colors["white"]};
`;
export const Completion = styled.div `
  display: flex;
  color: ${progressColor};
  border-radius: 0.25rem;
  margin: 0.25rem;
  padding: 0.25rem;
  transition: all 0.7s ease-out;
`;
export const CompletionProgressBar = styled.div.attrs(({ percentage }) => ({
    style: {
        width: `${percentage !== null && percentage !== void 0 ? percentage : 0}%`,
    },
})) `
  border-radius: 2px;
  margin: 0;
  height: 4px;
  background-color: ${progressColor};
  transition: all 0.7s ease-out;
`;
export const DescriptionText = styled.p `
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-line;
`;
