import styled from "styled-components";
export const Header = styled.h2 `
  text-align: center;
  font-family: ${p => p.theme.fonts.sanSerif};
  font-size: ${p => p.theme.fontSizes.large};
  color: ${p => p.theme.fontColor};
`;
export const Paragraph = styled.p `
  text-align: left;
  font-family: ${p => p.theme.fonts.sasSerif};
  font-size: ${p => p.theme.fontSizes.small};
  color: ${p => p.theme.fontColor};
  line-height: calc(1.5 * ${p => p.theme.fontSizes.small});

  span {
    font-family: ${p => p.theme.fonts.sasSerif};
  }
`;
export const ListElement = styled.li `
  width: 100%;
  margin-top: 1rem;
  text-align: left;
  text-indent: 50px;
  font-family: ${p => p.theme.fonts.sasSerif};
  font-size: ${p => p.theme.fontSizes.small};
  color: ${p => p.theme.fontColor};
  line-height: calc(1.5 * ${p => p.theme.fontSizes.small});

  span {
    font-family: ${p => p.theme.fonts.sasSerif};
  }
`;
export const InfoSection = styled.div `
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
`;
export const InfoSectionsContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 12.5vw;
`;
