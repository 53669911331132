import React, { createContext, useMemo, useState, useEffect } from "react";
import ToastAlert from "../components/ToastAlert";
import { logErrorReportFail, logTrackingFail } from "../utils/constants";
const DefaultSnackbarState = {
    pushToast: () => { },
    dismiss: () => { },
};
export const SnackbarContext = createContext(DefaultSnackbarState);
const defaultTimer = 4500; // ms
const dismissDuration = 150; // ms
let dismissalTimer;
export const SnackbarProvider = ({ children }) => {
    const [toast, setToast] = useState(null);
    const pushToast = (newToast) => {
        var _a;
        try {
            (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.track(`toast level ${newToast.level}: "${newToast.message}"`);
        }
        catch (ampErr) {
            logTrackingFail();
        }
        if (dismissalTimer)
            clearTimeout(dismissalTimer);
        if (toast) {
            // dismiss old toast so that it animates away;
            setToast(oldToast => (Object.assign(Object.assign({}, oldToast), { dismissed: true })));
            // when it's gone, introduce new toast
            dismissalTimer = setTimeout(() => {
                if (!newToast.permanence || newToast.permanence === "normal") {
                    dismissalTimer = setTimeout(dismiss, defaultTimer);
                }
                setToast(newToast);
            }, dismissDuration);
        }
        else {
            // set new toast immediately
            if (!newToast.permanence || newToast.permanence === "normal") {
                dismissalTimer = setTimeout(dismiss, defaultTimer);
            }
            setToast(newToast);
        }
    };
    const dismiss = () => {
        if (dismissalTimer)
            clearTimeout(dismissalTimer);
        setToast(oldToast => (Object.assign(Object.assign({}, oldToast), { dismissed: true })));
        dismissalTimer = setTimeout(() => {
            setToast(null);
        }, dismissDuration);
    };
    const value = useMemo(() => ({
        pushToast,
        dismiss,
    }), [toast]);
    useEffect(() => () => dismissalTimer && clearTimeout(dismissalTimer), []);
    useEffect(() => {
        if ((toast === null || toast === void 0 ? void 0 : toast.level) === "error") {
            try {
                Bugsnag === null || Bugsnag === void 0 ? void 0 : Bugsnag.notify({ name: "error toast", message: toast.message }, (event) => {
                    event.context = "error toast was rendered";
                    event.severity = "warning";
                });
            }
            catch (_e) {
                logErrorReportFail();
            }
        }
    }, [toast]);
    return (React.createElement(SnackbarContext.Provider, { value: value },
        children,
        toast ? (React.createElement(ToastAlert, { dismissDuration: dismissDuration, message: toast.message, level: toast.level, dismiss: () => dismiss(), dismissed: toast.dismissed })) : null));
};
