import React, { useState, useEffect } from "react";
import { getContrastingHex } from "color-contrast-picker";
import { ToastContainer, toastColor } from "./styled";
import { theme } from "../../theme";
import Cancel from "../Logos/Cancel";
export default function ToastAlert({ message, level, dismissed, dismissDuration, dismiss, }) {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        setVisible(!dismissed);
    }, [dismissed]);
    return (React.createElement(ToastContainer, { level: level, dismissDuration: dismissDuration, visible: visible },
        message,
        React.createElement("button", { onClick: dismiss },
            React.createElement(Cancel, { style: { transform: "rotate(45deg) scale(1.5)" }, color: getContrastingHex(toastColor({ level, theme })) }))));
}
