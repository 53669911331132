import styled from "styled-components";
import Button from "../../components/Button";
import { MAX_MOBILE_SCREEN_SIZE } from "../../utils/constants";
const scale = (p) => {
    return (p.theme.edgeWidth + p.radius * 2) / (p.radius * 2);
};
export const NextButton = styled(Button) `
  position: ${p => p.isMobile ? "fixed" : "absolute"};
  bottom: 0;
  right: 3vw;
  z-index: 2;
  background-color: ${p => p.theme.colors.black};
`;
export const PrevButton = styled(Button) `
  position: ${p => p.isMobile ? "fixed" : "absolute"};
  bottom: 0;
  left: ${p => p.isMobile ? "1rem" : "25vw"};
  z-index: 2;
  background-color: ${p => p.theme.colors.black};
`;
export const LessonsCircleInnerContainer = styled.div `
  height: ${p => p.circleRadius * 2.5}px;
  zIndex: 0;
  position: relative;
  flexGrow: 1;
  z-index: 1;
`;
export const LessonsCircleContainer = styled.div `
  position: relative;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const InvisibleCircle = styled.div `
  border-radius: 50%;
  background-color: transparent;
  z-index: -1;
  width: ${p => p.radius * 2}px;
  height: ${p => p.radius * 2}px;
  position: absolute;
  left: calc(50% - ${p => p.radius}px);
  bottom: ${p => p.theme.edgeWidth}px;
  box-sizing: content-box;
  
  @media (max-height: 750px) {
    transform: scale(.8);
    
    a, p, h1, h2, h3, li, span {
      transform: scale(1.25);
    }
  }

  @media (max-height: 600px) {
    transform: scale(.7);
    
    a, p, h1, h2, h3, li, span {
      transform: scale(1.45);
    }
  }
`;
export const Circle = styled.div `
  border-radius: 50%;
  background-color: transparent;
  z-index: -1;
  border: ${p => p.theme.edgeWidth}px solid ${p => p.darkened ? p.theme.colors.grey : p.theme.secondaryColor};
  width: ${p => p.radius * 2}px;
  height: ${p => p.radius * 2}px;
  position: absolute;
  left: calc(50% - ${p => p.radius}px);
  top: calc(50% - ${p => p.radius}px);
  transform: scale(${scale});
`;
export const CTAcopy = styled.p `
  font-size: ${p => p.theme.fontSizes.medium};
  color: ${p => p.theme.fontColor};
`;
export const CTAcontainer = styled.div `
  display: flex;
  align-items: center;
  margin: ${p => (!p.visible ? 0 : "1rem")};
  overflow: hidden;
  height: ${p => (!p.visible ? 0 : "10rem")};
  transition: all 0.2s ease-in;

  button {
    margin: 1rem;
  }
`;
export const MobileTitle = styled.div `
  box-sizing: border-box;
  text-align: left;
  color: ${p => p.theme.fontColor};
  font-size: ${p => p.theme.fontSizes.huge};
  line-height: 3rem;
  margin: 0 0 3rem 0;
  font-weight: 500;
  font-family: ${p => p.theme.fonts.sanSerif};
  text-align: center;
`;
export const UnitDetailContainer = styled.div `
  display: flex;
  flex: 1;
  margin: 0;
  padding: 0;
  height: calc((100vh - 10rem) - 7rem);
  position: relative;
  overflow: auto;

  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    height: 100%;
  }
`;
