var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useState, useMemo, useCallback, useEffect, useContext, } from "react";
import { GoogleIdentityContext } from "./googleIdentityContext";
import { httpGet, httpPatch } from "../utils/requests";
import { NodeStatuses } from "../components/Node";
import { FREE_MODULE_IDS, logErrorReportFail, logTrackingFail } from "../utils/constants";
const defaultUserContextState = {
    userIsLoaded: false,
    userState: {},
    updateUserState: () => { },
    nodeStatus: () => null,
    hasPurchasedModule: () => false,
};
export const UserContext = createContext(defaultUserContextState);
export const UserContextProvider = ({ children }) => {
    const { loggedIn, tryWithTokenRefresh } = useContext(GoogleIdentityContext);
    const [userState, setUserState] = useState({});
    const userIsLoaded = useMemo(() => !!userState.email, [userState]);
    const updateUserState = useCallback(tryWithTokenRefresh((stateToUpsert) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (loggedIn) {
            const { data: user } = yield httpPatch("/user", stateToUpsert);
            try {
                (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.setUserId(user.email);
            }
            catch (ampErr) {
                logTrackingFail();
            }
            setUserState(user);
        }
    })), [loggedIn]);
    const nodeStatus = useCallback((nodePath) => {
        var _a, _b;
        return (_b = (_a = userState === null || userState === void 0 ? void 0 : userState.nodeStatuses) === null || _a === void 0 ? void 0 : _a[nodePath]) !== null && _b !== void 0 ? _b : NodeStatuses.unexplored;
    }, [userState]);
    const hasPurchasedModule = useCallback((moduleId) => {
        var _a;
        return !userIsLoaded || (!!(FREE_MODULE_IDS.includes(moduleId) || ((_a = userState === null || userState === void 0 ? void 0 : userState.purchases) === null || _a === void 0 ? void 0 : _a.includes(moduleId))));
    }, [userState, userIsLoaded]);
    useEffect(() => {
        if (loggedIn) {
            tryWithTokenRefresh(() => __awaiter(void 0, void 0, void 0, function* () {
                var _a, _b;
                const { data: user } = yield httpGet("/user");
                if (user) {
                    try {
                        (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.setUserId(user.email);
                    }
                    catch (ampErr) {
                        logTrackingFail();
                    }
                    setUserState(user);
                }
                else {
                    const { data: user } = yield httpPatch("/user", {});
                    try {
                        (_b = window === null || window === void 0 ? void 0 : window.amplitude) === null || _b === void 0 ? void 0 : _b.setUserId(user.email);
                    }
                    catch (ampErr) {
                        logTrackingFail();
                    }
                    setUserState(user);
                }
            }))();
        }
        else {
            setUserState({});
        }
    }, [loggedIn]);
    useEffect(() => {
        if (ENV === "production" /* Envs.production */ && (userState === null || userState === void 0 ? void 0 : userState.email)) {
            try {
                Bugsnag === null || Bugsnag === void 0 ? void 0 : Bugsnag.setUser(userState.email, userState.email, userState.email); // BugSnag requires an ID, name, and email for users, I only have/care about emails.
            }
            catch (_e) {
                logErrorReportFail();
            }
        }
    }, [userState]);
    const value = useMemo(() => {
        return {
            userIsLoaded,
            userState,
            updateUserState,
            nodeStatus,
            hasPurchasedModule,
        };
    }, [
        userIsLoaded,
        userState,
        updateUserState,
        nodeStatus,
        hasPurchasedModule
    ]);
    return React.createElement(UserContext.Provider, { value: value }, children);
};
