import styled from "styled-components";
export const QandAContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 12.5vw 5rem;
`;
export const InfoArea = styled.div `
  margin: 1rem;
  color: ${p => p.theme.colors["white"]};
  font-size: ${p => p.theme.fontSizes.small};
  font-family: ${p => p.theme.fonts.sanSerif};
  font-weight: 300;

  span {
    font-family: ${p => p.theme.fonts.sanSerif};
    margin: 0;
  }

  p,
  ul,
  li {
    font-weight: 300;
    font-family: ${p => p.theme.fonts.sanSerif};
  }

  a {
    font-weight: 300;
    font-family: ${p => p.theme.fonts.sanSerif};
    color: ${p => p.theme.linkColor};
    text-decoration: underline solid;

    &:hover {
      color: ${p => p.theme.fontColor};
    }
  }
`;
export const Highlighted = styled.span `
  font-family: ${p => p.theme.fonts.sanSerif} !important;
  color: ${p => p.theme.colors["whiteBright"]};
  font-weight: 400;
`;
