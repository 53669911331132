import React from "react";
import PageWrapper from "../../components/PageWrapper";
import QandA from "../../components/QandA";
import StyledLink from "../../components/StyledLink";
import { InfoArea, Highlighted, QandAContainer } from "./styled";
export default function FAQ() {
    return (React.createElement(PageWrapper, null,
        React.createElement(QandAContainer, null,
            React.createElement(QandA, { style: { margin: 0 }, question: React.createElement(InfoArea, null,
                    React.createElement("span", null, "Q"),
                    " - Why should I purchase content here when there's tons of free content online?"), answer: React.createElement(InfoArea, null,
                    React.createElement("span", null, "A"),
                    " - Dev Yourself is a curated guide from 0 to solidly-employable-junior-dev.",
                    React.createElement("p", null, "I strive to provide you with features that help structure your learning experience so you can get the most out of the time you spend learning."),
                    React.createElement("p", null,
                        "That said, there are plenty of options out there and I recommend trying a few to find the best match for you. Here are some",
                        " ",
                        React.createElement(Highlighted, null, "alternatives I've vetted myself:")),
                    React.createElement("ul", null,
                        React.createElement(StyledLink, { to: "https://www.codecademy.com/about" }, "Codecademy")),
                    React.createElement("ul", null,
                        React.createElement(StyledLink, { to: "https://www.theodinproject.com/about" }, "The Odin Project")),
                    React.createElement("ul", null,
                        React.createElement(StyledLink, { to: "https://www.edx.org/course/introduction-computer-science-harvardx-cs50x" }, "CS50 (Harvard's intro to comp sci course)")),
                    React.createElement("ul", null,
                        React.createElement(StyledLink, { to: "https://www.freecodecamp.org/learn" }, "Free Code Camp"))) }),
            React.createElement(QandA, { style: { margin: 0 }, question: React.createElement(InfoArea, null,
                    React.createElement("span", null, "Q"),
                    " - What specifically sets this learning platform apart?"), answer: React.createElement(InfoArea, null,
                    React.createElement("span", null, "A"),
                    " - For one, having an ",
                    React.createElement(Highlighted, null, "integrated kanban board"),
                    " for structuring tasks as tickets. Which is both a common workflow for devs and, more generally, a great organizational tool.",
                    React.createElement("p", null,
                        "Another is that I provide ",
                        React.createElement(Highlighted, null, "multiple formats"),
                        " for every lesson so that you can choose the format(s) that suit your learning style best."),
                    React.createElement("p", null,
                        "I think maybe the biggest differentiating factor an",
                        " ",
                        React.createElement(Highlighted, null, "emphasis on understanding \"how\" and \"why\" things work"),
                        ". It's served me well as an engineer and I've tried to make it a central theme of the Dev Yourself curriculum")) }),
            React.createElement(QandA, { style: { margin: 0 }, question: React.createElement(InfoArea, null,
                    React.createElement("span", null, "Q"),
                    " - Why can't I just buy the individual units I'm interested in? Why do you force me to buy a whole module (bundle of units) at once?"), answer: React.createElement(InfoArea, null,
                    React.createElement("span", null, "A"),
                    " - It was a ",
                    React.createElement(Highlighted, null, "UX"),
                    " decision. I didn't want people to be constantly hitting a paywall while trying to learn. Even with a low price per lesson, that idea rubbed me wrong. So, I decided I would bundle units on similar concepts together so people could get a more complete learning experience from",
                    " ",
                    React.createElement(Highlighted, null, "fewer purchases/checkout experiences.")) }),
            React.createElement(QandA, { style: { margin: 0 }, question: React.createElement(InfoArea, null,
                    React.createElement("span", null, "Q"),
                    " - How much will this cost?"), answer: React.createElement(InfoArea, null,
                    React.createElement("span", null, "A"),
                    " - I think that completing about 10-15 modules should be enough for someone be able to build a portfolio and apply to junior dev jobs.",
                    React.createElement("p", null, "It depends on how specialized you want to be, what kinds of projects you want to put in your portfolio, what level of preparation you intended to achieve, and what types of companies you intend to apply to."),
                    React.createElement("p", null,
                        "My goal is that you'll be able to get a really strong foundation and land your first job for ",
                        React.createElement(Highlighted, null, "less than $500"),
                        " (as a high estimate).")) }),
            React.createElement(QandA, { style: { margin: 0 }, question: React.createElement(InfoArea, null,
                    React.createElement("span", null, "Q"),
                    " - What if I have questions? Like, if something doesn't make sense, or is broken?"), answer: React.createElement(InfoArea, null,
                    React.createElement("span", null, "A"),
                    " - I try to be thorough, and ",
                    React.createElement(Highlighted, null, "I reference outside resources"),
                    ", so you're not trapped trying to get everything you need from one source. Ultimately we all learn differently and we all have different stumbling blocks. I'm sensitive to that and do my best to foresee what kinds of things might not make sense (and how they might be better articulated). Also, I aspire to provide forums that a community can form around and contribute to.",
                    React.createElement("p", null,
                        "Last, ",
                        React.createElement(Highlighted, null, "use the internet:"),
                        " Google is your friend, check the docs for wahtever your using, Stack Overflow often has great solutions to all the most common issues, and Chat GPT is a great tool to ask questions to."),
                    React.createElement("p", null,
                        "I try to be careful about not breaking things. However, I am a team of 1, and people make mistakes. So, feel free to ",
                        React.createElement(StyledLink, { to: "/contact" }, "contact me"),
                        " if something is wrong (or if something is right!). I would love to hear your honest thoughts.")) }),
            React.createElement(QandA, { style: { margin: 0 }, question: React.createElement(InfoArea, null,
                    React.createElement("span", null, "Q"),
                    " - How long will this take?"), answer: React.createElement(InfoArea, null,
                    React.createElement("span", null, "A"),
                    " - One of the great advantages (aside from cost) of partaking in a self-directed program like this one is the flexibility. That said, it also means I cannot precisely estimate how long it would take to reach a satisfactory level of skill through the Dev Yourself curriculum.",
                    React.createElement("p", null,
                        "I can say that I cover roughly the same content as any top web development bootcamp, and those take ",
                        React.createElement(Highlighted, null, "~4 months"),
                        " if you enroll full time. So, it's reasonable to expect that ",
                        React.createElement(Highlighted, null, "as a lower bound.")),
                    React.createElement("p", null,
                        "But don't strive to hit a timetable that doesn't work for you!",
                        " ",
                        React.createElement(Highlighted, null, "When you purchase a module you have access for life, so there's no rush!"))) }),
            React.createElement(QandA, { style: { margin: 0 }, question: React.createElement(InfoArea, null,
                    React.createElement("span", null, "Q"),
                    " - What exactly can I learn here?"), answer: React.createElement(InfoArea, null,
                    React.createElement("span", null, "A"),
                    " - ",
                    React.createElement(Highlighted, null, "Fullstack javascript"),
                    ", specifically with a focus on web development.",
                    React.createElement("p", null, "This won't always be the curriculum (I intend to broaden it in time), but for now I think that it's a really great way to start your career in tech because it's such a flexible starting point. You can pivot to app security, mobile development, cloud development, AI/ML, dev ops, and more almost right away with a background in web. Also there are numerous specialties (frontend, backend, etc.) within web dev that are attainable first jobs for junior developers."),
                    React.createElement("p", null, "So, if you know at this moment that you aren't interested in learning how to develop web apps, I can confidently say my content is not for you (at least not yet); sorry.")) }),
            React.createElement(QandA, { style: { margin: 0 }, question: React.createElement(InfoArea, null,
                    React.createElement("span", null, "Q"),
                    " - How should I decide between using your platform, doing a full time bootcamp, or just teaching myself?"), answer: React.createElement(InfoArea, null,
                    React.createElement("span", null, "A"),
                    " - Tough question! There is no right answer, but here is how I would think about it as someone who was originally self taught, and enjoyed coding so I decided to do a bootcamp to accelerate my growth.",
                    React.createElement("ul", null, "If you thrive on structure, strongly benefit community when you're undertaking a big new thing, and have the time and money , a bootcamp is almost certainly for you."),
                    React.createElement("ul", null, "If you're unwilling to spend $, have experience treading the path less traveled, particularly when it comes to learning new skills, and don't benefit from external structure, self-teaching might be the thing for you."),
                    React.createElement("ul", null,
                        "If you fall somewhere between those two, and are willing to spend some money but nowhere near what immersive bootcamps charge, then",
                        " ",
                        React.createElement(Highlighted, null, "Dev Yourself is your goldilocks-zone."))) }),
            React.createElement(QandA, { style: { margin: 0 }, question: React.createElement(InfoArea, null,
                    React.createElement("span", null, "Q"),
                    " - Who are you? Why should I trust you're opinions/knowledge about software engineering?"), answer: React.createElement(InfoArea, null,
                    React.createElement("span", null, "A"),
                    " - I've been a software engineer for the better part of a decade.",
                    React.createElement("p", null,
                        "In that time ",
                        React.createElement(Highlighted, null, "I worked for a variety of companies"),
                        ". Some tiny, some huge. I hate to name drop, so I won't."),
                    React.createElement("p", null,
                        "Perhaps the most important thing that sets my experience apart from other folks with similar amounts of years of experience is the",
                        " ",
                        React.createElement(Highlighted, null, "large variety of types of engineering"),
                        " I've done. I've worked on cloud platforms, IOS apps, ML projects, gotten into the nitty gritty of inventing and optimizing algorithms, lead teams, started two companies, and of course, made quite a few websites."),
                    React.createElement("p", null,
                        "I am NOT the worlds best engineer, but I'm very",
                        " ",
                        React.createElement(Highlighted, null, "well rounded"),
                        " and I have a very",
                        " ",
                        React.createElement(Highlighted, null, "strong knack for writing code.")),
                    React.createElement("p", null,
                        "Most importantly, ",
                        React.createElement(Highlighted, null, "I care about helping people."))) }),
            React.createElement(QandA, { style: { margin: 0 }, question: React.createElement(InfoArea, null,
                    React.createElement("span", null, "Q"),
                    " - Why are you doing this?"), answer: React.createElement(InfoArea, null,
                    React.createElement("span", null, "A"),
                    " - ",
                    React.createElement(Highlighted, null, "I love helping people and I love writing code."),
                    React.createElement("p", null, "Throughout my life I've circled back to teaching jobs again and again. Doing this for me is a culmination of many years of discovering my own passions and honing my own skills so that I can be a happy practitioner of the things that interest me.")) }),
            React.createElement(QandA, { style: { margin: 0 }, question: React.createElement(InfoArea, null,
                    React.createElement("span", null, "Q"),
                    " - How do I decide which content to learn?"), answer: React.createElement(InfoArea, null,
                    React.createElement("span", null, "A"),
                    " - Read the descriptions of the units and modules near the bottom of the module tree (main page).",
                    React.createElement("p", null,
                        "See which ones most appeal to you as portfolio projects, then",
                        " ",
                        React.createElement(Highlighted, null, "chart a path from the top of the tree down to one of the projects at the bottom that interest you.")),
                    React.createElement("p", null,
                        "Also, you don't need to 100% \"complete\" each module to feel prepared to move on to the next. About 3 units in each module is enough to consider moving on to the next, though it varies.",
                        " ",
                        React.createElement(Highlighted, null, "Read the descriptions of modules to understand better what requisite knowledge you need"),
                        " ",
                        "to successfully engage with the material.")) }))));
}
