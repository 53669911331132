import React from "react";
import { marked } from "marked";
import { Link } from "./styled";
import VideoPlayer from "../VideoPlayer";
import { TutorialStep } from "./TutorialFormat";
import MakeKanbanBoard from "./MakeKanbanBoard";
import ScrollableContainer from "../ScrollableContainer";
import Copyable from "../Copyable";
import QandA from "../QandA";
const renderer = {
    code: (code, language) => {
        return `<pre class="language-${language} line-numbers" data-start="1"><code class="language-${language} line-numbers">${code}</code></pre>`;
    },
};
// Turns h1 into h2, h2 into h3, etc.
const walkTokens = (token) => {
    if (token.type === "heading")
        token.depth += 1;
};
marked.use({ walkTokens, renderer });
const parseRawPropValue = (propName) => (rawText) => {
    let endOfOpeningTagIndex;
    let tagDepth = 0;
    // find its proper associated closing tag (cannot be escaped inside inline code, or associated with another opening tag of the same type)
    for (let i = 0; i < rawText.length; i++) {
        if (rawText[i] === "`" && rawText[i + 1] === "`" && rawText[i + 2] === "`") {
            i = rawText.indexOf("```", i + 2);
        }
        if (rawText.slice(i, i + `<${propName}>`.length) === `<${propName}>`) {
            i += propName.length + 1;
            if (tagDepth === 0) {
                endOfOpeningTagIndex = i + 1;
            }
            tagDepth++;
        }
        if (rawText.slice(i, i + `</${propName}>`.length) === `</${propName}>`) {
            tagDepth--;
            if (tagDepth === 0) {
                return rawText.slice(endOfOpeningTagIndex, i);
            }
            i += propName.length + 2;
        }
    }
    throw new SyntaxError(`"${propName}" prop's value could not be parsed from: "${rawText}"`);
};
const parseProps = (unparsedProps, propParsers) => Object.entries(propParsers).reduce((props, [propName, parser]) => (Object.assign({ [propName]: parser(unparsedProps) }, props)), {});
const specialTags = {
    scrollable: {
        component: (props) => React.createElement(ScrollableContainer, Object.assign({ key: Math.random().toString() }, props)),
        propParsers: {
            children: parseMdText,
        },
    },
    tutorial_step: {
        component: (props) => React.createElement(TutorialStep, Object.assign({ key: Math.random().toString() }, props)),
        propParsers: {
            title: parseRawPropValue("title"),
            children: parseMdText,
        },
    },
    q_a: {
        component: (props) => React.createElement(QandA, Object.assign({ key: Math.random().toString() }, props)),
        propParsers: {
            question: unparsedProps => parseMdText(parseRawPropValue("question")(unparsedProps)),
            answer: unparsedProps => parseMdText(parseRawPropValue("answer")(unparsedProps)),
        },
    },
    video_lesson: {
        component: (props) => React.createElement(VideoPlayer, Object.assign({ key: Math.random().toString() }, props)),
        propParsers: {
            module: parseRawPropValue("module"),
            unit: parseRawPropValue("unit"),
            videoName: parseRawPropValue("video_name"),
        },
    },
    checkpoint: {
        component: (props) => (React.createElement("div", { key: Math.random().toString(), style: { height: 0, width: 0, zIndex: -1000, opacity: 0 }, id: props.id })),
        propParsers: {
            id: parseRawPropValue("id"),
        },
    },
    copyable: {
        component: (props) => React.createElement(Copyable, Object.assign({ key: Math.random().toString() }, props)),
        propParsers: {
            children: parseMdText,
            contents: unparsedProps => unparsedProps.replace(/\`{3}[a-z]*/gi, "").trim(),
        },
    },
    clone_board: {
        component: (props) => React.createElement(MakeKanbanBoard, Object.assign({ key: Math.random().toString() }, props)),
        propParsers: {
            boardId: parseRawPropValue("board_id"),
        },
    },
    link: {
        component: (props) => (React.createElement(Link, { key: Math.random().toString(), to: props.to }, props.label)),
        propParsers: {
            to: parseRawPropValue("to"),
            label: parseRawPropValue("label"),
        },
    },
};
export function parseMdText(text) {
    const components = [];
    // for the length of the input
    for (let i = 0; i < text.length;) {
        let openingTagIndex = text.length;
        let tagType = null;
        // find the next special tag that needs to be added to the components array
        Object.keys(specialTags).forEach(tag => {
            const j = text.indexOf(`<${tag}>`, i);
            if (j > -1 && j < openingTagIndex) {
                openingTagIndex = j;
                tagType = tag;
            }
        });
        // if one exists
        if (tagType) {
            tagType = tagType;
            let closingTagIndex;
            let tagDepth = 1;
            // find its proper associated closing tag (cannot be escaped inside inline code, or associated with another opening tag of the same type)
            for (let j = openingTagIndex + tagType.length + 2; j < text.length; j++) {
                if (text[j] === "`" && text[j + 1] === "`" && text[j + 2] === "`") {
                    j = text.indexOf("```", j + 2);
                }
                if (text.slice(j, j + `<${tagType}>`.length) === `<${tagType}>`) {
                    tagDepth++;
                    j += tagType.length + 1;
                }
                if (text.slice(j, j + `</${tagType}>`.length) === `</${tagType}>`) {
                    tagDepth--;
                    if (tagDepth === 0) {
                        closingTagIndex = j;
                        break;
                    }
                    j += tagType.length + 2;
                }
            }
            // push the preceding text into the components array to be parsed directly by marked.js
            components.push(text.slice(i, openingTagIndex));
            // parse the props for the special tag
            const props = parseProps(text.slice(openingTagIndex + tagType.length + 2, closingTagIndex).trim(), specialTags[tagType].propParsers);
            // push the jsx generated by the special tag's component into the components array
            components.push(specialTags[tagType].component(Object.assign(Object.assign({}, props), { key: Math.random() })));
            // repeat starting from the end of the closing tag
            i = closingTagIndex + `</${tagType}>`.length;
        }
        else {
            // otherwise, push the remaining text into the components array to be parsed directly by marked.js
            components.push(text.slice(i));
            break;
        }
    }
    // filter components array for non-empty components, parse any text left over from the above with marked.js
    return components
        .filter(item => !!item)
        .map((comp, index) => {
        return typeof comp === "string" ? (React.createElement("div", { key: index, dangerouslySetInnerHTML: { __html: marked.parse(comp) } })) : (comp);
    });
}
