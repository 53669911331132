import React, { useCallback } from "react";
import { TutorialFormatContainer, TutorialNavigationButtonsContainer } from "./styled";
import { useParams } from "react-router-dom";
import Button from "../Button";
import ArrowStub from "../Logos/ArrowStub";
import { contentPath } from "../../utils/constants";
import UrlHashNavigator from "../UrlHashNavigator";
export default function TutorialFormat({ tutorialSteps, stepIndex, titles, }) {
    const { moduleId, unitId } = useParams();
    const navToNextStep = useCallback(() => {
        (window.location.hash = `#${stepIndex + 1}`);
        window.scrollTo(0, 0);
    }, [stepIndex]);
    const navToPrevStep = useCallback(() => {
        (window.location.hash = `#${stepIndex - 1}`);
        window.scrollTo(0, 0);
    }, [stepIndex]);
    return (React.createElement(React.Fragment, null,
        React.createElement(UrlHashNavigator, { titles: titles }),
        React.createElement(TutorialFormatContainer, null,
            tutorialSteps[stepIndex],
            React.createElement(TutorialNavigationButtonsContainer, null,
                stepIndex === tutorialSteps.length - 1 ? (React.createElement("div", { style: { display: "flex", justifyContent: "center", gap: "2rem" } },
                    React.createElement(Button, { size: "large", label: "back-to-lessons", linkTo: contentPath(moduleId, unitId) }, "To unit"),
                    React.createElement(Button, { size: "large", label: "back-to-units", linkTo: contentPath(moduleId) }, "To module"))) : (React.createElement(Button, { size: "large", onClick: navToNextStep, label: "next step" },
                    React.createElement("div", { style: { display: "flex", gap: ".75rem", alignItems: "center" } },
                        "next ",
                        React.createElement(ArrowStub, { style: { height: "1.4rem", width: "calc(1.4rem * 210/500)" } })))),
                stepIndex > 0 ? (React.createElement(Button, { size: "large", onClick: navToPrevStep, label: "previous step" },
                    React.createElement("div", { style: { display: "flex", gap: ".75rem", alignItems: "center" } },
                        React.createElement(ArrowStub, { style: {
                                transform: "rotate(180deg)",
                                height: "1.4rem",
                                width: "calc(1.4rem * 210/500)",
                            } }),
                        " ",
                        "back"))) : null))));
}
export function TutorialStep(props) {
    return React.createElement("div", null, props.children);
}
