const EXTRA_LINES = 26;
export const adjustedCode = (code) => `let scriptOver = false;
let timeouts = [];
const ___setTimeout = setTimeout;
const ___clearTimeout = clearTimeout;
console.log = (...args) => postMessage({ type: "info", data: args });
setTimeout = (cb, ...args) => {
  let id;
  const callback = () => {
    cb();
    timeouts = timeouts.filter(timeoutId => timeoutId !== id);
    if (scriptOver && timeouts.length === 0) {
      postMessage("terminate");
    }
  }
  id = ___setTimeout(callback, ...args)
  timeouts.push(id);
  return id;
}
clearTimeout = (id) => {
  ___clearTimeout(id);
  timeouts = timeouts.filter(timeoutId => timeoutId !== id);
  if (scriptOver && timeouts.length === 0) {
    postMessage("terminate");
  }
}
try {
${code}
} catch (error) {
  postMessage({ type: "error", data: error.stack ? error.stack.split("\\n") : ["Error: " + error]});
  postMessage("terminate");
};
scriptOver = true;
if (timeouts.length === 0) {
  postMessage("terminate");
}`;
export const adjustStackTraceLine = (stackTraceLine) => {
    if (/\d/.test(stackTraceLine)) {
        let [line, character] = stackTraceLine.split(":").slice(-2);
        let [func] = stackTraceLine.split("(");
        line = parseInt(line) - EXTRA_LINES;
        func = func.replace(/onmessage/g, "Object.<anonymous>");
        return `${func}(~/testScript.js:${line}:${character}`;
    }
    else
        return stackTraceLine;
};
export const composeSyntaxErrorStackTrace = (errorEvent) => {
    return [errorEvent.message, `testScript.js:${errorEvent.lineno - EXTRA_LINES}:${errorEvent.colno}`];
};
export const composeTimeoutErrorStackTrace = () => {
    return ["Timeout exceeded.", "Your code did not terminate within 15 seconds.", "You may have an infinite loop."];
};
