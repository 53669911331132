var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { useNavigate } from "react-router-dom";
import { ConditionalLinkSvg } from "./styled";
export default function Logo(_a) {
    var { color, linkTo } = _a, rest = __rest(_a, ["color", "linkTo"]);
    const navigate = useNavigate();
    return (React.createElement(ConditionalLinkSvg, Object.assign({ xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: `0 0 1000 1000`, linkTo: linkTo, color: color }, rest, { onClick: linkTo ? () => navigate(linkTo) : null }),
        React.createElement("g", { transform: "matrix(1.2346 0 0 1.2346 725.6172 699.1459)", id: "86649" },
            React.createElement("path", { style: {
                    stroke: color,
                    strokeWidth: 50,
                    fill: "none",
                    opacity: 1,
                }, transform: "translate(-67.5, -67.5)", d: "M 132.5 67.5 C 132.5 103.399 103.399 132.5 67.5 132.5 C 31.6015 132.5 2.5 103.399 2.5 67.5 C 2.5 31.6015 31.6015 2.5 67.5 2.5 C 103.399 2.5 132.5 31.6015 132.5 67.5 Z" })),
        React.createElement("g", { transform: "matrix(1.2346 0 0 1.2346 281.6172 699.1459)", id: "43387" },
            React.createElement("path", { style: {
                    stroke: color,
                    strokeWidth: 50,
                    fill: "none",
                    opacity: 1,
                }, transform: "translate(-67.5, -67.5)", d: "M 132.5 67.5 C 132.5 103.399 103.399 132.5 67.5 132.5 C 31.6015 132.5 2.5 103.399 2.5 67.5 C 2.5 31.6015 31.6015 2.5 67.5 2.5 C 103.399 2.5 132.5 31.6015 132.5 67.5 Z" })),
        React.createElement("g", { transform: "matrix(1.2346 0 0 1.2346 725.6172 255.1459)", id: "353515" },
            React.createElement("path", { style: {
                    stroke: color,
                    strokeWidth: 50,
                    fill: "none",
                    opacity: 1,
                }, transform: "translate(-67.5, -67.5)", d: "M 132.5 67.5 C 132.5 103.399 103.399 132.5 67.5 132.5 C 31.6015 132.5 2.5 103.399 2.5 67.5 C 2.5 31.6015 31.6015 2.5 67.5 2.5 C 103.399 2.5 132.5 31.6015 132.5 67.5 Z" })),
        React.createElement("g", { transform: "matrix(1.2346 0 0 1.2346 280.6172 255.1459)", id: "768083" },
            React.createElement("path", { style: {
                    stroke: color,
                    strokeWidth: 50,
                    fill: "none",
                    opacity: 1,
                }, transform: "translate(-67.5, -67.5)", d: "M 132.5 67.5 C 132.5 103.399 103.399 132.5 67.5 132.5 C 31.6015 132.5 2.5 103.399 2.5 67.5 C 2.5 31.6015 31.6015 2.5 67.5 2.5 C 103.399 2.5 132.5 31.6015 132.5 67.5 Z" })),
        React.createElement("g", { transform: "matrix(3.3068 0 0 1.1227 503.1891 255.1465)", id: "734485" },
            React.createElement("path", { style: {
                    stroke: "none",
                    strokeWidth: 0,
                    fill: color,
                    opacity: 1,
                }, transform: "translate(-43.5, -25)", d: "M 2.5 2.5 H 84.5 V 47.5 H 2.5 V 2.5 Z" })),
        React.createElement("g", { transform: "matrix(0 3.3068 -1.1227 0 725.6173 482.5216)", id: "765952" },
            React.createElement("path", { style: {
                    stroke: "none",
                    strokeWidth: 0,
                    fill: color,
                    opacity: 1,
                }, transform: "translate(-43.5, -25)", d: "M 2.5 2.5 H 84.5 V 47.5 H 2.5 V 2.5 Z" })),
        React.createElement("g", { transform: "matrix(-3.8532 3.8663 -0.8248 -0.822 501.5836 476.9762)", id: "782288" },
            React.createElement("path", { style: {
                    stroke: "none",
                    strokeWidth: 0,
                    fill: color,
                    opacity: 1,
                }, transform: "translate(-43.5, -25)", d: "M 2.5 2.5 H 84.5 V 47.5 H 2.5 V 2.5 Z" }))));
}
