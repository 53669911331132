var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useContext, useMemo, useState } from "react";
import { ContentDetailCardContainer, ContentDetailCardTitle, ClearButton, DescriptionArea, SelectedContentName, SelectedContentDescription, } from "./styled";
import Cancel from "../Logos/Cancel";
import { ContentContext, ContentTypes } from "../../context/contentContext";
import MarkdownRenderer from "../MarkdownRenderer/MarkdownRenderer";
;
export default function ContentDetailCard(_a) {
    var { title, contentType, selectedContentId, defaultContentId } = _a, rest = __rest(_a, ["title", "contentType", "selectedContentId", "defaultContentId"]);
    const [_selectedContentId, setSelectedContentId] = useState();
    const { modules, units, lessons } = useContext(ContentContext);
    const selectedContent = useMemo(() => contentType === ContentTypes.module ? modules[_selectedContentId] : contentType === ContentTypes.unit ? units[_selectedContentId] : contentType === ContentTypes.lesson ? lessons[_selectedContentId] : null, [contentType, _selectedContentId, modules, units, lessons]);
    const hasDefaultId = useMemo(() => !!defaultContentId, []);
    const defaultContent = useMemo(() => {
        if (defaultContentId in modules) {
            return modules[defaultContentId];
        }
        else if (defaultContentId in units) {
            return units[defaultContentId];
        }
        else if (defaultContentId in lessons) {
            console.warn("The `ContentDetailCard` component cannot have a lesson's id as it's `defaultContentId` prop.");
        }
        ;
        return null;
    }, [defaultContentId, modules, units]);
    useEffect(() => {
        if (selectedContentId) {
            setSelectedContentId(selectedContentId);
        }
    }, [selectedContentId]);
    return (React.createElement(ContentDetailCardContainer, Object.assign({}, rest),
        React.createElement(DescriptionArea, null, defaultContent && !selectedContent ? (React.createElement(MarkdownRenderer, { markdown: defaultContent.description })) : (React.createElement(React.Fragment, null,
            React.createElement(ContentDetailCardTitle, null, title),
            React.createElement("div", { style: { display: "flex", justifyContent: "space-between", alignItems: "center" } },
                selectedContent ? React.createElement(SelectedContentName, null, selectedContent.name) : null,
                hasDefaultId ? (React.createElement(ClearButton, { label: `back to ${defaultContent === null || defaultContent === void 0 ? void 0 : defaultContent.name}'s description`, onClick: () => setSelectedContentId(null) },
                    React.createElement(Cancel, null))) : null),
            contentType === ContentTypes.module || contentType === ContentTypes.unit ? React.createElement(SelectedContentDescription, null, selectedContent === null || selectedContent === void 0 ? void 0 : selectedContent.shortDescription) : null)))));
}
