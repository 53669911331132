import React, { useContext, useMemo } from "react";
import Node from "../../components/Node";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import PageWrapper from "../../components/PageWrapper";
import { ContentContext } from "../../context/contentContext";
import { UserContext } from "../../context/userContext";
import { GoogleIdentityContext } from "../../context/googleIdentityContext";
import { contentPath } from "../../utils/constants";
import { Circle, InvisibleCircle, UnitDetailContainer, LessonsCircleContainer, LessonsCircleInnerContainer, NextButton, PrevButton, } from "./styled";
import { Availabilities } from "../../types";
import CheckoutCTA from "../../components/CheckoutCTA";
import { WindowSizeContext } from "../../context/windowSizeContext";
import ContentDetailCard from "../../components/ContentDetailCard";
import InfoHover from "../../components/InfoHover";
const circleRadius = 200; // px
export default function UnitDetail() {
    var _a, _b, _c;
    const { loggedIn } = useContext(GoogleIdentityContext);
    const { modules, units, lessons, loadingUnits, loadingLessons } = useContext(ContentContext);
    const { hasPurchasedModule } = useContext(UserContext);
    const { moduleId, unitId } = useParams();
    const { isMobile } = useContext(WindowSizeContext);
    const nextUnitId = useMemo(() => {
        var _a;
        return (_a = Object.values(units).find(unit => unit.index === units[unitId].index + 1)) === null || _a === void 0 ? void 0 : _a.id;
    }, [units, unitId]);
    const prevUnitId = useMemo(() => {
        var _a;
        return (_a = Object.values(units).find(unit => unit.index === units[unitId].index - 1)) === null || _a === void 0 ? void 0 : _a.id;
    }, [units, unitId]);
    const doneLoading = useMemo(() => {
        return (!loadingUnits &&
            unitId in units &&
            !loadingLessons &&
            Object.values(lessons).some(lesson => lesson.unitId === unitId));
    }, [unitId, loadingUnits, units, loadingLessons, lessons]);
    return (React.createElement(React.Fragment, null,
        doneLoading ? React.createElement(React.Fragment, null) : React.createElement(Loader, null),
        React.createElement(PageWrapper, { "aria-hidden": !doneLoading, style: { opacity: doneLoading ? 1 : 0 } },
            React.createElement(CheckoutCTA, { visible: loggedIn && !hasPurchasedModule(moduleId), unitId: unitId, moduleId: moduleId }),
            React.createElement(UnitDetailContainer, null,
                !isMobile ? (React.createElement(ContentDetailCard, { style: { margin: "0px", height: "100%" }, title: (_a = units[unitId]) === null || _a === void 0 ? void 0 : _a.name, defaultContentId: unitId })) : (null),
                React.createElement(LessonsCircleContainer, null,
                    React.createElement(InfoHover, { style: { position: "absolute", top: 0, left: "94.4%" } },
                        React.createElement("p", null, "Lessons within a unit don't need to be done in any particular order."),
                        React.createElement("p", null, "They cover the same material differently for different learning preferences/styles."),
                        React.createElement("p", null, "If you see an \"Overview\" maybe check that out first though.")),
                    React.createElement(LessonsCircleInnerContainer, { circleRadius: circleRadius },
                        React.createElement(InvisibleCircle, { radius: circleRadius },
                            React.createElement(Circle, { darkened: ((_b = modules[moduleId]) === null || _b === void 0 ? void 0 : _b.availability) === Availabilities.comingNext || ((_c = modules[moduleId]) === null || _c === void 0 ? void 0 : _c.availability) === Availabilities.comingSoon || !hasPurchasedModule(moduleId), radius: circleRadius }),
                            Object.values(lessons).map((lesson, i) => {
                                var _a;
                                const radians = ((i * Math.PI * 2) / Object.keys(lessons).length +
                                    Math.PI * (Object.keys(lessons).length < 3 ? 3 / 4 : 1 / 2) +
                                    Math.PI) %
                                    (2 * Math.PI);
                                const x = circleRadius + circleRadius * Math.cos(radians);
                                const y = circleRadius + circleRadius * Math.sin(radians);
                                const labelPosition = radians / Math.PI < 0.125
                                    ? "E" /* Positions.E */
                                    : radians / Math.PI < 0.375
                                        ? "SE" /* Positions.SE */
                                        : radians / Math.PI < 0.625
                                            ? "S" /* Positions.S */
                                            : radians / Math.PI < 0.875
                                                ? "SW" /* Positions.SW */
                                                : radians / Math.PI < 1.125
                                                    ? "W" /* Positions.W */
                                                    : radians / Math.PI < 1.375
                                                        ? "NW" /* Positions.NW */
                                                        : radians / Math.PI < 1.625
                                                            ? "N" /* Positions.N */
                                                            : radians / Math.PI < 1.875
                                                                ? "NE" /* Positions.NE */
                                                                : "E" /* Positions.E */;
                                return (React.createElement(Node, { linkTo: contentPath(moduleId, unitId, lesson.id), key: radians, label: lesson.name, svgName: lesson.id, labelPosition: labelPosition, unpurchased: !hasPurchasedModule(moduleId), availability: (_a = modules[moduleId]) === null || _a === void 0 ? void 0 : _a.availability, x: x, y: y }));
                            })))),
                prevUnitId && React.createElement(PrevButton, { size: isMobile ? "large" : "small", isMobile: isMobile, linkTo: contentPath(moduleId, prevUnitId), label: "previous unit" }, "Prev Unit"),
                nextUnitId && React.createElement(NextButton, { size: isMobile ? "large" : "small", isMobile: isMobile, linkTo: contentPath(moduleId, nextUnitId), label: "next unit" }, "Next Unit")))));
}
