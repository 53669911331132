import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import { buttonBackgroundColor, buttonTextColor, buttonHoverBackgroundColor, buttonHoverTextColor, } from "../../theme";
const unit = "em";
const height = (props) => ({
    small: "30px",
    medium: "40px",
    large: "3rem",
    huge: "5rem",
}[props.size]);
const fontSize = (props) => props.theme.fontSizes[props.size];
const padding = ({ size }) => ({
    small: [0, 0.8, 0, 0.8],
    medium: [0, 0.95, 0, 0.95],
    large: [0, 1, 0, 1],
    huge: [0, 1, 0, 1],
}[size]
    .map(val => val + unit)
    .join(" "));
export const StyledLink = styled(NavLink) `
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: calc(${height} / 2);
  border-style: inset;
  border: 2px solid ${buttonTextColor};
  text-align: center;
  white-space: nowrap;
  background-position: center;
  transition: background 0.8s;
  color: ${buttonTextColor};
  background-color: ${buttonBackgroundColor};
  padding: ${padding};
  height: ${height};
  font-size: calc(${fontSize} * 0.9);
  min-width: ${p => p.$minWidth}px;
  box-sizing: content-box;
  box-shadow: 0px 2px 2px ${p => p.theme.colors["blackDark"]};
  fill: ${buttonTextColor};
  width: fit-content;

  &:hover {
    background-color: ${buttonHoverBackgroundColor};
    color: ${buttonHoverTextColor};
    fill: ${buttonHoverTextColor};
    transition: all 0.1s ease-in;
    border: 2px solid ${buttonHoverTextColor};
  }

  &:disabled {
    background-color: ${buttonBackgroundColor} !important;
    color: ${p => (p.isLoading ? buttonHoverTextColor(p) : p.theme.colors["greyLight"])} !important;
    fill: ${p => (p.isLoading ? buttonHoverTextColor(p) : p.theme.colors["greyLight"])} !important;
    border: 2px solid ${p => (p.isLoading ? buttonHoverTextColor(p) : p.theme.colors["greyLight"])};
    cursor: auto;
  }

  &:active:not([disabled]) {
    transform: translateY(0.12rem);
    transition: all 0.05s ease-in;
    box-shadow: none;
  }
`;
export const StyledButton = styled.button `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: calc(${height} / 2);
  border-style: inset;
  border: 2px solid ${buttonTextColor};
  text-align: center;
  white-space: nowrap;
  background-position: center;
  transition: background 0.8s;
  color: ${buttonTextColor};
  background-color: ${buttonBackgroundColor};
  padding: ${padding};
  height: ${height};
  font-size: calc(${fontSize} * 0.9);
  min-width: ${p => p.minWidth}px;
  box-sizing: content-box;
  box-shadow: 0px 2px 2px ${p => p.theme.colors["blackDark"]};
  fill: ${buttonTextColor};

  &:hover {
    background-color: ${buttonHoverBackgroundColor};
    color: ${buttonHoverTextColor};
    fill: ${buttonHoverTextColor};
    transition: all 0.1s ease-in;
    border: 2px solid ${buttonHoverTextColor};
  }

  &:disabled {
    background-color: ${buttonBackgroundColor} !important;
    color: ${p => (p.isLoading ? buttonHoverTextColor(p) : p.theme.colors["greyLight"])} !important;
    fill: ${p => (p.isLoading ? buttonHoverTextColor(p) : p.theme.colors["greyLight"])} !important;
    border: 2px solid ${p => (p.isLoading ? buttonHoverTextColor(p) : p.theme.colors["greyLight"])};
    cursor: auto;
  }

  &:active:not([disabled]) {
    transform: translateY(0.12rem);
    transition: all 0.05s ease-in;
    box-shadow: none;
  }
`;
const keyframeCycleSeconds = (props) => (props.stepDurationMillis * (props.message.length + 1)) / 1000;
const keyframesWriter = (props) => {
    let content = "";
    const steps = props.message
        .split("")
        .map((char, i) => ({
        str: (content += char),
        percent: Math.floor((100 * i) / props.message.length),
    }))
        .map(step => `${step.percent}% { content: "${step.str}"; }`);
    return keyframes `
    ${steps.join("\n")}
    100% { content: ""; }
  `;
};
const keyframesBlink = () => keyframes `
  0% { opacity: 0; }
  100% { opacity: 1; }
`;
const centerOffset = (props) => {
    const characterCount = props.message.length;
    const [amount, unit] = fontSize(props).match(/((\d+(\.\d+)?)|(\d?\.\d+))|[\D]+/g);
    return (-1 * parseFloat(amount) * (characterCount + 1)) / 2 + unit;
};
export const Typewriter = styled.p `
  font-size: ${fontSize};
  text-align: left;
  margin-left: ${centerOffset};
  transform: translateX(50%);
  margin-top: 0;
  margin-bottom: 0;
  font-family: ${p => p.theme.fonts.monospace};

  &:before {
    content: "";
    animation: ${keyframesWriter} ${keyframeCycleSeconds}s linear infinite;
  }

  &:after {
    content: "|";
    animation: ${keyframesBlink} infinite alternate ${(p) => p.stepDurationMillis / 2000}s;
  }
`;
