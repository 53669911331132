import styled from "styled-components";
import Button from "../Button";
import Input from "../Input";
import { NavLink } from "react-router-dom";
export const ClearButton = styled(Button) `
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  padding: 0;
  &:hover {
    border: none;
  }
  &:focus {
    border: none;
  }
`;
export const DetailSearchInput = styled(Input) `
  width: 20rem;
  input {
    font-size: ${p => p.theme.fontSizes.small};
  }
`;
export const SearchArea = styled.div `
  position: relative;
  transition: all 0.25s ease-out;
  padding-bottom: 3px;
`;
export const SelectedContentName = styled.h2 `
  font-family: ${p => p.theme.fonts.sasSerif};
  font-size: ${p => p.theme.fontSizes.medium};
  color: ${p => p.theme.fontColor};
`;
export const SelectedContentDescription = styled.p `
  font-family: ${p => p.theme.fonts.sasSerif};
  font-size: ${p => p.theme.fontSizes.small};
  color: ${p => p.theme.fontColor};
  margin: 0px;
`;
export const SearchResultItemBorder = styled.div `
  border: 1px ${p => p.theme.secondaryColor} !important;
  border-style: ${p => p.isFirstItem ? "solid none" : "none none solid none"} !important;
  padding: 0;
`;
export const SearchResultItem = styled(NavLink) `
  text-decoration: none;
  cursor: pointer;
`;
export const SearchResultName = styled.p `
  font-family: ${p => p.theme.fonts.sasSerif};
  font-size: ${p => p.theme.fontSizes.medium};
  color: ${p => p.theme.linkColor};
  text-decoration: ${p => p.hovered ? "underline" : "none"};
  margin: .5rem 0;
`;
export const SearchResultMatchInfo = styled.div `
  font-family: ${p => p.theme.fonts.sasSerif};
  font-size: ${p => p.theme.fontSizes.tiny};
  color: ${p => p.theme.fontColor};
  margin-bottom: .5rem;

  span {
    background-color: ${p => p.theme.colors.white};
    color: ${p => p.theme.colors.blackDark};
  }
`;
export const SearchResultsContainer = styled.div `
  z-index: 12345;
  border-radius: 0.5rem;
  outline: ${p => p.hasResults ? `2px ${p.theme.secondaryColor} solid` : "none"} ;
  padding: ${p => p.hasResults ? ".5rem" : "none"};
  background-color: ${p => p.theme.primaryColor};
  transform: translateY(-1px);
  position: absolute;
  top: 100%;
  width: 100%;
  overflow-y: auto;
  max-height: 66vh;
`;
