import React, { useContext, useMemo } from "react";
import BoardCard from "../../components/BoardCard";
import Loader from "../../components/Loader";
import LoginWall from "../../components/LoginWall";
import PageWrapper from "../../components/PageWrapper";
import { BoardsContext } from "../../context/boardsContext";
import { GoogleIdentityContext } from "../../context/googleIdentityContext";
import { BoardCardsContainer, NoKanbanResults, } from "./styled";
export default function KanbanList() {
    const { boards, loadedBoards } = useContext(BoardsContext);
    const { loggedIn } = useContext(GoogleIdentityContext);
    const boardsList = useMemo(() => Object.values(boards), [boards]);
    return (React.createElement(PageWrapper, { style: { margin: "0 5vw" } }, !loggedIn ? (React.createElement(LoginWall, null)) : !loadedBoards ? (React.createElement(Loader, null)) : (React.createElement(BoardCardsContainer, null, boardsList.length ? (boardsList.map((board, index) => React.createElement(BoardCard, { key: index, board: board }))) : (React.createElement(NoKanbanResults, null,
        React.createElement("p", null, "No project boards yet")))))));
}
