import styled from "styled-components";
export const PaywallContainer = styled.div `
  font-size: ${p => p.theme.fontSizes.large};
  color: ${p => p.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-contents: center;
`;
export const ValueProp = styled.div `
  display: flex;
  align-items: center;

  * {
    margin: 0.25rem;
    font-size: ${p => p.theme.fontSizes.small};
  }

  span {
    display: contents;
  }
`;
export const PurchaseArea = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: -webkit-fill-available;
  margin: 1rem 2rem;
  padding: 3rem 1rem;
`;
export const MiniNode = styled.div `
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.2rem solid ${p => p.theme.colors.magentaSoft};;
`;
