import styled from "styled-components";
import { MAX_MOBILE_SCREEN_SIZE } from "../../utils/constants";
import Button from "../../components/Button";
import Input from "../../components/Input";
export const BoardCardsContainer = styled.div `
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;

  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    margin-top: 2rem;
  }
`;
export const KanbanSearchInput = styled(Input) `
  width: 24rem;
  input {
    font-size: ${p => p.theme.fontSizes.small};
  }
`;
export const KanbanSearchClearButton = styled(Button) `
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  padding: 0;
  &:hover {
    border: none;
  }
  &:focus {
    border: none;
  }
`;
export const NoKanbanResults = styled.div `
  color: ${p => p.theme.fontColor};
  font-size: ${p => p.theme.fontSizes.small};
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
