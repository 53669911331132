var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useRef, useMemo, useCallback, useContext } from "react";
import { httpGet } from "../../utils/requests";
import { VideoPlayerContainer, StyledVideoTag } from "./styled";
import { SnackbarContext } from "../../context/snackbarContext";
import Loader from "../Loader";
export default function VideoPlayer({ module, unit, videoName, }) {
    const videoRef = useRef();
    const [videoUrl, setVideoUrl] = useState();
    const loading = useMemo(() => !videoUrl, [videoUrl]);
    const { pushToast } = useContext(SnackbarContext);
    useEffect(() => {
        if (module && unit && videoName) {
            (() => __awaiter(this, void 0, void 0, function* () {
                const { data } = yield httpGet(`/video/${module}/${unit}/${videoName}`);
                setVideoUrl(data.signedUrl);
            }))();
        }
    }, [module, unit, videoName]);
    const onError = useCallback(() => {
        pushToast({
            level: "error",
            message: "Something went wrong. Your video might have expired. Try refreshing the page."
        });
    }, []);
    return (React.createElement(VideoPlayerContainer, { loading: loading }, loading ? (React.createElement(Loader, { scale: 1.5 })) : (React.createElement(StyledVideoTag, { onError: onError, ref: videoRef, controls: true, controlsList: "nodownload" },
        React.createElement("source", { src: videoUrl }),
        "Uh oh! Your browser does not support the video tag. Try Chrome."))));
}
