import styled from "styled-components";
export const QAContainer = styled.div `
  background-color: ${p => p.theme.accentColor};
  padding: 0 1rem;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 2px 2px ${p => p.theme.colors["blackDark"]};
`;
export const QuestionContainer = styled.div `
  border: none;
  background-color: ${p => p.theme.accentColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  p {
    font-family: ${p => p.theme.fonts.sanSerif};
  }
`;
export const Delimiter = styled.div `
  background-color: ${p => p.theme.colors["white"]};
  width: 100%;
  height: 1px;
  border-radius: 100%;
  position: relative;
  margin: auto;
`;
export const AnswerContainer = styled.div `
  height: ${p => (p.open ? p.answerHeight : 0)}px !important;
  transition: height 0.15s ease-in;
`;
export const ExpandIcon = styled.button `
  border: none;
  border-radius: 100%;
  background-color: ${p => p.theme.accentColor};
  transform: rotate(${p => (p.open ? 0 : -180)}deg);
  transition: all 0.1s ease-in;
`;
