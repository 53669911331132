import styled from "styled-components";
import Question from "../Logos/Question";
export const Circle = styled.div `
  position: relative;
  width: 1.5rem;
  background-color: ${p => p.focused ? p.theme.blackDark : p.theme.colors.greyLight};
  border-radius: 50%;
  display: flex;
  transition: all .2s ease-out;
`;
export const QuestionIcon = styled(Question) `
  fill: ${p => p.focused ? p.theme.colors.blueLight : p.theme.colors.black};
  transition: all .2s ease-out;
`;
export const ChildrenContainer = styled.div `
  border-radius: .25rem;
  position: absolute;
  background-color: ${p => p.theme.colors.black};
  color: ${p => p.theme.colors.white};
  top: ${p => p.top ? "100%" : 0};
  left: ${p => p.left ? 0 : "100%"};
  max-height: ${p => p.focused ? "15rem" : 0};
  overflow: auto;
  transition: all .2s ease-out;
  transform: translate(${p => p.left ? ".75rem" : "calc(-100% - .75rem)"}, ${p => p.top ? "-.75rem" : "calc(-100% + .75rem)"});
  z-index: 10;
  padding: 0;
  box-shadow: ${p => p.focused ? `1px 1px 5px ${p.theme.colors.blackDark}` : "none"};

  ::-webkit-scrollbar {
    display: none;
    opacity: 0;
  }
  
  ::-webkit-scrollbar-thumb {
    display: none;
    opacity: 0;
  }
  
  ::-webkit-scrollbar-corner {
    display: none;
    opacity: 0;
  }

  * {
    max-width: 20rem;
    width: max-content;
    margin: .5rem;
  }
`;
