import styled from "styled-components";
import { NodeStatuses } from "./types";
import LockLogo from "../Logos/LockLogo";
import DynamicLogo from "../Logos/DynamicLogo";
const positions = [
    "E" /* Positions.E */,
    "NE" /* Positions.NE */,
    "N" /* Positions.N */,
    "NW" /* Positions.NW */,
    "W" /* Positions.W */,
    "SW" /* Positions.SW */,
    "S" /* Positions.S */,
    "SE" /* Positions.SE */,
];
const labelPositionY = ({ labelPosition, hasSubLabel, radius }) => {
    const radians = Math.PI + (positions.indexOf(labelPosition) / 4) * Math.PI;
    const subLabelAdjustment = hasSubLabel && labelPosition === "N" /* Positions.N */ ? "-10px" : hasSubLabel && labelPosition === "NE" /* Positions.NE */ ? "-5px" : hasSubLabel && labelPosition === "NW" /* Positions.NW */ ? "-5px" : "0px";
    return `calc(${radius}px + ${Math.sin(radians) * radius}px + ${subLabelAdjustment})`;
};
const labelPositionX = ({ labelPosition, radius }) => {
    const radians = (positions.indexOf(labelPosition) / 4) * Math.PI;
    return `calc(${radius}px + ${Math.cos(radians) * radius}px)`;
};
const centerText = ({ labelPosition }) => {
    if (labelPosition === "E" /* Positions.E */)
        return `translate(0, -50%)`;
    if (labelPosition === "NE" /* Positions.NE */)
        return `translate(0, -100%)`;
    if (labelPosition === "N" /* Positions.N */)
        return `translate(-50%, -150%)`;
    if (labelPosition === "NW" /* Positions.NW */)
        return `translate(-100%, -100%)`;
    if (labelPosition === "W" /* Positions.W */)
        return `translate(-100%, -50%)`;
    if (labelPosition === "SW" /* Positions.SW */)
        return `translate(-100%, 0)`;
    if (labelPosition === "S" /* Positions.S */)
        return `translate(-50%, 0)`;
    if (labelPosition === "SE" /* Positions.SE */)
        return `translate(0, 0)`;
};
const labelFontSize = ({ radius }) => Math.min(Math.max(16, radius * 0.75), 22);
const edgeWidth = ({ radius, theme }) => Math.min(Math.max(4, radius * theme.nodeFatness), 30);
const unfocusColor = ({ unfocusColorName, theme }) => theme.colors[unfocusColorName] || theme.secondaryColor;
const focusColor = ({ focusColorName, theme }) => theme.colors[focusColorName] || theme.linkColor;
const focusFilter = ({ focusColorName, theme }) => theme.colorFilters[focusColorName] || theme.colorFilters.linkColor;
const unfocusFilter = ({ unfocusColorName, theme }) => theme.colorFilters[unfocusColorName] || theme.colorFilters.secondaryColor;
const scaleFactor = 1.05;
export const Label = styled.div `
  white-space: nowrap;
  position: absolute;
  margin: 0;
  color: ${unfocusColor};
  left: ${labelPositionX};
  top: ${labelPositionY};
  transform: ${centerText};
  font-size: ${labelFontSize}px;

  border-radius: 12px;
  text-align: center;
  white-space: nowrap;
  background-position: center;
  padding: .15rem 0.5em;

  transition: background 0.8s;
  box-sizing: content-box;

  &:hover {
    cursor: pointer;
  }
`;
export const SubLabel = styled.span `
  font-size: ${p => p.theme.fontSizes.tiny};
  position: absolute;
  top: 100%;
  left: .75em;
`;
export const NodeBase = styled.button `
  z-index: 1001;
  border-radius: 50%;
  position: ${p => (p.x === null && p.y === null ? "relative" : "absolute")};
  top: ${p => p.y}px;
  left: ${p => p.x}px;
  border: 1rem;
  background-color: ${p => p.theme.primaryColor};
  box-shadow: inset 0 0 0 ${edgeWidth}px ${unfocusColor};
  margin: 0;
  padding: 0;
  width: ${p => p.radius * 2}px;
  height: ${p => p.radius * 2}px;
  transition: all 0.07s ease-in, top 0s, left 0s;
  
  * {
    color: ${unfocusColor};
  }

  img {
    filter: ${unfocusFilter};
  }

  div {
    transition: background-color 0s;
  }

  svg {
    fill: ${unfocusColor};
  }

  span {
    padding: .1rem 0.25em;
    border-radius: 10px;
  }

  &:hover,
  &:focus {
    z-index: 100001;
    box-shadow: inset 0 0 0 ${edgeWidth}px ${focusColor};
    transform: scale(${scaleFactor});

    img {
      filter: ${focusFilter};
    }

    * {
      z-index: 100001;
      color: ${focusColor};
    }

    div, span {
      background-color: ${p => p.theme.primaryColor};
    }

    svg {
      fill: ${focusColor};
    }
  }
`;
export const StyledLockLogo = styled(LockLogo) `
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
`;
const indicatorColor = ({ status, theme }) => status === NodeStatuses.alerted
    ? theme.colors.red
    : status === NodeStatuses.unexplored
        ? theme.colors["blueIntense"]
        : "transparent";
export const Indicator = styled.div `
  width: ${p => (p.status !== NodeStatuses.seen ? "50%" : 0)};
  height: ${p => (p.status !== NodeStatuses.seen ? "50%" : 0)};
  border-radius: 50%;
  background-color: ${indicatorColor} !important;
  box-shadow: 0 0 6px ${indicatorColor}, 0 0 6px ${indicatorColor};
`;
export const NodeLogo = styled(DynamicLogo) `
  position: absolute;
  top: calc(50% - ${p => p.size}px / 2);
  left: calc(50% - ${p => p.size}px / 2);
  height: ${p => p.size}px;
  width: ${p => p.size}px;
  display: block;
  transition: all 0.07s ease-in, top 0s, left 0s;
`;
