import React from "react";
import PageWrapper from "../../components/PageWrapper";
import { Header, InfoSection, Paragraph, ListElement, InfoSectionsContainer } from "./styled";
export default function About() {
    return (React.createElement(PageWrapper, null,
        React.createElement(InfoSectionsContainer, null,
            React.createElement(InfoSection, null,
                React.createElement(Header, null, "Mission"),
                React.createElement(Paragraph, null, "Dev Yourself aims to fill the gap in educational resources between fulltime immersive bootcamps and free online tutorials. Immersive bootcamps provide structure, and challenge, whereas free online resources provide flexibility (and are free!). Though not free, Dev Yourself will always provide the highest possible quality of lessons and educational tools at a tiny fraction of a fulltime bootcamp's tuition, while also giving you the flexibility and variety to learn at your own pace in your own style.")),
            React.createElement(InfoSection, null,
                React.createElement(Header, null, "Curriculum"),
                React.createElement(Paragraph, null, "Dev Yourself's curriculum is fullstack Javascript, specifically web development. It is designed to hit all the stops to enable you to compete with bootcamp graduates. CS graduates from traditional universities learn different material (different, not better), and Dev Yourself's content does occasionally stray into that sphere, but for the most part it is fair to compare to hold it to the standard of any top web development bootcamp's curriculum. Also, there is some divergence in the curriculum, and some branches you don't necessarily need to exhaust to develop really strong skills for yourself. That said, here's roughly what you'll learn in roughly the order you'll learn it:"),
                React.createElement(ListElement, null,
                    React.createElement("span", { style: { textDecoration: "underline" } }, "Getting started"),
                    " - You'll install some of the software you'll need to write/run your code."),
                React.createElement(ListElement, null,
                    React.createElement("span", { style: { textDecoration: "underline" } }, "Javascript basics"),
                    " - Everything you might gain from the first a bootcamp prep course: variables, control flow, data types, functions, etc."),
                React.createElement(ListElement, null,
                    React.createElement("span", { style: { textDecoration: "underline" } }, "Javascript in the browser"),
                    " - DOM manipulation, Bundling, React, Axios"),
                React.createElement(ListElement, null,
                    React.createElement("span", { style: { textDecoration: "underline" } }, "Javascript on the server"),
                    " - NodeJS, Express"),
                React.createElement(ListElement, null,
                    React.createElement("span", { style: { textDecoration: "underline" } }, "Relational databases"),
                    " - Postgres, PSQL, Knex"),
                React.createElement(ListElement, null, "Once you have a taste of those you'll deepen your knowledge of them with at least one fullstack application (for practice) before launching into the portfolio projects you'll ultimately use to gain employment."),
                React.createElement(ListElement, null,
                    React.createElement("span", { style: { textDecoration: "underline" } }, "Portfolio Projects"),
                    " - Now you'll solidify and expand your knowledge by creating more fully featured apps that showcase the skills and technologies above, plus others like GraphQL, Websockets, and many other lesser technologies that would be tedious/pointless to list, but importantly will give your work the look and feel of a professional"),
                React.createElement(ListElement, null,
                    React.createElement("span", { style: { textDecoration: "underline" } }, "Tangents"),
                    " - You may find you like algorithms (I do). You may find you want to learn regex, or about compilers, or something else that doesn't neatly fit in the progression. I strive to provide a deep and wide pool of knowledge, so you can take as many fruitful detours as you would like along on your learning path.")),
            React.createElement(InfoSection, null,
                React.createElement(Header, null, "Motivation behind Dev Yourself"),
                React.createElement(Paragraph, null, "I (Abel, the creator of Dev Yourself) love helping people find careers in tech. I have always loved learning, but also, was failed many times by the education system. So, education is something I have thought a lot about; specifically how it could be improved."),
                React.createElement(Paragraph, null,
                    "I also have noticed, from my hours scrolling reddit, and my years teaching/speaking with people about transitioning into tech careers, some trends. For one, \"tutorial hell\" the endless, fruitless exercise of going step by step though unfamiliar content. Capturing, comprehending, and regurgitating the pieces one by one, only to reach the end and have no confidence in one's ability to actually use the information in another context. I can firmly say it ",
                    React.createElement("span", { style: { textDecoration: "underline" } }, "isn't you"),
                    ". It's tutorials. So although I also provide tutorials, that is only one of the variety of formats of Dev Yourself's lessons."),
                React.createElement(Paragraph, null, "Another important fact is that context matters, in particular people find it way easier to learn when the information isn't just scattered details. So Dev Yourself's curriculum is as project-oriented as possible. There are, especially in the beginning of learning to code, times when it's difficult to build a project around the material. Still, one of my main goals is to provide you with the most engaging education possible."),
                React.createElement(Paragraph, null, "Coding is often (improperly) introduced to people through a lens of which technologies to learn, what is popular, or what \"formula\" to use (for getting a job, making a project, solving a problem, etc.). This is bad. When it comes to engineering\u2014of any kind\u2014understanding is gold. This, fundamentally, is what Dev Yourself is all about: you understanding code. You'll still use a variety of technologies along the way, as you should for any reputable curriculum, but the emphasis will be on understanding. This means instructions will sometimes guild you to break your code before explaining how and why things broke. Tangents in general are a big part of the content here."),
                React.createElement(Paragraph, null, "Finally, I care about your independence. I want you to really become a dev! Part of being a developer is having your own development environment. Many online learning platforms have niftier built-in IDEs for you to test or submit code. I definitely wouldn't knock them for that, but I think getting your code to run on your own machine builds a better foundation for you to both cultivate your skills and apply them. So, the code sandbox I provide is minimal. It's meant to be used for you to participate in smaller activities you'll encounter as you are reading/watching lessons, not to build projects.")),
            React.createElement(InfoSection, null,
                React.createElement(Header, null, "Recipe for success (2 ingredients)"),
                React.createElement(Paragraph, null,
                    "1. You need to ",
                    React.createElement("span", { style: { textDecoration: "underline" } }, "be diligent"),
                    ". Don't burn yourself out. Don't put crazy pressure on yourself. But, you do need to spend time writing code to learn to write code."),
                React.createElement(Paragraph, null,
                    "2. You're far more likely to thrive if you have",
                    " ",
                    React.createElement("span", { style: { textDecoration: "underline" } }, "intrinsic motivation"),
                    ". Software engineering often comes with a certain prestige, compensation, and flexibility that people aspire to. I don't blame anyone for wanting to pursue those things. However, unless you're very talented or have very specific kind of personality (which I'm not saying you aren't/don't), it's unlikely that those things alone will carry you though this entire process. The enjoyment of solving problems/puzzles, of making things work, and/or of building interactive software are some of the vital intrinsic motivations I've seen propel people into a software engineering career. If none of that sounds like you, or if you don't know if that sounds like you, try the first module's worth of lessons (which is free) and maybe try some other free coding resources online to see if you enjoy the activity of writing code at all. If it's not giving you a spark, that might be a sign you're not ready to do this. I myself did not have that spark the first time I was exposed to writing code (now I love it so much I do it every day for fun), so by no means should you take this as an omen that you cannot do something, but be aware that finding a way to enjoy the act of writing code (or at least some aspects of it) is a pretty critical ingredient to long term success.")))));
}
