import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BoardsContext } from "../../context/boardsContext";
import { BoardCardContainer, Title, Status, Path, InfoSection, StatusSection, DeleteAction, } from "./styled";
import { boardPath } from "../../utils/constants";
import Minus from "../Logos/Minus";
export default function BoardCard({ board }) {
    const { title, boardId, status } = board;
    const navigate = useNavigate();
    const { deleteBoard } = useContext(BoardsContext);
    const requestDelete = useCallback((event) => {
        event.stopPropagation();
        deleteBoard(boardId);
    }, [boardId]);
    return (React.createElement(BoardCardContainer, { "data-cy": `board-${boardId}`, onClick: () => navigate(boardPath(boardId)) },
        React.createElement(InfoSection, null,
            React.createElement(Title, null, title)),
        React.createElement(StatusSection, null,
            React.createElement("div", { style: { display: "flex", flexDirection: "column" } },
                React.createElement(Path, null, "Status"),
                React.createElement(Status, null, status)),
            React.createElement(DeleteAction, { label: "delete board", color: "red", onClick: requestDelete },
                React.createElement(Minus, { style: { width: "66%", height: "66%" } })))));
}
