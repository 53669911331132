import styled from "styled-components";
export const FallbackViewContainer = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90vh;
  justify-content: center;
  align-items: center;
`;
export const StyledWarningLogo = styled.svg `
  height: 6rem;
  width: 6rem;
  margin: 1rem;

  path {
    fill: ${p => { var _a; return (_a = p.color) !== null && _a !== void 0 ? _a : p.theme.secondaryColor; }};
  }
`;
export const Apology = styled.p `
  font-size: ${p => p.theme.fontSizes.huge};
  color: ${p => p.theme.secondaryColor};
`;
export const Explanation = styled.p `
  font-size: ${p => p.theme.fontSizes.medium};
  color: ${p => p.theme.secondaryColor};
  width: 60vw;
`;
export const Email = styled.span `
  position: relative;
  font-size: calc(${p => p.theme.fontSizes.medium} - 4px);
  font-family: ${p => p.theme.fonts.code};
  color: ${p => p.theme.linkColor};
  margin: 0 1rem 0 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
