import styled from "styled-components";
export const PleaseLoginContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const Apology = styled.div `
  text-align: center;
  font-size: ${p => p.theme.fontSizes.large};
  color: ${p => p.theme.colors["white"]};

  p {
    margin: 0;
  }
`;
export const CTA = styled.div `
  font-size: ${p => p.theme.fontSizes.large};
  color: ${p => p.theme.colors["white"]};
  height: 2rem;
  margin-bottom: 4rem;
`;
export const DirectToPolicy = styled.div `
  font-size: ${p => p.theme.fontSizes.small};
  color: ${p => p.theme.colors["white"]};
  text-align: center;
`;
