var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { theme } from "../../theme";
const SearchIcon = (_a) => {
    var { color, size, style } = _a, rest = __rest(_a, ["color", "size", "style"]);
    return (React.createElement("svg", Object.assign({ width: "199px", height: "199px", viewBox: "0 0 24.00 24.00", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: Object.assign({ width: size !== null && size !== void 0 ? size : "1.5rem", height: size !== null && size !== void 0 ? size : "1.5rem" }, style) }, rest),
        React.createElement("g", { id: "SVGRepo_bgCarrier", strokeWidth: "0" }),
        React.createElement("g", { id: "SVGRepo_tracerCarrier", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("g", { id: "SVGRepo_iconCarrier" },
            " ",
            React.createElement("g", { id: "Interface / Search_Magnifying_Glass" },
                " ",
                React.createElement("path", { id: "Vector", d: "M15 15L21 21M10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17Z", stroke: color || theme.colors.white, strokeWidth: "2.5", strokeLinecap: "round", strokeLinejoin: "round" }),
                " "),
            " ")));
};
export default SearchIcon;
