import styled from "styled-components";
import Input from "../Input";
import Button from "../Button";
export const overrideUnderlineWidth = "98%";
export const ViewOverlay = styled.div `
  top: 0;
  left: 0;
  position: fixed;
  background-color: ${p => p.theme.primaryColor}cc;
  width: 100vw;
  height: 100vh;
  z-index: ${p => (p.open ? 1000 : -1000)};
  opacity: ${p => (p.open ? 1 : 0)};
  transition: all 0.15s ease-out;
  display: ${open ? "flex" : "none"};
  justify-content: center;
  align-items: center;
`;
export const FormArea = styled.div `
  display: flex;
  gap: 0.6rem;
  flex-direction: column;
  border-radius: 0.25rem;
  background-color: ${p => p.theme.accentColor};
  height: 75vh;
  width: 66vw;
  transform: scale(${p => (p.open ? 1 : 0.4)});
  z-index: ${p => (p.open ? 1001 : -1000)};
  padding: 4rem 4rem 2rem 4rem;
  transition: all 0.15s ease-out;
  box-shadow: 0px 4px 8px ${p => p.theme.colors["blackDark"]};
`;
export const TitleInput = styled(Input) `
  height: 3rem;
  width: 100%;
  border-radius: 0.25rem;
  border: none;
  background-color: ${p => p.theme.primaryColor};

  input {
    color: ${p => p.theme.fontColor};
    font-size: ${p => p.theme.fontSizes.large};
  }

  &:hover {
    border: none;
  }

  &:focus-within {
    border: none;
  }
`;
export const DescriptionInput = styled.textarea `
  border-radius: 0.25rem;
  padding: 1rem 1rem;
  min-height: 35%;
  overflow-y: auto;
  resize: none;
  white-space: wrap;
  border: none;
  color: ${p => p.theme.fontColor};
  font-size: ${p => p.theme.fontSizes.small};
  background-color: ${p => p.theme.primaryColor};

  &:focus {
    outline: none;
  }
`;
export const Checklist = styled.div `
  background-color: ${p => (p.beingDraggedOver ? p.theme.primaryColor : "inherit")};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  height: 4rem;
  padding: 0.25rem;
  gap: 0.25rem;
  transition: background-color 0.1s ease-out;
`;
export const ChecklistItem = styled.div `
  height: 42px;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  div {
    flex-grow: 1;
  }
`;
export const TaskInput = styled(Input) `
  border-radius: 0.25rem;
  border: none;
  background-color: ${p => p.theme.primaryColor};
  flex-grow: 1;

  input {
    color: ${p => p.theme.fontColor};
    font-size: ${p => p.theme.fontSizes.small};
    display: flex;
    flex-wrap: wrap;
    word-wrap: break-word;
    resize: vertical;
  }

  &:hover {
    border: none;
  }

  &:focus-within {
    border: none;
  }
`;
export const ChecklistCheckbox = styled(Button) `
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 66%;
    height: 66%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const DeleteTaskAction = styled(Button) `
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    width: 66%;
    height: 66%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
