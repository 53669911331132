import styled from "styled-components";
import { MAX_MOBILE_SCREEN_SIZE } from "../../utils/constants";
import Button from "../Button";
export const BreadCrumbsAndSearchContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Container = styled.div `
  padding: 0 2rem 2rem 2rem;
  width: 100%;

  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    padding: max(20%, 7rem) 0 0 0;
  }
`;
export const OpenCodeEditorButton = styled(Button) `
  height: 2rem;
  width: 2rem;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 8.55rem;
  right: calc(5% + 2.5rem);
  min-width: 0;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    display: none;
    opacity: 0;
  }
`;
