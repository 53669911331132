import styled from "styled-components";
export const ConditionalLinkSvg = styled.svg `
  color: ${p => p.color};
  z-index: 3;

  &:hover {
    color: ${p => (!!p.linkTo ? p.theme.colors["white"] : p.color)};
    cursor: ${p => (!!p.linkTo ? "pointer" : "default")};
  }
`;
