import React, { useMemo } from "react";
import { TicketCardContainer, Title, Description, DescriptionText, Completion, CompletionProgressBar, } from "./styled";
export default function TicketCard({ ticket, beingDragged }) {
    const { title, description, checklist, uuid } = ticket;
    const percentageComplete = useMemo(() => {
        return checklist.length > 0
            ? Math.ceil(100 * (checklist.filter(item => item.complete).length / checklist.length))
            : null;
    }, [checklist]);
    return (React.createElement(TicketCardContainer, { "data-cy": `ticket-${uuid}`, beingDragged: beingDragged },
        React.createElement(Title, null, title),
        React.createElement(Description, null,
            React.createElement(DescriptionText, null, description)),
        isNaN(percentageComplete) ? null : (React.createElement(React.Fragment, null,
            React.createElement(Completion, { percentage: percentageComplete },
                percentageComplete,
                "% complete"),
            React.createElement(CompletionProgressBar, { percentage: percentageComplete })))));
}
