import { theme } from "../../theme";
const spiceMod = 3173;
const spiceRange = 231;
const spiceToOffset = (spice) => {
    const offset = (Math.floor(spice
        .split("")
        .map(char => char.charCodeAt(0))
        .reduce((prod, code) => code * prod, 1) % spiceMod) %
        spiceRange) -
        spiceRange / 2;
    return offset;
};
export const xPosition = (index, rowAmount, left, width, spice) => left + (width / (rowAmount + 1)) * (index + 1) + spiceToOffset(spice);
export const yPosition = (rowIndex, top) => (top + (rowIndex + 1) * theme.rowHeight) - 60;
export function buildTrees(moduleList) {
    const childrenLookUp = {};
    const nodeLookUp = {};
    const rootLevel = [];
    moduleList.forEach(module => {
        if (!(module.id in childrenLookUp)) {
            childrenLookUp[module.id] = [];
        }
        const node = {
            id: module.id,
            name: module.name,
            children: childrenLookUp[module.id],
            availability: module.availability,
            depth: 0,
        };
        if (!(module.id in nodeLookUp)) {
            nodeLookUp[module.id] = node;
        }
        if (module.prerequisites.length === 0) {
            rootLevel.push(node);
        }
        module.prerequisites.forEach(prereq => {
            if (prereq in childrenLookUp) {
                childrenLookUp[prereq].push(node);
            }
            else {
                childrenLookUp[prereq] = [node];
            }
        });
    });
    const addDepths = (node, depth = 0) => {
        node.depth = Math.max(depth, node.depth);
        node.children.forEach(child => addDepths(child, depth + 1));
    };
    rootLevel.forEach(node => addDepths(node));
    const layers = [];
    const buildLayers = (node, treeIndex) => {
        if (!(treeIndex in layers))
            layers[treeIndex] = [];
        if (!(node.depth in layers[treeIndex]))
            layers[treeIndex][node.depth] = [];
        if (!layers[treeIndex][node.depth].includes(node))
            layers[treeIndex][node.depth].push(node);
        node.children.forEach(child => buildLayers(child, treeIndex));
    };
    rootLevel.forEach(buildLayers);
    return layers;
}
