import React, { useEffect, useContext, useMemo, useState, useCallback } from "react";
import { ClearButton, DetailSearchInput, SearchArea, SearchResultItem, SearchResultName, SearchResultMatchInfo, SearchResultItemBorder, SearchResultsContainer, } from "./styled";
import Cancel from "../Logos/Cancel";
import SearchIcon from "../Logos/Search";
import { ContentContext, ContentTypes } from "../../context/contentContext";
import { useParams } from "react-router-dom";
import { contentPath, truthy } from "../../utils/constants";
;
const SearchResultMatch = ({ content, searchTerm }) => {
    const { before, after } = useMemo(() => {
        const key = content.shortDescription.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ? "shortDescription" :
            content.description.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ? "description" :
                content.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ? "name" : null;
        if (!key)
            return { before: null, after: null };
        const maxLen = () => Math.floor(45 - searchTerm.length) / 2;
        const matchIndex = content[key].toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase());
        const before = matchIndex > maxLen() ? "..." + content[key].slice(matchIndex - maxLen() - 3, matchIndex) : content[key].slice(0, matchIndex);
        const after = content[key].length - (matchIndex + searchTerm.length) > maxLen() ? content[key].slice(matchIndex + searchTerm.length, matchIndex + searchTerm.length + maxLen() - 3) + "..." : content[key].slice(matchIndex + searchTerm.length);
        return { before, after };
    }, [content, searchTerm]);
    return React.createElement(SearchResultMatchInfo, null,
        before,
        React.createElement("span", null, searchTerm),
        after);
};
const SearchResult = ({ isFirstItem, searchTerm, content }) => {
    const [hovered, setHovered] = useState(false);
    const { moduleId, unitId } = useParams();
    const onMouseEnter = useCallback(() => setHovered(true), []);
    const onMouseLeave = useCallback(() => setHovered(false), []);
    return (React.createElement(SearchResultItemBorder, { isFirstItem: isFirstItem },
        React.createElement(SearchResultItem, { to: contentPath(...[moduleId, unitId, content.id].filter(truthy)), onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
            React.createElement(SearchResultName, { hovered: hovered }, content.name),
            React.createElement(SearchResultMatch, { content: content, searchTerm: searchTerm }))));
};
export default function ContentSearchBar({ searchContentType, searchWithinModuleId, }) {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchExpanded, setSearchExpanded] = useState(false);
    const [matchedContent, setMatchedContent] = useState([]);
    const { queryLessons, queryModules, queryUnits } = useContext(ContentContext);
    const search = useMemo(() => searchContentType === ContentTypes.module ? queryModules : searchContentType === ContentTypes.unit ? queryUnits : searchContentType === ContentTypes.lesson ? queryLessons : null, [searchContentType, searchWithinModuleId, queryLessons, queryModules, queryUnits]);
    const clearButton = useMemo(() => !!searchTerm.length ? (React.createElement(ClearButton, { label: `clear search`, onClick: () => setSearchTerm("") },
        React.createElement(Cancel, null))) : null, [searchTerm]);
    const changeSearchTerm = useCallback((event) => setSearchTerm(event.target.value), []);
    useEffect(() => {
        var _a;
        setSearchExpanded(!!searchTerm);
        setMatchedContent((_a = search === null || search === void 0 ? void 0 : search(searchTerm)) !== null && _a !== void 0 ? _a : []);
    }, [searchTerm]);
    return (React.createElement(SearchArea, { expanded: searchExpanded },
        React.createElement(DetailSearchInput, { fullWidth: true, label: "search", placeholder: "Search", onChange: changeSearchTerm, value: searchTerm, startAdornment: React.createElement(SearchIcon, null), endAdornment: clearButton }),
        React.createElement(SearchResultsContainer, { hasResults: matchedContent.length > 0 }, matchedContent.map((content, index) => (React.createElement(SearchResult, { key: content.id + index, isFirstItem: !index, content: content, searchTerm: searchTerm }))))));
}
