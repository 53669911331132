import styled from "styled-components";
export const SelectContainer = styled.div `
  position relative;
  width: fit-content;
  max-width: 100%;
`;
export const StyledSelect = styled.select `
  max-width: 100%;
  height: 2.6rem;
  background-color: ${p => p.theme.primaryColor};
  color: ${p => p.theme.fontColor};
  border: none;
  outline: none;
  font-size: ${p => p.theme.fontSizes.medium};
  caret-color: ${p => p.theme.fontColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.1s ease-in-out;
`;
export const Option = styled.option ``;
