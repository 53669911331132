import React, { useMemo } from "react";
import { NodeStatuses } from "./types";
import { StyledLockLogo, Indicator } from "./styled";
export default function StatusIndicator({ status, labelPosition, }) {
    const title = useMemo(() => {
        switch (status) {
            case NodeStatuses.alerted:
                return "Alert";
            case NodeStatuses.seen:
                return "Started";
            case NodeStatuses.unexplored:
                return "Not started";
            case NodeStatuses.locked:
                return "Unpurchased";
            default:
                return null;
        }
    }, [status]);
    return (React.createElement("div", { title: title, style: {
            width: status !== NodeStatuses.seen ? "20px" : 0,
            height: status !== NodeStatuses.seen ? "20px" : 0,
            borderRadius: "50%",
            position: "absolute",
            right: ["NW" /* Positions.NW */, "W" /* Positions.W */, "SW" /* Positions.SW */].includes(labelPosition)
                ? "calc(100% - 22px)"
                : "-25px",
            top: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "transparent",
        } }, status === NodeStatuses.locked ? (React.createElement(StyledLockLogo, null)) : (React.createElement(Indicator, { status: status }))));
}
