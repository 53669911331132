import React, { useContext } from "react";
import { SnackbarContext } from "../../context/snackbarContext";
import { CopyableContainer, ClipboardLogo } from "./styled";
export default function Copyable({ children, contents }) {
    const { pushToast } = useContext(SnackbarContext);
    const copy = () => {
        navigator.clipboard.writeText(contents.replace(/\&lt;/g, "<").replace(/\&gt;/g, ">"));
        pushToast({
            message: "copied to clipboard",
            level: "success",
        });
    };
    return (React.createElement(CopyableContainer, null,
        React.createElement(ClipboardLogo, { copy: copy }),
        children));
}
