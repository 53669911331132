import React from "react";
import { ThemeProvider } from "styled-components";
import Lesson from "./views/Lesson";
import ModuleTree from "./views/ModuleTree";
import ModuleDetail from "./views/ModuleDetail";
import UnitDetail from "./views/UnitDetail";
import About from "./views/About";
import FAQ from "./views/FAQ";
import Contact from "./views/Contact";
import Menu from "./components/Menu";
import Kanban from "./views/Kanban";
import KanbanList from "./views/KanbanList";
import FallbackErrorView from "./views/FallbackErrorView";
import NotFoundView from "./views/NotFoundView";
import { createBrowserRouter, RouterProvider, Outlet, } from "react-router-dom";
import { GoogleIdentityProvider } from "./context/googleIdentityContext";
import { SnackbarProvider } from "./context/snackbarContext";
import { PaywallModalProvider } from "./context/paywallContext";
import { ContentProvider } from "./context/contentContext";
import { UserContextProvider } from "./context/userContext";
import { BoardsProvider } from "./context/boardsContext";
import { theme } from "./theme";
import { contentPath, routerPaths } from "./utils/constants";
import useScrollToTopOnNav from "./hooks/useScrollToTopOnNav";
import { WindowSizeProvider } from "./context/windowSizeContext";
import UserPolicy from "./views/UserPolicy/UserPolicy";
import Redirect from "./components/Redirect";
const Providers = () => {
    useScrollToTopOnNav();
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement(SnackbarProvider, null,
            React.createElement(GoogleIdentityProvider, null,
                React.createElement(WindowSizeProvider, null,
                    React.createElement(UserContextProvider, null,
                        React.createElement(PaywallModalProvider, null,
                            React.createElement(ContentProvider, null,
                                React.createElement(BoardsProvider, null,
                                    React.createElement(Outlet, null))))))))));
};
const router = createBrowserRouter([
    {
        element: React.createElement(Providers, null),
        children: [
            {
                path: "/",
                element: React.createElement(Menu, null),
                errorElement: React.createElement(FallbackErrorView, null),
                children: [
                    { path: routerPaths.root, element: React.createElement(Redirect, { to: contentPath() }) },
                    { path: routerPaths.modules, element: React.createElement(ModuleTree, null) },
                    { path: routerPaths.moduleDetail, element: React.createElement(ModuleDetail, null) },
                    { path: routerPaths.unitDetail, element: React.createElement(UnitDetail, null) },
                    { path: routerPaths.lesson, element: React.createElement(Lesson, null) },
                    { path: routerPaths.boards, element: React.createElement(KanbanList, null) },
                    { path: routerPaths.singleBoard, element: React.createElement(Kanban, null) },
                    { path: routerPaths.about, element: React.createElement(About, null) },
                    { path: routerPaths.faq, element: React.createElement(FAQ, null) },
                    { path: routerPaths.contact, element: React.createElement(Contact, null) },
                    { path: routerPaths.policy, element: React.createElement(UserPolicy, null) },
                ],
            },
            { path: "*", element: React.createElement(NotFoundView, null) },
        ],
    },
]);
const Router = () => React.createElement(RouterProvider, { router: router });
export default Router;
