import styled from "styled-components";
import StyledLink from "../../components/StyledLink";
export const StyledPolicyLink = styled(StyledLink) `
  &:hover {
    color: ${p => p.theme.linkColor};
  }
`;
export const Email = styled.span `
  position: relative;
  color: ${p => p.theme.linkColor};
  margin: 0 1rem 0 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
export const PolicyContainer = styled.div `
  display: flex;
  flex-direction: column;
  margin: 1rem 12.5vw;

  *:not(a, span) {
    color: ${p => p.theme.fontColor};
    font-family: ${p => p.theme.fonts.sanSerif};
  }

  a {
    color: ${p => p.theme.linkColor};
    font-family: ${p => p.theme.fonts.sanSerif};
  }
`;
export const PolicyRow = styled.div `
  display: flex;
  gap: 0.75rem;
  align-items: flex-end;
  p {
    font-size: ${p => p.theme.fontSizes.tiny};
    margin-bottom: 1.5rem;
  }
`;
