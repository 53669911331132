import React, { useCallback, useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { adjustedCode, adjustStackTraceLine, composeSyntaxErrorStackTrace, composeTimeoutErrorStackTrace } from "./helpers";
import { ClearOutputButton, CodeButtons, Crossbar, Drawer, EditorSection, ErrorOutput, ErrorText, OutputArea, OutputControls, OutputMain, StdoutText, Wrapper, } from "./styled";
import neonVommitTheme from "./codeTheme";
import Button from "../Button";
import useClickAwayListener from "../../hooks/useClickAwayListener";
import useDelayedState from "../../hooks/useDelayedState";
import Cancel from "../Logos/Cancel";
const LOCAL_STORAGE_KEY = "dev-yourself-code-editor";
export default function CodeEditorDrawer({ editorOpen: parentEditorOpen, closeEditor }) {
    const [code, setCode] = useState("");
    const [editorRendered, setEditorRendered] = useDelayedState(false);
    const [editorOpen, setEditorOpen] = useDelayedState(parentEditorOpen);
    const [activeWorker, setActiveWorker] = useState();
    const [workerRunning, setWorkerRunning] = useState(false);
    const [workerTimeout, setWorkerTimeout] = useState();
    const [messages, setMessages] = useState([]);
    const { setRef: drawerRef } = useClickAwayListener({ onClickAway: closeEditor });
    const stopExecution = useCallback(() => {
        if (activeWorker) {
            activeWorker.terminate();
            setWorkerRunning(false);
        }
    }, [activeWorker]);
    const appendMessage = useCallback((message) => {
        setMessages((prevMessages) => prevMessages.concat(message).slice(-1000));
    }, []);
    useEffect(() => {
        if (parentEditorOpen) {
            setEditorOpen(true, 50);
            setEditorRendered(true, 0);
        }
        else {
            setEditorOpen(false, 0);
            setEditorRendered(false, 200);
        }
    }, [parentEditorOpen]);
    const executeCode = useCallback(() => {
        const blob = new Blob([adjustedCode(code)], { type: "application/javascript" });
        const workerScriptUrl = URL.createObjectURL(blob);
        const worker = new Worker(workerScriptUrl);
        setActiveWorker(worker);
        worker.onerror = ((e) => {
            setWorkerRunning(false);
            appendMessage({ data: composeSyntaxErrorStackTrace(e), type: "error" });
        });
        worker.postMessage(undefined);
        setWorkerRunning(true);
        setWorkerTimeout(setTimeout(() => {
            setWorkerRunning(false);
            appendMessage({ data: composeTimeoutErrorStackTrace(), type: "error" });
        }, 15000));
        worker.onmessage = (e) => {
            if (e.data === "terminate") {
                setWorkerRunning(false);
            }
            else {
                const message = e.data.type === "info" ? e.data : { type: e.data.type, data: e.data.data.map(adjustStackTraceLine) };
                appendMessage(message);
            }
        };
    }, [code, workerRunning, workerTimeout, activeWorker, messages]);
    useEffect(() => {
        if (!workerRunning && activeWorker) {
            activeWorker.terminate();
            clearTimeout(workerTimeout);
            setActiveWorker(undefined);
            setWorkerTimeout(undefined);
        }
    }, [workerRunning, workerTimeout, activeWorker]);
    const updateCode = useCallback((newCode) => {
        localStorage.setItem(LOCAL_STORAGE_KEY, newCode);
        setCode(newCode);
    }, []);
    const clearStdout = useCallback(() => setMessages([]), []);
    useEffect(() => {
        const storedCode = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (storedCode) {
            setCode(storedCode);
        }
    }, []);
    return editorRendered ? (React.createElement(Drawer, { ref: drawerRef, open: editorOpen },
        React.createElement(Wrapper, null,
            React.createElement(EditorSection, null,
                React.createElement(CodeButtons, null,
                    React.createElement(Button, { style: { borderRadius: "50%", padding: "0 5px" }, label: "close editor", onClick: closeEditor },
                        React.createElement(Cancel, null)),
                    workerRunning ? React.createElement(Button, { label: "stop", color: "red", onClick: stopExecution }, "Stop") : React.createElement(Button, { label: "run code", onClick: executeCode }, "Run")),
                React.createElement(CodeMirror, { value: code, extensions: [
                        neonVommitTheme,
                        javascript({ jsx: true })
                    ], onChange: updateCode, indentWithTab: true })),
            React.createElement(OutputArea, null,
                React.createElement(OutputControls, null,
                    React.createElement(StdoutText, null, "Output"),
                    React.createElement(ClearOutputButton, { label: "clear output", onClick: clearStdout },
                        React.createElement(Crossbar, null))),
                React.createElement(OutputMain, null, messages.map((message, i) => (message.type === "info" ?
                    React.createElement(StdoutText, { key: `stdout-${i}` }, message.data.map(data => typeof data === "object" ? JSON.stringify(data) : data)) :
                    React.createElement(ErrorOutput, { key: `error-${i}` }, message.data.map((stackTraceLine, j) => React.createElement(ErrorText, { style: { marginLeft: j > 0 ? "2rem" : 0 }, key: `error-${i}-${j}` }, stackTraceLine)))))))))) : null;
}
;
