var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
import { logTrackingFail } from "../../../utils/constants";
export default function DynamicLogo(_a) {
    var { svgName } = _a, rest = __rest(_a, ["svgName"]);
    return (React.createElement("img", Object.assign({}, rest, { src: `/assets/${svgName}.svg`, onLoad: (event) => event.target.style.display = "block", onError: event => {
            var _a;
            event.target.style.display = "none";
            try {
                (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.track(`Failed to load dynamic logo '${svgName}'`);
            }
            catch (ampErr) {
                logTrackingFail();
            }
        } })));
}
