import { useCallback, useEffect, useState } from "react";
const events = {
    once(element, type, callback) {
        const typeArray = type ? type.split(" ") : [];
        const recursiveFunction = (event) => {
            var _a;
            (_a = event.target) === null || _a === void 0 ? void 0 : _a.removeEventListener(event.type, recursiveFunction);
            return callback(event);
        };
        for (let i = typeArray.length - 1; i >= 0; i--) {
            this.on(element, typeArray[i], recursiveFunction);
        }
    },
    on(element, type, callback) {
        var _a;
        (_a = element.addEventListener) === null || _a === void 0 ? void 0 : _a.call(element, type, callback);
    },
    off(element, type, callback) {
        var _a;
        (_a = element.removeEventListener) === null || _a === void 0 ? void 0 : _a.call(element, type, callback);
    },
    isKeyboard(event) {
        return ["keydown", "keypress", "keyup"].indexOf(event.type) !== -1;
    },
};
const clickAwayEvents = ["mousedown", "touchstart"];
const isWithin = (element, target) => {
    if (target !== null) {
        return (element === target || isWithin(element, target.parentElement));
    }
    return false;
};
const bind = (callback) => clickAwayEvents.forEach((event) => events.on(document, event, callback));
const unbind = (callback) => clickAwayEvents.forEach((event) => events.off(document, event, callback));
const useClickAwayListener = ({ onClickAway }) => {
    const [selfRef, setRef] = useState(null);
    const handleClickAway = useCallback((event) => {
        var _a;
        if (event.defaultPrevented)
            return;
        if (((_a = document.documentElement) === null || _a === void 0 ? void 0 : _a.contains(event.target)) &&
            selfRef &&
            !isWithin(selfRef, event.target)) {
            onClickAway(event);
        }
    }, [onClickAway, selfRef]);
    useEffect(() => {
        bind(handleClickAway);
        return () => unbind(handleClickAway);
    }, [onClickAway, handleClickAway, selfRef]);
    return {
        setRef
    };
};
export default useClickAwayListener;
