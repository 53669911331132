export const API_EXPLICIT_DENY = "Forbidden, User is not authorized to access this resource with an explicit deny.";
export const API_BAD_TOKEN = "Bad token";
export const API_BAD_NONCE = "Bad nonce";
export const API_NO_TOKEN = "No token";
export const API_EXPIRED_TOKEN = "Expired token";
export const API_UNPURCHASED_MODULE = "Module has not been purchased";
export const CONTACT_EMAIL = "abel.devyourself@gmail.com";
export const FREE_MODULE_IDS = ["getting-started", "js-basics"];
export const OFFSCREEN_COORDINATE = 1000000;
export const MAX_MOBILE_SCREEN_SIZE = 768;
export const contentPath = (module, unit, lesson) => {
    let path = "/learn";
    if (module)
        path += `/${module}`;
    if (unit)
        path += `/${unit}`;
    if (lesson)
        path += `/${lesson}`;
    return path;
};
export const boardPath = (boardId) => {
    let path = "/boards";
    if (boardId)
        path += `/${boardId}`;
    return path;
};
export const routerPaths = {
    root: "/",
    modules: contentPath(),
    moduleDetail: contentPath(":moduleId"),
    unitDetail: contentPath(":moduleId", ":unitId"),
    lesson: contentPath(":moduleId", ":unitId", ":lessonId"),
    boards: boardPath(),
    singleBoard: boardPath(":boardId"),
    about: "about",
    faq: "faq",
    contact: "contact",
    policy: "policy",
};
const acronyms = ["faq"];
export const capitalize = (word) => acronyms.includes(word) ? word.toLocaleUpperCase() : word[0].toLocaleUpperCase() + word.slice(1);
export const truthy = (x) => !!x;
export const camelCase = (str) => str.replace(/\s[a-z]/g, match => match.slice(1).toLocaleUpperCase());
export const hexWithOpacity = (hex, opacity) => `rgba(${parseInt(hex.slice(1, 3), 16)}, ${parseInt(hex.slice(3, 5), 16)}, ${parseInt(hex.slice(5, 7), 16)}, ${opacity})`;
export const logErrorReportFail = () => console.error("Seems like bugsnag was blocked by your browser. Oh well, no error reporting for you. You'll just have to contact me if something breaks.");
export const logTrackingFail = () => console.error("Seems like Amplitude was blocked by your browser. Oh well, no reporting for you. You'll just have to contact me if you have suggestions.");
