import React, { useCallback, useContext, useMemo } from "react";
import { useParams, useLocation, matchPath } from "react-router-dom";
import { BreadCrumbAreaContainer, BreadCrumbList, LinkDelimiter, BreadCrumbLabel, PageTitle, } from "./styled";
import { routerPaths, truthy, capitalize } from "../../utils/constants";
import StyledLink from "../StyledLink";
import { ContentContext } from "../../context/contentContext";
export default function BreadCrumbs({ children }) {
    const { modules, units, lessons } = useContext(ContentContext);
    const { moduleId, unitId, lessonId } = useParams();
    const location = useLocation();
    const crumbLabels = useMemo(() => {
        return location.pathname
            .split("/")
            .filter(truthy)
            .map(decodeURIComponent)
            .map((possibleId) => {
            if (possibleId in modules)
                return modules[possibleId].name;
            if (possibleId in units)
                return units[possibleId].name;
            if (possibleId in lessons)
                return lessons[possibleId].name;
            return possibleId;
        });
    }, [moduleId, unitId, lessonId, modules, units, lessons]);
    const crumbIds = useMemo(() => {
        return location.pathname
            .split("/")
            .filter(truthy)
            .map(decodeURIComponent)
            .map((possibleId) => {
            if (possibleId in modules)
                return possibleId;
            if (possibleId in units)
                return possibleId;
            if (possibleId in lessons)
                return possibleId;
            return possibleId;
        });
    }, [moduleId, unitId, lessonId, modules, units, lessons]);
    const crumbLink = useCallback((index) => {
        return "/" + crumbIds.slice(0, index + 1).join("/");
    }, [crumbLabels]);
    const linkablePath = useCallback((index) => {
        return (index < crumbLabels.length - 1 &&
            Object.values(routerPaths).some(path => {
                return !!matchPath(path, crumbLink(index));
            }));
    }, [crumbLink]);
    return (React.createElement(BreadCrumbAreaContainer, null,
        React.createElement(BreadCrumbList, null, crumbLabels.map((crumbLabel, index) => (React.createElement(React.Fragment, { key: index },
            linkablePath(index) ? (React.createElement(StyledLink, { to: crumbLink(index) },
                React.createElement(BreadCrumbLabel, { "data-cy": `breadCrumb-${crumbLabel}` }, capitalize(crumbLabel)))) : (React.createElement(PageTitle, null, capitalize(crumbLabel.replace(/^\d+\s/, "")))),
            index < crumbLabels.length - 1 ? (React.createElement(LinkDelimiter, null, !linkablePath(index) && !linkablePath(index + 1) ? "-" : "/")) : null)))),
        React.createElement("div", null, children)));
}
