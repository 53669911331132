import React from "react";
import { WindowSizeWarningContainer, DisclaimerHeader, Explanation, DisclaimerSubHeader, mobilePhoneStyle, } from "./styled";
import MobilePhone from "../Logos/MobilePhone";
import { theme } from "../../theme";
import { MenuDialog, CircularMenuButton, Backdrop } from "./styled";
import Cancel from "../Logos/Cancel";
import useClickAwayListener from "../../hooks/useClickAwayListener";
export default function WindowSizeWarningModal({ open, onClose, }) {
    const { setRef } = useClickAwayListener({ onClickAway: onClose });
    return open ? (React.createElement(Backdrop, null,
        React.createElement(MenuDialog, { open: open, ref: setRef },
            React.createElement(CircularMenuButton, { label: "close window size warning", onClick: onClose },
                React.createElement(Cancel, { style: { width: "65%", height: "65%" } })),
            React.createElement(WindowSizeWarningContainer, null,
                React.createElement(MobilePhone, { style: mobilePhoneStyle, color: theme.secondaryColor }),
                React.createElement(DisclaimerHeader, null, "WAIT!"),
                React.createElement(DisclaimerSubHeader, null, "The best experience of this site is on larger screens."),
                React.createElement(Explanation, null,
                    "Learning to code requires writing code. And properly writing code requires an old fashioned keyboard and IDE. So get off your phone and get coding",
                    " ",
                    String.fromCodePoint(0x1f600)))))) : null;
}
