import React, { useState, useLayoutEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ProgressBarContainer, ProgressBarMeter, ProgressCheckpoint, ProgressCheckpointCenter, } from "./styled";
export default function ProgressBar({ checkpointIds, longitude = "95%", contentLoaded, }) {
    const { hash } = useLocation();
    const [scrollPercent, setScrollPercent] = useState(-0.01);
    const updateHash = useCallback((checkpointTitle) => (window.location.hash = `#${checkpointTitle}`), []);
    const getCheckpointElements = () => {
        const selectors = ["h2", "h3", ".action", ...checkpointIds.map(id => `#${id}`)].join();
        const checkpointElements = document.querySelectorAll(selectors);
        return [...checkpointElements].sort((e1, e2) => e1.getBoundingClientRect().y - e2.getBoundingClientRect().y);
    };
    const checkPointTitle = (e) => ((e === null || e === void 0 ? void 0 : e.id) ? e.id : e.innerText.replace(/\-/g, " "));
    const yPercent = (yValue) => {
        const checkpoints = getCheckpointElements();
        checkpoints.sort((e1, e2) => e1.getBoundingClientRect().y - e2.getBoundingClientRect().y);
        let nextCheckpointIndex = 0;
        let scrollPercent = 0;
        while (nextCheckpointIndex < checkpoints.length) {
            if (checkpoints[nextCheckpointIndex].getBoundingClientRect().y >= 0)
                break;
            nextCheckpointIndex++;
        }
        if (!nextCheckpointIndex)
            return 0;
        if (nextCheckpointIndex === checkpoints.length)
            return 100;
        scrollPercent += ((nextCheckpointIndex - 1) / (checkpoints.length - 1));
        scrollPercent += (1 - (checkpoints[nextCheckpointIndex].getBoundingClientRect().y / (checkpoints[nextCheckpointIndex].getBoundingClientRect().y - checkpoints[nextCheckpointIndex - 1].getBoundingClientRect().y))) / (checkpoints.length - 1);
        scrollPercent *= 100;
        return scrollPercent;
    };
    const scrollTo = (title) => {
        const checkpoint = getCheckpointElements().find(element => checkPointTitle(element) === title);
        if (checkpoint) {
            checkpoint.scrollIntoView();
        }
    };
    const onScroll = () => {
        requestAnimationFrame(() => {
            setScrollPercent(yPercent(window.scrollY));
        });
    };
    useLayoutEffect(() => {
        if (getCheckpointElements().length)
            document.addEventListener("scroll", onScroll);
        return () => document.removeEventListener("scroll", onScroll);
    }, [checkpointIds]);
    useLayoutEffect(() => {
        if (contentLoaded && hash) {
            scrollTo(decodeURIComponent(hash.slice(1)));
        }
        else {
            setScrollPercent(0);
        }
    }, [hash, contentLoaded]);
    return (React.createElement(ProgressBarContainer, { longitude: longitude },
        React.createElement(ProgressBarMeter, { scrollPercent: scrollPercent }),
        getCheckpointElements().map((element, index, elements) => {
            const percent = (index / (elements.length - 1)) * 100;
            const title = checkPointTitle(element);
            return (React.createElement(ProgressCheckpoint, { "data-cy": `progress checkpoint-${title}`, highlight: scrollPercent >= percent - 0.1, style: { top: `${percent}%` }, key: index, onClick: () => updateHash(title), title: title },
                React.createElement(ProgressCheckpointCenter, null)));
        })));
}
