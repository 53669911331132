import styled from "styled-components";
import { MAX_MOBILE_SCREEN_SIZE } from "../../utils/constants";
export const UnitListContainer = styled.div `
  position: relative;
  overflow: scroll;
  flex: 1;
`;
export const NodeContainer = styled.div `
  width: 100%;
  height: ${p => p.theme.rowHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Line = styled.div `
  position: absolute;
  top: ${p => p.theme.rowHeight / 2}px;
  left: calc(50% - ${p => p.theme.edgeWidth / 2}px);
  background-color: ${p => p.darkened ? p.theme.colors.grey : p.theme.secondaryColor};
  width: ${p => p.theme.edgeWidth}px;
  height: ${p => (p.numUnits - 1) * p.theme.rowHeight}px;
`;
export const ModuleDetailContainer = styled.div `
  display: flex;
  height: calc((100vh - 10rem) - 7rem);
  
  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    height: 100%;
    flex-direction: column;
  }
`;
export const MobileTitle = styled.div `
  box-sizing: border-box;
  text-align: left;
  color: ${p => p.theme.fontColor};
  font-size: ${p => p.theme.fontSizes.huge};
  line-height: 3rem;
  margin: 0 0 1rem 0;
  font-weight: 500;
  font-family: ${p => p.theme.fonts.sanSerif};
  text-align: center;
`;
