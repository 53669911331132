var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, useState } from "react";
import { InputContainer, InputRow, InputAdornment, InputAndUnderline, StyledInput, Underline, } from "./styled";
import { logTrackingFail } from "../../utils/constants";
export default function Input(_a) {
    var { onFocus, onBlur, onChange, value, label, startAdornment, endAdornment, fullWidth, className, underlineWidth, style } = _a, rest = __rest(_a, ["onFocus", "onBlur", "onChange", "value", "label", "startAdornment", "endAdornment", "fullWidth", "className", "underlineWidth", "style"]);
    const [focused, setFocused] = useState(false);
    const _onFocus = useCallback((event) => {
        setFocused(true);
        onFocus === null || onFocus === void 0 ? void 0 : onFocus(event);
    }, [onFocus]);
    const _onBlur = useCallback((event) => {
        var _a;
        setFocused(false);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(event);
        try {
            (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.track(`"${value}" as typed into ${label} input, before onBlur was triggered`);
        }
        catch (ampErr) {
            logTrackingFail();
        }
    }, [onBlur]);
    const _onChange = useCallback((event) => {
        onChange === null || onChange === void 0 ? void 0 : onChange(event);
    }, [onChange]);
    return (React.createElement(InputContainer, { fullWidth: fullWidth, className: `input-container ${className}`, style: style },
        React.createElement(InputRow, { hasStartAdornment: !!startAdornment },
            !!startAdornment && React.createElement(InputAdornment, null, startAdornment),
            React.createElement(InputAndUnderline, { hasStartAdornment: !!startAdornment },
                React.createElement(StyledInput, Object.assign({ autoComplete: "off", name: label, value: value !== null && value !== void 0 ? value : "", onFocus: _onFocus, onBlur: _onBlur, onChange: _onChange, hasStartAdornment: !!startAdornment, hasEndAdornment: !!endAdornment }, rest)),
                React.createElement(Underline, { focused: focused, hasStartAdornment: !!startAdornment, hasEndAdornment: !!endAdornment, overrideUnderlineWidth: underlineWidth })),
            !!endAdornment && React.createElement(InputAdornment, { isEndAdornment: true }, endAdornment))));
}
