import styled from "styled-components";
import { getContrastingHex } from "color-contrast-picker";
export const toastColor = (p) => p.level === "error"
    ? p.theme.colors["red"]
    : p.level === "info"
        ? p.theme.colors["blueIntense"]
        : p.theme.colors["greenDark"];
const toastTextColor = (p) => getContrastingHex(toastColor(p), 4.5);
export const ToastContainer = styled.div `
  z-index: 10000;
  position: fixed;
  padding: 0.75rem 1rem 0.75rem 1rem;
  left: 50%;
  top: 2rem;
  transform: translateX(-50%) scale(${p => (p.visible ? 1 : 0.75)});
  opacity: ${p => (p.visible ? 1 : 0)};
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
  font-weight: bold;
  white-space: nowrap;
  background-color: ${toastColor};
  color: ${toastTextColor};
  transition: all ${p => p.dismissDuration}ms ease-in;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.375rem;
    height: 1.375rem;
    margin: 0 0 0 0.75rem;
  }
`;
