import styled from "styled-components";
import { MAX_MOBILE_SCREEN_SIZE } from "../../utils/constants";
export const ExpandableSelection = styled.div `
  display: flex;
  gap: .25rem;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5000;
  transition: width 0.1s ease-in;
  width: ${p => p.open ? "200px" : "0px"};
  height: ${p => p.open ? "auto" : "0px"};
  padding: ${p => p.open ? ".25rem" : "0px"};
  overflow: ${p => p.open ? "unset" : "hidden"};
  overflow-y: auto;
  max-height: 50vh;
  border-radius: .5rem;
  background-color: ${p => p.theme.colors.black};
  box-shadow: ${p => p.open ? `1px 1px 5px ${p.theme.colors.blackDark}` : "none"};
`;
export const SelectionButton = styled.button `
  border-radius: .5rem;
  color: ${p => p.theme.colors.white};
  font-size: ${p => p.theme.fontSizes.tiny};
  margin: 0;
  text-align: left;
  border: none;
  background-color: ${p => p.theme.colors.black};
  transition: all 0.1s ease-in;
  
  :hover {
    color: ${p => p.theme.colors.black};
    background-color: ${p => p.theme.linkColor};
    transform: translateY(-2px);
  }

  &::-webkit-scrollbar {
    width: 0px;
  }
`;
export const TitleBreadCrumbsArea = styled.div `
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;

  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    display: none;
    opacity: 0;
  }
`;
export const TitleBreadCrumbList = styled.div `
  display: flex;
  align-items: center;
`;
export const TitleBreadCrumbLabel = styled.div `
  margin: 0;
  font-size: ${p => p.theme.fontSizes.tiny};
  color: ${p => p.currentStep ? p.theme.fontColor : p.theme.linkColor};
  white-space: nowrap;
  cursor: ${p => p.currentStep ? "auto" : "pointer"};

  &:hover {
    text-decoration: ${p => p.currentStep ? "none" : "underline solid"};
    color: ${p => p.theme.fontColor};
  }
`;
export const TitleBreadCrumbDelimiter = styled.div `
  padding: 0;
  margin: 0 0.25rem 0 0.25rem;
  font-size: ${p => p.theme.fontSizes.tiny};
  color: ${p => p.theme.fontColor};
`;
export const MoreStepsMenu = styled.div `
  margin: 0 .25rem;
  font-size: ${p => p.theme.fontSizes.tiny};
  color: ${p => p.theme.linkColor};
  white-space: nowrap;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${p => p.theme.primaryColor};

  &:hover {
    text-decoration: underline solid;
    color: ${p => p.theme.fontColor};
  }
`;
