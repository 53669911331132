import styled from "styled-components";
import Button from "../Button/Button";
export const App = styled.div `
  display: flex;
  flex-direction: column;
  width: 100vw;
  padding: 0;
  overflow: auto;
`;
export const NavBar = styled.div `
  height: 5.5rem;
  display: flex;
  justify-content: space-between;
  background-color: ${p => p.theme.accentColor};
  align-items: center;
  z-index: 2;
  padding: 1rem 3rem;
  box-shadow: 0px 1px 2px ${p => p.theme.colors.blackDark};
`;
export const NavArea = styled.div `
  height: 4rem;
  display: flex;
  justify-content: space-between;
  background-color: ${p => p.theme.accentColor};
  align-items: center;
  z-index: 2;
  padding: 0;
  gap: 0.75rem;
  margin-right: 0.75rem;
`;
export const AppBody = styled.div `
  display: flex;
  flex-grow: 1;
  margin: 0;
  background-color: ${p => p.theme.primaryColor};
`;
export const CircularMenuButton = styled(Button) `
  position: fixed;
  top: 1rem;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4rem;
  width: 4rem;
  height: 4rem;
  padding: 0;
  z-index: 2001;

  > * {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const MobileNavArea = styled.div `
  position: sticky;
  width: 100vw;
  top: 0;
  z-index: 2006;
`;
export const MenuDialog = styled.div `
  background-color: ${p => p.theme.primaryColor};
  z-index: 2000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${p => (p.visible ? "1" : "0")};
  display: ${p => (p.visible ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-end;
`;
