import React, { useContext, useMemo, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { contentPath } from "../../utils/constants";
import PageWrapper from "../../components/PageWrapper";
import Node from "../../components/Node";
import Loader from "../../components/Loader";
import { ContentContext, ContentTypes } from "../../context/contentContext";
import { GoogleIdentityContext } from "../../context/googleIdentityContext";
import { UnitListContainer, NodeContainer, Line, ModuleDetailContainer, } from "./styled";
import CheckoutCTA from "../../components/CheckoutCTA";
import { UserContext } from "../../context/userContext";
import { WindowSizeContext } from "../../context/windowSizeContext";
import ContentDetailCard from "../../components/ContentDetailCard";
import { Availabilities } from "../../types";
export default function ModuleDetail() {
    var _a, _b, _c;
    const { isMobile } = useContext(WindowSizeContext);
    const { loggedIn } = useContext(GoogleIdentityContext);
    const { hasPurchasedModule } = useContext(UserContext);
    const { modules, units, loadingModules, loadingUnits } = useContext(ContentContext);
    const { moduleId } = useParams();
    const [focusedUnitId, setFocusedUnitId] = useState();
    const onFocusUnit = useCallback((unitId) => () => setFocusedUnitId(unitId), []);
    const onBlurUnit = useCallback(() => setFocusedUnitId(null), []);
    const doneLoading = useMemo(() => {
        return (!loadingModules &&
            moduleId in modules &&
            !loadingUnits &&
            Object.values(units).some(unit => unit.moduleId === moduleId));
    }, [loadingModules, moduleId, modules, loadingUnits, units]);
    return (React.createElement(React.Fragment, null,
        doneLoading ? React.createElement(React.Fragment, null) : React.createElement(Loader, null),
        React.createElement(PageWrapper, { allowSearch: true, "aria-hidden": !doneLoading, style: { opacity: doneLoading ? 1 : 0 } },
            React.createElement(CheckoutCTA, { visible: loggedIn && !hasPurchasedModule(moduleId), moduleId: moduleId }),
            React.createElement(ModuleDetailContainer, null,
                !isMobile ? (React.createElement(ContentDetailCard, { style: { margin: "0px", height: "100%" }, selectedContentId: focusedUnitId, contentType: ContentTypes.unit, title: (_a = modules[moduleId]) === null || _a === void 0 ? void 0 : _a.name, defaultContentId: moduleId })) : (null),
                React.createElement(UnitListContainer, null,
                    Object.values(units)
                        .sort((unit1, unit2) => unit1.index - unit2.index)
                        .map((unit, i) => {
                        var _a;
                        return (React.createElement(NodeContainer, { key: i },
                            React.createElement(Node, { availability: (_a = modules[moduleId]) === null || _a === void 0 ? void 0 : _a.availability, unpurchased: !hasPurchasedModule(moduleId), svgName: unit.id, linkTo: contentPath(moduleId, unit.id), label: unit.name, labelPosition: "E" /* Positions.E */, onFocus: onFocusUnit(unit.id), onBlur: onBlurUnit })));
                    }),
                    React.createElement(Line, { darkened: ((_b = modules[moduleId]) === null || _b === void 0 ? void 0 : _b.availability) === Availabilities.comingNext || ((_c = modules[moduleId]) === null || _c === void 0 ? void 0 : _c.availability) === Availabilities.comingSoon || !hasPurchasedModule(moduleId), numUnits: Object.keys(units).length }))))));
}
