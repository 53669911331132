var _a;
import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./Router";
import { theme } from "./theme";
import { logErrorReportFail, logTrackingFail } from "./utils/constants";
import "./utils/requests"; // setting default config
const AmplitudeApiKey = ENV === "test" /* Envs.test */
    ? null
    : ENV === "production" /* Envs.production */
        ? "d923f7de1f450968c1d1832f6375185b"
        : "dba19e90745eb55d1477bd41c27092eb";
if (AmplitudeApiKey) {
    try {
        (_a = window === null || window === void 0 ? void 0 : window.amplitude) === null || _a === void 0 ? void 0 : _a.init(AmplitudeApiKey, {
            appVersion: RELEASE,
        }, {
            disableCookies: true,
            pageViewTracking: true,
        });
    }
    catch (apmError) {
        logTrackingFail();
    }
}
else {
    delete window.amplitude;
}
if (ENV === "production" /* Envs.production */) {
    try {
        Bugsnag === null || Bugsnag === void 0 ? void 0 : Bugsnag.start({
            apiKey: BUGSNAG_API_KEY,
            appVersion: RELEASE,
            collectUserIp: false,
        });
    }
    catch (err) {
        logErrorReportFail();
    }
}
const rootElement = document.getElementById("root");
rootElement.style.backgroundColor = theme.primaryColor;
ReactDOM.createRoot(rootElement).render(React.createElement(Router, null));
