import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import Prism from "prismjs";
import { GoogleIdentityContext } from "../../context/googleIdentityContext";
import { parseMdText } from "./parseMdText";
import { preprocessRawMarkdown } from "./preprocessRawMarkdown";
import ProgressBar from "../ProgressBar";
import TutorialFormat, { TutorialStep } from "./TutorialFormat";
import LoginWall from "../LoginWall";
import { StyledMDContainer } from "./styled";
import { Formats } from "./types";
import Button from "../Button/Button";
import { theme } from "../../theme";
import { contentPath } from "../../utils/constants";
Prism.hooks.add("after-highlight", env => {
    // works only for <code> wrapped inside <pre data-line-numbers> (not inline)
    let pre = env.element.parentNode;
    if (!pre || !/pre/i.test(pre.nodeName) || pre.className.indexOf("line-numbers") === -1) {
        return;
    }
    const linesNum = 1 + env.code.split("\n").length;
    let lineNumbersWrapper;
    const lines = new Array(linesNum).join("<span></span>");
    lineNumbersWrapper = document.createElement("span");
    lineNumbersWrapper.className = "line-numbers-rows";
    lineNumbersWrapper.innerHTML = lines;
    if (pre.hasAttribute("data-start")) {
        pre.style.counterReset = "linenumber " + (parseInt(pre.getAttribute("data-start"), 10) - 1);
    }
    env.element.appendChild(lineNumbersWrapper);
});
export default function MarkdownRenderer({ markdown, requiresAuth, onLoaded, mdAreaWidth = "80%", }) {
    const { hash } = useLocation();
    const { moduleId, unitId } = useParams();
    const { loggedIn } = useContext(GoogleIdentityContext);
    const [format, setFormat] = useState(null);
    const [content, setContent] = useState();
    const [contentLoaded, setContentLoaded] = useState(false);
    const [checkpointIds, setCheckpointIds] = useState([]);
    const [tutorialStepTitles, setTutorialStepTitles] = useState([]);
    const [tutorialSteps, setTutorialSteps] = useState(null);
    const [stepIndex, setStepIndex] = useState(null);
    const requestLogin = useMemo(() => !!(requiresAuth && !loggedIn), [requiresAuth, loggedIn]);
    const bottomPadding = useMemo(() => {
        switch (format) {
            case Formats["deep dive"]: return `calc(100vh - (${theme.appMarginSize} / 3) - 2rem - 113px)`;
            case Formats.video: return "4rem";
            default: return "auto";
        }
    }, [format]);
    const parseContent = useCallback(() => {
        if (!markdown)
            return;
        if (/^<format>([\s\S]*?)<\/format>/.test(markdown)) {
            const closingFormatTagIndex = markdown.indexOf("</format>");
            if (closingFormatTagIndex < 0)
                throw new Error("malformed format tag");
            setFormat(markdown.slice(8, closingFormatTagIndex).trim());
            markdown = markdown.replace(/^<format>([\s\S]*?)<\/format>\s*/, "");
        }
        const jsx = parseMdText(preprocessRawMarkdown(markdown));
        const ids = jsx.filter(element => { var _a; return (_a = element.props) === null || _a === void 0 ? void 0 : _a.id; }).map(element => element.props.id);
        const tutorialSteps = jsx.filter(child => { var _a, _b; return ((_b = (_a = child === null || child === void 0 ? void 0 : child.type) === null || _a === void 0 ? void 0 : _a.prototype) === null || _b === void 0 ? void 0 : _b.constructor) === TutorialStep; });
        if (tutorialSteps) {
            setTutorialStepTitles(tutorialSteps.map(step => step.props.title));
            setTutorialSteps(tutorialSteps);
        }
        setCheckpointIds(ids);
        setContent(jsx);
        if (onLoaded)
            onLoaded();
        setContentLoaded(true);
    }, [markdown]);
    const clearContent = useCallback(() => {
        setTutorialSteps(null);
        setCheckpointIds([]);
        setContent([]);
    }, []);
    useEffect(() => {
        if (requestLogin) {
            clearContent();
            if (onLoaded)
                onLoaded();
        }
        else {
            parseContent();
        }
    }, [onLoaded, requestLogin, markdown, parseContent]);
    useEffect(() => {
        if (format === Formats.tutorial) {
            const nextStepIndex = parseInt(hash === null || hash === void 0 ? void 0 : hash.slice(1));
            if (hash && !isNaN(nextStepIndex)) {
                setStepIndex(nextStepIndex);
            }
            else {
                setStepIndex(0);
            }
        }
    }, [hash, format]);
    useEffect(() => Prism.highlightAll(), [content, stepIndex]);
    return (React.createElement(React.Fragment, null,
        requestLogin ? React.createElement(LoginWall, null) : React.createElement(React.Fragment, null),
        React.createElement(StyledMDContainer, { style: { paddingBottom: bottomPadding }, mdAreaWidth: mdAreaWidth },
            format === Formats.tutorial ? (React.createElement(TutorialFormat, { tutorialSteps: tutorialSteps, stepIndex: stepIndex, titles: tutorialStepTitles })) : (content),
            format === Formats["deep dive"] ? (React.createElement(ProgressBar, { contentLoaded: contentLoaded, checkpointIds: checkpointIds, longitude: "95%" })) : null),
        (format === Formats["deep dive"] || format === Formats.video) && content ? (React.createElement("div", { style: { display: "flex", justifyContent: "center", gap: "2rem" } },
            React.createElement(Button, { label: "scroll-to-top", onClick: () => window.scrollTo(0, 0) }, "To top"),
            React.createElement(Button, { label: "back-to-lessons", linkTo: contentPath(moduleId, unitId) }, "To unit"),
            React.createElement(Button, { label: "back-to-units", linkTo: contentPath(moduleId) }, "To module"))) : null));
}
