var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useState, useContext } from "react";
import Button from "../../components/Button";
import PageWrapper from "../../components/PageWrapper";
import CopyToClipboard from "../../components/Logos/CopyToClipboard";
import { MessageInput, InfoArea, EmailInput, Email, ContactContainer } from "./styled";
import { httpPost } from "../../utils/requests";
import { SnackbarContext } from "../../context/snackbarContext";
import { CONTACT_EMAIL } from "../../utils/constants";
export default function Contact() {
    const { pushToast } = useContext(SnackbarContext);
    const [fromEmail, setFromEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const copy = () => {
        navigator.clipboard.writeText(CONTACT_EMAIL);
        pushToast({
            message: "copied to clipboard",
            level: "success",
        });
    };
    const onSubmit = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        setLoading(true);
        try {
            yield httpPost("/contact", {
                message,
                fromEmail,
            });
            pushToast({
                level: "success",
                message: "Message received!",
            });
            setFromEmail("");
            setMessage("");
        }
        catch (error) {
            pushToast({
                level: "error",
                message: "Something went wrong. Try emailing me directly.",
            });
        }
        setLoading(false);
    }), [fromEmail, message, pushToast]);
    return (React.createElement(PageWrapper, null,
        React.createElement(ContactContainer, null,
            React.createElement(InfoArea, null,
                "I'd love to hear from you. I'll try respond to the email you provide. Also, if you'd prefer to email me directly, here's my email:",
                " ",
                React.createElement(Email, { onClick: copy, "aria-label": "copy to clipboard", title: "copy to clipboard" }, CONTACT_EMAIL),
                React.createElement(CopyToClipboard, { copy: copy })),
            React.createElement(EmailInput, { onChange: (e) => setFromEmail(e.target.value), label: "input your email", value: fromEmail, placeholder: "Your email" }),
            React.createElement(MessageInput, { onChange: (e) => setMessage(e.target.value), value: message, placeholder: "Let me know what you think." }),
            React.createElement(Button, { style: { width: "4rem" }, onClick: onSubmit, label: "submit", isLoading: loading }, "Submit"))));
}
