import React, { useContext } from "react";
import { PleaseLoginContainer, Apology, CTA, DirectToPolicy } from "./styled";
import { GoogleIdentityContext } from "../../context/googleIdentityContext";
import LockLogo from "../Logos/LockLogo";
import { theme } from "../../theme";
import StyledLink from "../StyledLink";
export default function LoginWall() {
    const { GoogleAuthButton } = useContext(GoogleIdentityContext);
    return (React.createElement(PleaseLoginContainer, null,
        React.createElement(LockLogo, { style: { width: "8rem", fill: theme.secondaryColor }, color: theme.secondaryColor }),
        React.createElement(Apology, null,
            React.createElement("p", null, "Sorry! Authentication required."),
            React.createElement("p", null, "Please log in to proceed.")),
        React.createElement(CTA, null,
            React.createElement(GoogleAuthButton, { style: {
                    position: "relative",
                    left: "50%",
                    top: "50%",
                    transform: "translateX(-50%)",
                } })),
        React.createElement(DirectToPolicy, null,
            "If you're concerned about privacy/security, I understand where you're coming from. I assure you I am not selling your personal data. For more check out my",
            " ",
            React.createElement(StyledLink, { to: "/policy" }, "Privacy Policy"),
            ".")));
}
