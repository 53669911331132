var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useContext, useState, useMemo, useEffect, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { SnackbarContext } from "../../context/snackbarContext";
import { GoogleIdentityContext } from "../../context/googleIdentityContext";
import { httpPatch, httpDelete } from "../../utils/requests";
import { ViewOverlay, FormArea, TitleInput, DescriptionInput, Checklist, ChecklistItem, ChecklistCheckbox, TaskInput, DeleteTaskAction, overrideUnderlineWidth, } from "./styled";
import Button from "../Button";
import Minus from "../Logos/Minus";
import Check from "../Logos/Check";
import Grabbable from "../Logos/Grabbable";
import { theme } from "../../theme";
const encircledLogoStyles = {
    width: "66%",
    height: "66%",
    display: "flex",
};
const truthyTask = (checklistItem) => !!checklistItem.task;
export default function TicketDialog({ open, boardId, uuid, closeDialog, existingTicketAttributes, }) {
    const { pushToast } = useContext(SnackbarContext);
    const { tryWithTokenRefresh } = useContext(GoogleIdentityContext);
    const formAreaRef = useRef();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [checklist, setChecklist] = useState([
        { task: "", complete: false },
    ]);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    useEffect(() => {
        if (existingTicketAttributes.title)
            setTitle(existingTicketAttributes.title);
        if (existingTicketAttributes.description)
            setDescription(existingTicketAttributes.description);
        if (existingTicketAttributes.checklist)
            setChecklist(existingTicketAttributes.checklist.concat({ task: "", complete: false }));
    }, [existingTicketAttributes]);
    const updatedTicketAttributes = useMemo(() => (Object.assign(Object.assign({}, existingTicketAttributes), { title,
        description,
        checklist })), [title, description, checklist, existingTicketAttributes, open]);
    const toggleCheckbox = useCallback((index) => () => {
        setChecklist(prevChecklist => prevChecklist
            .map((item, i) => (i === index ? Object.assign(Object.assign({}, item), { complete: !item.complete }) : Object.assign({}, item)))
            .filter(truthyTask)
            .concat({ task: "", complete: false }));
    }, []);
    const updateTask = useCallback((index) => (event) => {
        setChecklist(prevChecklist => prevChecklist
            .map((item, i) => (i === index ? Object.assign(Object.assign({}, item), { task: event.target.value }) : Object.assign({}, item)))
            .filter(truthyTask)
            .concat({ task: "", complete: false }));
    }, []);
    const deleteTask = useCallback((index) => () => {
        setChecklist(prevChecklist => prevChecklist
            .filter((_, i) => i !== index)
            .filter(truthyTask)
            .concat({ task: "", complete: false }));
    }, []);
    const upsertTicket = useCallback(tryWithTokenRefresh(() => __awaiter(this, void 0, void 0, function* () {
        setSaveLoading(true);
        updatedTicketAttributes.checklist = updatedTicketAttributes.checklist.filter(truthyTask);
        const { data: ticket } = yield httpPatch(`/ticket/${boardId}${uuid ? `?uuid=${uuid}` : ""}`, updatedTicketAttributes);
        pushToast({
            level: "success",
            message: "Saved!",
        });
        closeDialog(ticket);
    }), {
        onError: () => {
            pushToast({
                level: "error",
                message: "Ticket not saved",
            });
        },
        onEnd: () => setSaveLoading(false)
    }), [boardId, uuid, updatedTicketAttributes, open]);
    const deleteTicket = useCallback(tryWithTokenRefresh(() => __awaiter(this, void 0, void 0, function* () {
        setDeleteLoading(true);
        updatedTicketAttributes.checklist = updatedTicketAttributes.checklist.filter(truthyTask);
        yield httpDelete(`/ticket/${boardId}${uuid ? `?uuid=${uuid}` : ""}`);
        pushToast({
            level: "info",
            message: "Deleted",
        });
        closeDialog(null, true);
    }), {
        onError: () => {
            pushToast({
                level: "error",
                message: "Ticket not deleted",
            });
        },
        onEnd: () => setDeleteLoading(false)
    }), [boardId, uuid, open]);
    const onDragEnd = useCallback(result => {
        if (!result.destination)
            return;
        const { source: { index: sourceIndex }, destination: { index: destinationIndex }, } = result;
        if (sourceIndex === destinationIndex)
            return;
        const updatedChecklist = [...checklist];
        updatedChecklist.splice(destinationIndex, 0, updatedChecklist.splice(sourceIndex, 1)[0]);
        setChecklist(updatedChecklist.filter(truthyTask).concat({ task: "", complete: false }));
    }, [checklist]);
    return (React.createElement(ViewOverlay, { onClick: () => closeDialog(null), open: open, "aria-hidden": !open, hidden: !open },
        React.createElement(DragDropContext, { onDragEnd: onDragEnd },
            React.createElement(FormArea, { ref: formAreaRef, onClick: (e) => e.stopPropagation(), open: open },
                React.createElement(TitleInput, { fullWidth: true, "data-cy": "card title", placeholder: "Title", label: "ticket title", value: title, underlineWidth: overrideUnderlineWidth, onChange: (event) => setTitle(event.target.value) }),
                React.createElement(DescriptionInput, { "data-cy": "card description", placeholder: "Ticket description", value: description, onChange: (event) => setDescription(event.target.value) }),
                React.createElement(Droppable, { droppableId: "list" }, (provided, snapshot) => (React.createElement(Checklist, Object.assign({}, provided.droppableProps, { ref: provided.innerRef, beingDraggedOver: snapshot.isDraggingOver }), checklist.map((item, index) => (React.createElement(Draggable, { draggableId: "" + index, index: index, key: index, isDragDisabled: !item.task }, (provided, snapshot) => {
                    var _a, _b, _c;
                    // this condition is here because the Draggable component translates down and right the amount the FormArea is from the top left of it's "display: fixed" parent.
                    // work around found here: https://github.com/atlassian/react-beautiful-dnd/issues/1881#issuecomment-691237307
                    if (snapshot.isDragging) {
                        const formArea = formAreaRef === null || formAreaRef === void 0 ? void 0 : formAreaRef.current;
                        const offset = {
                            x: (_a = formArea === null || formArea === void 0 ? void 0 : formArea.offsetLeft) !== null && _a !== void 0 ? _a : 0,
                            y: (_b = formArea === null || formArea === void 0 ? void 0 : formArea.offsetTop) !== null && _b !== void 0 ? _b : 0,
                        };
                        const x = provided.draggableProps.style.left - offset.x;
                        const y = provided.draggableProps.style.top - offset.y;
                        provided.draggableProps.style.left = x;
                        provided.draggableProps.style.top = y;
                    }
                    return (React.createElement(ChecklistItem, Object.assign({ ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { index: index }),
                        React.createElement(Grabbable, { style: {
                                fill: theme.colors["white"],
                                width: "1rem",
                                height: "1rem",
                                opacity: item.task ? 1 : 0,
                            } }),
                        React.createElement(ChecklistCheckbox, { label: item.complete ? "complete" : "incomplete", disabled: !item.task, onClick: toggleCheckbox(index), style: { opacity: item.task ? 1 : 0 } }, item.complete ? React.createElement(Check, { style: encircledLogoStyles }) : React.createElement(React.Fragment, null)),
                        React.createElement(TaskInput, { "data-cy": `subtask-input-${index}`, placeholder: "Add a task", label: "task", value: (_c = item.task) !== null && _c !== void 0 ? _c : "", onChange: updateTask(index), underlineWidth: overrideUnderlineWidth }),
                        React.createElement(DeleteTaskAction, { disabled: !item.task, onClick: deleteTask(index), label: "delete task", color: "red", style: { opacity: item.task ? 1 : 0 } },
                            React.createElement(Minus, { style: encircledLogoStyles }))));
                })))))),
                React.createElement("div", { style: {
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: ".5rem",
                        marginTop: ".5rem",
                    } },
                    React.createElement(Button, { onClick: upsertTicket, isLoading: saveLoading, disabled: deleteLoading, label: uuid ? "edit ticket" : "create ticket" },
                        "Save",
                        uuid ? " Changes" : ""),
                    React.createElement(Button, { onClick: () => {
                            if (uuid)
                                deleteTicket();
                            else
                                closeDialog(null);
                        }, color: "red", isLoading: deleteLoading, disabled: saveLoading, label: uuid ? "delete ticket" : "discard ticket" }, uuid ? "Delete Ticket" : "Discard Ticket"))))));
}
