import React, { createContext, useMemo, useState, useEffect, useCallback, useContext, } from "react";
import { useLocation } from "react-router-dom";
import { GoogleIdentityContext } from "./googleIdentityContext";
const DefaultPaywallModalState = {
    isOpen: false,
    activate: () => { },
};
export const PaywallModalContext = createContext(DefaultPaywallModalState);
export const PaywallModalProvider = ({ children }) => {
    const { loggedIn } = useContext(GoogleIdentityContext);
    const [isOpen, setIsOpen] = useState(false);
    const { pathname } = useLocation();
    useEffect(() => {
        if (isOpen)
            setIsOpen(false);
    }, [pathname]);
    useEffect(() => {
        if (!loggedIn)
            setIsOpen(false);
    }, [loggedIn]);
    const activate = useCallback(() => {
        setIsOpen(true);
    }, []);
    const values = useMemo(() => ({
        isOpen,
        activate,
    }), [isOpen]);
    return React.createElement(PaywallModalContext.Provider, { value: values }, children);
};
