import styled from "styled-components";
import Input from "../../components/Input";
export const ContactContainer = styled.div `
  display: flex;
  flex-direction: column;
  gap: .75rem;
  margin: 0 12.5vw;
`;
export const EmailInput = styled(Input) `
  width: 18rem;
  margin-top: 2rem;
  background-color: ${p => p.theme.colors["blackDark"]};
  input {
    background-color: ${p => p.theme.colors["blackDark"]};
    font-size: ${p => p.theme.fontSizes.small};
  }
`;
export const MessageInput = styled.textarea `
  border-radius: 1.3rem;
  padding: 1rem 1rem;
  height: 25vh;
  overflow-y: auto;
  resize: none;
  white-space: wrap;
  border: none;
  color: ${p => p.theme.fontColor};
  font-size: ${p => p.theme.fontSizes.small};
  background-color: ${p => p.theme.colors["blackDark"]};

  &:focus {
    outline: none;
  }
`;
export const InfoArea = styled.div `
  font-size: ${p => p.theme.fontSizes.small};
  font-family: ${p => p.theme.fonts.sanSerif};
  color: ${p => p.theme.fontColor};
`;
export const ClipboardButton = styled.button `
  color: transparent;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  transform: translate(-15px, 3px);
`;
export const StyledCopyToClipboard = styled.svg `
  cursor: pointer;
  width: 1.25rem;
  path {
    fill: ${p => { var _a; return (_a = p.color) !== null && _a !== void 0 ? _a : p.theme.linkColor; }};
  }
`;
export const Email = styled.span `
  position: relative;
  font-size: calc(${p => p.theme.fontSizes.small} - 2px);
  font-family: ${p => p.theme.fonts.code};
  color: ${p => p.theme.linkColor};
  margin: 0 1rem 0 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
