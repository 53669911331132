import React, { useContext, useMemo } from "react";
import CheckoutButton from "../../components/CheckoutButton";
import { buttonStyle, CTAcopy, CTAcontainer, LogoAndCopy, CTAarea } from "./styled";
import { ContentContext } from "../../context/contentContext";
import DynamicLogo from "../Logos/DynamicLogo";
import { theme } from "../../theme";
export default function CheckoutCTA({ moduleId, unitId, visible, paywalled, children, }) {
    var _a;
    const { modules } = useContext(ContentContext);
    const copy = useMemo(() => {
        var _a, _b;
        let text = [];
        if (unitId)
            text.push(`Purchase the ${(_b = (_a = modules[moduleId]) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : moduleId} module to access this content.`);
        if (!paywalled)
            text.push(`You can explore to read and learn more, but you cannot access the lessons without owning the module.`);
        return text.join(" ");
    }, [unitId, paywalled]);
    return (React.createElement(CTAcontainer, { visible: visible, "aria-hidden": !visible },
        React.createElement(CTAarea, null,
            React.createElement(LogoAndCopy, null,
                React.createElement(DynamicLogo, { svgName: (_a = modules === null || modules === void 0 ? void 0 : modules[moduleId]) === null || _a === void 0 ? void 0 : _a.id, style: { width: "3rem", filter: theme.colorFilters.magentaSoft } }),
                React.createElement(CTAcopy, null, copy),
                React.createElement(CheckoutButton, { moduleId: moduleId, style: buttonStyle }, "Purchase")),
            children)));
}
