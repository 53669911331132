import React, { useCallback, useMemo, useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ExpandableSelection, SelectionButton, TitleBreadCrumbsArea, TitleBreadCrumbDelimiter, TitleBreadCrumbList, TitleBreadCrumbLabel, MoreStepsMenu, } from "./styled";
import useClickAwayListener from "../../hooks/useClickAwayListener";
export default function UrlHashNavigator({ titles }) {
    const { hash } = useLocation();
    const buttonRef1 = useRef();
    const buttonRef2 = useRef();
    const expandableParentRef = useRef();
    const [prevStepSelectionOpen, setPrevStepSelectionOpen] = useState(false);
    const [nextStepSelectionOpen, setNextStepSelectionOpen] = useState(false);
    const currentTitleIndex = useMemo(() => parseInt(hash.slice(1)), [hash]);
    const closePrevStepSelections = useCallback((event) => {
        if (!((event === null || event === void 0 ? void 0 : event.target) === (buttonRef1 === null || buttonRef1 === void 0 ? void 0 : buttonRef1.current) || (event === null || event === void 0 ? void 0 : event.target) === (buttonRef2 === null || buttonRef2 === void 0 ? void 0 : buttonRef2.current))) {
            setPrevStepSelectionOpen(false);
        }
    }, [prevStepSelectionOpen, setPrevStepSelectionOpen]);
    const closeNextStepSelections = useCallback((event) => {
        if (!((event === null || event === void 0 ? void 0 : event.target) === (buttonRef1 === null || buttonRef1 === void 0 ? void 0 : buttonRef1.current) || (event === null || event === void 0 ? void 0 : event.target) === (buttonRef2 === null || buttonRef2 === void 0 ? void 0 : buttonRef2.current))) {
            setNextStepSelectionOpen(false);
        }
    }, [nextStepSelectionOpen, setNextStepSelectionOpen]);
    const { setRef: setPrevRefs } = useClickAwayListener({ onClickAway: closePrevStepSelections });
    const { setRef: setNextRefs } = useClickAwayListener({ onClickAway: closeNextStepSelections });
    const visibleTitles = useMemo(() => {
        return titles === null || titles === void 0 ? void 0 : titles.slice(Math.max(0, currentTitleIndex - 2), Math.min(currentTitleIndex + 2, titles.length));
    }, [titles, currentTitleIndex]);
    const navToStep = (stepIndex) => () => {
        if (currentTitleIndex !== stepIndex) {
            window.location.hash = `#${stepIndex}`;
            closePrevStepSelections();
            closeNextStepSelections();
            window.scrollTo(0, 0);
        }
    };
    useEffect(() => {
        if (!hash)
            window.location.hash = `#0`;
    }, [hash]);
    return React.createElement(TitleBreadCrumbsArea, null,
        React.createElement(TitleBreadCrumbList, null,
            currentTitleIndex > 2 &&
                React.createElement(MoreStepsMenu, { ref: buttonRef1, onClick: () => setPrevStepSelectionOpen(!prevStepSelectionOpen) },
                    "...",
                    React.createElement("div", { style: { position: "relative", width: 0, height: 0 }, ref: expandableParentRef },
                        React.createElement(ExpandableSelection, { className: "hidden-scrollbar", open: prevStepSelectionOpen, ref: setPrevRefs }, titles.slice(0, currentTitleIndex - 2).map((title, index) => (React.createElement(SelectionButton, { key: `go-to-${title}-1`, title: `go to ${title}`, onClick: navToStep(index) }, title)))))),
            currentTitleIndex > 2 && React.createElement(TitleBreadCrumbDelimiter, null, "/"),
            visibleTitles.map((breadCrumbTitle, index) => {
                const titleIndex = titles.indexOf(breadCrumbTitle);
                return (React.createElement(React.Fragment, null,
                    React.createElement(TitleBreadCrumbLabel, { currentStep: titleIndex === currentTitleIndex, key: `bc-title-${breadCrumbTitle}-${titleIndex}`, onClick: navToStep(titleIndex) }, breadCrumbTitle),
                    index < visibleTitles.length - 1 && React.createElement(TitleBreadCrumbDelimiter, { key: `bc-delimiter-${titleIndex}` }, "/")));
            }),
            currentTitleIndex < titles.length - 2 && React.createElement(TitleBreadCrumbDelimiter, null, "/"),
            currentTitleIndex < titles.length - 2 &&
                React.createElement(MoreStepsMenu, { ref: setNextRefs, onClick: () => setNextStepSelectionOpen(!nextStepSelectionOpen) },
                    "...",
                    React.createElement("div", { style: { position: "relative", width: 0, height: 0 }, ref: expandableParentRef },
                        React.createElement(ExpandableSelection, { className: "hidden-scrollbar", open: nextStepSelectionOpen, ref: setNextRefs }, titles.slice(currentTitleIndex + 2).map((title, index) => (React.createElement(SelectionButton, { key: `go-to-${title}-1`, title: `go to ${title}`, onClick: navToStep(currentTitleIndex + 2 + index) }, title))))))));
}
