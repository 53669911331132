import axios from "axios";
axios.defaults.baseURL = ENV === "production" /* Envs.production */ ? `https://api.devyourself.${window.location.host.split(".").pop()}` : API_URL;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = window.location.href.slice(0, -1);
const versionedUrl = (url) => `${localStorage.getItem("contentVersion")}/${url.replace(/^\//, "")}`;
export const httpGet = (url, config) => axios.get(versionedUrl(url), config);
export const httpDelete = (url, config) => axios.delete(versionedUrl(url), config);
export const httpPut = (url, data, config) => axios.put(versionedUrl(url), data, config);
export const httpPost = (url, data, config) => axios.post(versionedUrl(url), data, config);
export const httpPatch = (url, data, config) => axios.patch(versionedUrl(url), data, config);
export const requestConfig = axios;
