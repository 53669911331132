import React, { useContext, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageWrapper from "../../components/PageWrapper";
import { GoogleIdentityContext } from "../../context/googleIdentityContext";
import { ContentContext } from "../../context/contentContext";
import { UserContext } from "../../context/userContext";
import { PaywallModalContext } from "../../context/paywallContext";
import MarkdownRenderer from "../../components/MarkdownRenderer";
import Loader from "../../components/Loader";
import { theme } from "../../theme";
export default function Lesson() {
    var _a, _b;
    const { loggedIn } = useContext(GoogleIdentityContext);
    const { hasPurchasedModule, userIsLoaded } = useContext(UserContext);
    const { activate: activatePaywall, isOpen: paywallIsOpen } = useContext(PaywallModalContext);
    const { modules, units, lessons, loadingModules, loadingUnits, loadingLessons, loadedLessons, } = useContext(ContentContext);
    const { moduleId, unitId, lessonId } = useParams();
    const navigate = useNavigate();
    const doneLoading = useMemo(() => {
        var _a;
        return paywallIsOpen || !loggedIn || (!loadingLessons && lessonId in lessons && ((_a = lessons[lessonId]) === null || _a === void 0 ? void 0 : _a.markdown));
    }, [loadingLessons, lessonId, lessons, paywallIsOpen]);
    useEffect(() => {
        if (loggedIn &&
            userIsLoaded &&
            !hasPurchasedModule(moduleId) &&
            !loadingModules &&
            moduleId in modules &&
            !loadingUnits &&
            unitId in units) {
            activatePaywall();
        }
        else if (loggedIn && loadedLessons && !(lessonId in lessons)) {
            setTimeout(() => {
                if (loggedIn && loadedLessons && !(lessonId in lessons)) {
                    navigate("/404", { replace: true });
                }
            }, 1000);
        }
    }, [
        loadingModules,
        loadingUnits,
        hasPurchasedModule,
        loggedIn,
        modules,
        units,
        moduleId,
        unitId,
        activatePaywall,
        userIsLoaded,
        loadedLessons,
    ]);
    return (React.createElement(React.Fragment, null,
        doneLoading ? null : React.createElement(Loader, null),
        React.createElement(PageWrapper, { showCodeEditorButton: loggedIn && loadedLessons && !paywallIsOpen, style: { paddingBottom: `calc(${theme.appMarginSize} / 3)`, opacity: doneLoading ? 1 : 0 } },
            React.createElement("div", { style: { margin: `0px calc(${theme.appMarginSize}/2)` } },
                React.createElement(MarkdownRenderer, { markdown: (_b = (_a = lessons[lessonId]) === null || _a === void 0 ? void 0 : _a.markdown) !== null && _b !== void 0 ? _b : "", requiresAuth: true })))));
}
