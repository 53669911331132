import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { theme } from "../../theme";
import { contentPath } from "../../utils/constants";
import { ContentContext, ContentTypes } from "../../context/contentContext";
import { UserContext } from "../../context/userContext";
import { xPosition, yPosition, buildTrees } from "./helpersAndConstants";
import useSizeAndPosition from "../../hooks/useSizeAndPosition";
import { ModuleTreeContainer, PageContainer } from "./styled";
import { WindowSizeContext } from "../../context/windowSizeContext";
import ContentDetailCard from "../../components/ContentDetailCard";
import Loader from "../../components/Loader/Loader";
import Node from "../../components/Node";
import PageWrapper from "../../components/PageWrapper";
import { Availabilities } from "../../types";
export default function ModuleTree() {
    const { modules, loadedModules } = useContext(ContentContext);
    const { isMobile } = useContext(WindowSizeContext);
    const { hasPurchasedModule } = useContext(UserContext);
    const { x: left, y: top, width, height, setNode } = useSizeAndPosition();
    const [layers, setLayers] = useState([]);
    const moduleList = useMemo(() => Object.values(modules), [modules]);
    const [focusedModuleId, setFocusedModuleId] = useState();
    const onFocusModule = useCallback((moduleId) => () => setFocusedModuleId(moduleId), []);
    const onBlurModule = useCallback(() => setFocusedModuleId(null), []);
    useEffect(() => {
        if (moduleList) {
            const [nextLayers] = buildTrees(moduleList);
            setLayers(nextLayers || []);
        }
    }, [moduleList]);
    return React.createElement(React.Fragment, null,
        React.createElement(PageWrapper, { allowSearch: true, "aria-hidden": loadedModules, style: { opacity: loadedModules ? 1 : 0 } }, !loadedModules ? (React.createElement(Loader, null)) : (React.createElement(PageContainer, null,
            !isMobile ? (React.createElement(ContentDetailCard, { title: "Modules", contentType: ContentTypes.module, selectedContentId: focusedModuleId })) : (null),
            React.createElement(ModuleTreeContainer, { ref: setNode },
                React.createElement("svg", { style: { position: "absolute", top, left, width, height, overflow: "visible", marginBottom: "1rem" }, viewBox: `0 0 ${width} ${height}` }, layers.map((row, rowIndex) => row.map((node, nodeIndex) => (React.createElement(Edges, { hasPurchasedModule: hasPurchasedModule, key: nodeIndex, rows: layers, rowIndex: rowIndex, node: node, nodeIndex: nodeIndex, dimensions: {
                        left,
                        top,
                        width,
                        height,
                    } }))))),
                layers.map((row, rowIndex) => row.map((node, index) => (React.createElement(Node, { label: node.name, svgName: node.id, onFocus: onFocusModule(node.id), onBlur: onBlurModule, linkTo: contentPath(node.id), x: xPosition(index, row.length, left, width, node.name), y: yPosition(rowIndex, top), key: index, unpurchased: !hasPurchasedModule(node.id), availability: node.availability })))))))));
}
function Edges({ rows, rowIndex, node, nodeIndex, hasPurchasedModule, dimensions: { top, left, width }, }) {
    const row = useMemo(() => rows[rowIndex], [rows, rowIndex]);
    const strokeColor = useCallback((parent, child) => {
        if (hasPurchasedModule(parent.id) && hasPurchasedModule(child.id) && child.availability !== Availabilities.comingNext && child.availability !== Availabilities.comingSoon)
            return theme.secondaryColor;
        else
            return theme.colors["grey"];
    }, [hasPurchasedModule]);
    if (rowIndex === rows.length - 1)
        return null;
    if (node.children.length === 0)
        return null;
    return (React.createElement(React.Fragment, null, node.children.map(child => {
        const x1 = xPosition(nodeIndex, row.length, left, width, node.name) - left;
        const x2 = xPosition(rows[child.depth].indexOf(child), rows[child.depth].length, left, width, child.name) - left;
        const y1 = yPosition(node.depth, top) - top;
        const y2 = yPosition(child.depth, top) - top;
        return (React.createElement("line", { key: `${x1},${y1},${x2},${y2}`, x1: x1, y1: y1, x2: x2, y2: y2, stroke: strokeColor(node, child), strokeWidth: theme.edgeWidth }));
    })));
}
