import React, { useMemo } from "react";
import { Ring, LoaderNode, LoaderNodeContainer } from "./styled";
export default function Loader({ scale, duration, style }) {
    const S = useMemo(() => scale !== null && scale !== void 0 ? scale : 2, [scale]);
    const D = useMemo(() => duration !== null && duration !== void 0 ? duration : 3, [duration]);
    return (React.createElement(Ring, { scale: S, duration: D, style: style, "data-cy": "loader" },
        React.createElement(LoaderNodeContainer, { scale: S, initialOffset: 90, duration: D },
            React.createElement(LoaderNode, { scale: S })),
        React.createElement(LoaderNodeContainer, { scale: S, initialOffset: 270, duration: D },
            React.createElement(LoaderNode, { scale: S }))));
}
