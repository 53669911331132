import { useState, useLayoutEffect, useRef, useCallback } from "react";
import { OFFSCREEN_COORDINATE } from "../utils/constants";
export const defaultSizeAndPosition = {
    x: OFFSCREEN_COORDINATE,
    y: OFFSCREEN_COORDINATE,
    width: OFFSCREEN_COORDINATE,
    height: OFFSCREEN_COORDINATE,
};
export default function useSizeAndPosition() {
    const observer = useRef(null);
    const [width, setWidth] = useState(OFFSCREEN_COORDINATE);
    const [height, setHeight] = useState(OFFSCREEN_COORDINATE);
    const [y, setY] = useState(OFFSCREEN_COORDINATE);
    const [x, setX] = useState(OFFSCREEN_COORDINATE);
    const [node, setNode] = useState(null);
    const onSize = useCallback(([{ contentRect }]) => {
        setWidth(contentRect.width);
        setHeight(contentRect.height);
        setY(contentRect.y);
        setX(contentRect.x);
    }, []);
    const disconnect = useCallback(() => { var _a; return (_a = observer.current) === null || _a === void 0 ? void 0 : _a.disconnect(); }, []);
    const observe = useCallback(() => {
        observer.current = new ResizeObserver(onSize);
        if (node)
            observer.current.observe(node);
    }, [node]);
    useLayoutEffect(() => {
        observe();
        return () => disconnect();
    }, [disconnect, observe]);
    return { width, height, x, y, setNode };
}
