var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useMemo, useState, useEffect, useContext, useCallback, } from "react";
import { httpGet, httpPatch, httpDelete } from "../utils/requests";
import { SnackbarContext } from "./snackbarContext";
import { GoogleIdentityContext } from "./googleIdentityContext";
const DefaultBoardsState = {
    loadingBoards: false,
    loadedBoards: false,
    boards: {},
    changeBoardStatus: () => __awaiter(void 0, void 0, void 0, function* () { }),
    deleteBoard: () => __awaiter(void 0, void 0, void 0, function* () { }),
    getBoards: () => __awaiter(void 0, void 0, void 0, function* () { }),
};
export const BoardsContext = createContext(DefaultBoardsState);
export const BoardsProvider = ({ children }) => {
    const { pushToast } = useContext(SnackbarContext);
    const { loggedIn, idToken, tryWithTokenRefresh } = useContext(GoogleIdentityContext);
    const [boards, setBoards] = useState({});
    const [loadingBoards, setLoadingBoards] = useState(false);
    const [loadedBoards, setLoadedBoards] = useState(false);
    const getBoards = useCallback(tryWithTokenRefresh(() => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingBoards(true);
        setLoadedBoards(false);
        const { data: usersBoards } = yield httpGet("/board");
        const boardLookup = usersBoards.reduce((boardLookup, board) => (Object.assign(Object.assign({}, boardLookup), { [board.boardId]: board })), {});
        setBoards(boardLookup);
    }), {
        onError: () => {
            pushToast({
                level: "error",
                message: `Failed to load kanban boards ${String.fromCodePoint(0x1f613)}`,
            });
            setBoards({});
        },
        onEnd: () => setLoadingBoards(false),
        onSuccess: () => setLoadedBoards(true),
    }), [boards, idToken]);
    const deleteBoard = useCallback(tryWithTokenRefresh((boardId) => __awaiter(void 0, void 0, void 0, function* () {
        yield httpDelete(`/board/${boardId}`);
        const filteredBoardLookup = Object.assign({}, boards);
        delete filteredBoardLookup[boardId];
        setBoards(filteredBoardLookup);
        pushToast({
            level: "info",
            message: "Board deleted",
        });
    }), {
        onError: () => {
            pushToast({
                level: "error",
                message: "Failed to delete kanban board",
            });
            setBoards({});
        }
    }), [boards, idToken]);
    const changeBoardStatus = useCallback(tryWithTokenRefresh((boardId, newStatus) => __awaiter(void 0, void 0, void 0, function* () {
        const { data: updatedBoard } = yield httpPatch(`/board/${boardId}`, { status: newStatus });
        setBoards(boardsBefore => (Object.assign(Object.assign({}, boardsBefore), { [boardId]: Object.assign({}, updatedBoard) })));
    }), {
        onSuccess: () => {
            pushToast({
                level: "error",
                message: "Status change failed",
            });
        }
    }), [boards, idToken]);
    useEffect(() => {
        if (loggedIn) {
            getBoards();
        }
        else {
            setBoards({});
        }
    }, [loggedIn]);
    const values = useMemo(() => ({
        loadingBoards,
        loadedBoards,
        boards,
        deleteBoard,
        getBoards,
        changeBoardStatus,
    }), [boards, loadingBoards]);
    return React.createElement(BoardsContext.Provider, { value: values }, children);
};
