import React from "react";
export default function Code({ style }) {
    return React.createElement("svg", { style: style, viewBox: "-0.0001 29.4282 500.0001 440.635", width: "500.0001", height: "440.635", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { transform: "matrix(4.59066915512085, 0, 0, 4.59066915512085, -969.2074584960938, -667.0123291015625)" },
            React.createElement("g", { id: "SVGRepo_iconCarrier", transform: "matrix(0.165947, 0, 0, 0.165947, 98.126694, 29.60523)" },
                React.createElement("title", null),
                React.createElement("path", { d: "M803.5,1283a45.1,45.1,0,0,1-31.8-76.9L1202,775.8a45,45,0,0,1,63.6,63.7L835.3,1269.8A44.8,44.8,0,0,1,803.5,1283Z" }),
                React.createElement("path", { d: "M842.8,1000a45,45,0,0,1-26.9-8.9L699,904a45.1,45.1,0,0,1,0-72.2l116.9-87.1a45,45,0,0,1,53.8,72.2l-68.5,51,68.5,51a45,45,0,0,1-26.9,81.1Z" }),
                React.createElement("path", { d: "M1175.4,1314.2a45,45,0,0,1-26.9-81.1l68.4-51-68.4-51a45,45,0,1,1,53.7-72.2l117,87.1a45.1,45.1,0,0,1,0,72.2l-117,87.1A44.9,44.9,0,0,1,1175.4,1314.2Z" }))));
}
;
