import React, { useContext, useMemo } from "react";
import { PaywallContainer, ValueProp, PurchaseArea, MiniNode } from "./styled";
import CheckoutCTA from "../CheckoutCTA";
import { ContentContext } from "../../context/contentContext";
export default function Paywall({ moduleId, unitId }) {
    var _a;
    const { modules } = useContext(ContentContext);
    const paywallMessage = useMemo(() => {
        return `Sorry, you need to purchase this module access to this content.`;
    }, [moduleId, unitId]);
    return (React.createElement(PaywallContainer, null,
        paywallMessage,
        React.createElement(PurchaseArea, null,
            React.createElement(CheckoutCTA, { moduleId: moduleId, unitId: unitId, visible: true, paywalled: true },
                React.createElement(ValueProps, null,
                    React.createElement("p", null,
                        React.createElement("s", null, "$50"),
                        "$25"),
                    React.createElement("p", null,
                        "Full access to all content within ", (_a = modules === null || modules === void 0 ? void 0 : modules[moduleId].name) !== null && _a !== void 0 ? _a : moduleId),
                    React.createElement("p", null, "Materials include: tutorials, deep dives, videos, projects, kanban boards"),
                    React.createElement("p", null, "Access is permanent."))))));
}
function ValueProps({ children }) {
    return (React.createElement("div", { style: { display: "flex", flexDirection: "column", alignItems: "baseline" } }, children.map((child) => (React.createElement(ValueProp, null,
        React.createElement("span", null,
            React.createElement(MiniNode, null),
            child))))));
}
