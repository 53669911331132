import styled from "styled-components";
const borderRadius = () => "1.3rem";
const endAdornmentSpace = "2rem";
export const InputContainer = styled.div `
  position relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-width: 8rem;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "fit-content")};
  border-radius: ${borderRadius};
  border: 2px solid ${p => p.theme.secondaryColor};
  height: 2.6rem;
  padding: 0 0.25rem;
  &:focus-within {
    border: 2px solid ${p => p.theme.fontColor};
  }
  &:hover {
    border: 2px solid ${p => p.theme.fontColor};
  }
`;
export const InputRow = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ hasStartAdornment }) => (!hasStartAdornment ? "center" : "flex-start")};
  gap: 0.5rem;
  width: 100%;
`;
export const InputAdornment = styled.div `
  height: 1.5rem;
  width: 1.5rem;
  margin-left: ${({ isEndAdornment }) => (isEndAdornment ? "0" : "0.25rem")};
  position: ${({ isEndAdornment }) => (isEndAdornment ? "absolute" : "relative")};
  bottom: ${({ isEndAdornment }) => (isEndAdornment ? "calc(50% - 0.75rem)" : "")};
  right: ${({ isEndAdornment }) => (isEndAdornment ? "0.5rem" : "")};
`;
export const InputAndUnderline = styled.div `
  position: relative;
  width: calc(
    100% - (${borderRadius} / 2) - ${p => (p.hasStartAdornment ? endAdornmentSpace : "0px")}
  );
`;
const inputWidth = ({ hasEndAdornment }) => hasEndAdornment ? `calc(100% - ${endAdornmentSpace})` : "100%";
export const StyledInput = styled.input `
  min-height: 1.813rem;
  width: ${inputWidth};
  background-color: transparent;
  color: ${p => p.theme.fontColor};
  outline: none;
  border: none;
  font-size: ${p => p.theme.fontSizes.medium};
  caret-color: ${p => p.theme.fontColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.1s ease-in-out;
`;
export const underlineWidth = ({ focused, overrideUnderlineWidth, hasEndAdornment, }) => {
    if (!focused)
        return "40%";
    if (overrideUnderlineWidth !== undefined)
        return overrideUnderlineWidth;
    return `calc(.97 *
      (${hasEndAdornment ? endAdornmentSpace : "0px"} + ${inputWidth({ hasEndAdornment })} )
  )`;
};
export const Underline = styled.div `
  z-index: 100;
  height: 2px;
  position: absolute;
  width: ${underlineWidth};
  bottom: ${p => (p.focused ? "0" : "-6px")};
  left: 50%;
  background-color: ${p => (p.focused ? p.theme.fontColor : "transparent")};
  transform: translateX(-50%);
  transition: width 0.15s ease-in-out, bottom 0.15s ease-in-out, background-color 0.1s ease-in;
`;
