import React, { createContext, useMemo, useState } from "react";
import useIsMobile from "../hooks/useIsMobile";
import WindowSizeWarningModal from "../components/WindowSizeWarningModal/WindowSizeWarningModal";
const DefaultWindowSizeState = {
    isMobile: false,
};
export const WindowSizeContext = createContext(DefaultWindowSizeState);
export const WindowSizeProvider = ({ children }) => {
    const isMobile = useIsMobile();
    const [dismissedWindow, setDismissedWindow] = useState(false);
    const windowSizeWarningModalOpen = useMemo(() => isMobile && !dismissedWindow, [isMobile, dismissedWindow]);
    const values = useMemo(() => ({
        isMobile,
    }), [isMobile]);
    return (React.createElement(WindowSizeContext.Provider, { value: values },
        React.createElement(WindowSizeWarningModal, { open: windowSizeWarningModalOpen, onClose: () => setDismissedWindow(true) }),
        children));
};
