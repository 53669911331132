import styled from "styled-components";
export const StyledVideoTag = styled.video `
  border-radius: 8px;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`;
export const VideoPlayerContainer = styled.div `
  position: relative;
  min-height: ${p => p.loading ? "25rem" : "auto"};
`;
export const VideoLoadingContainer = styled.div `
  border-radius: 8px;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`;
