import React, { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { GoogleIdentityContext } from "../../context/googleIdentityContext";
import { App, NavBar, AppBody, NavArea, CircularMenuButton, MenuDialog, MobileNavArea, } from "./styled";
import Logo from "../Logos/DevYourself";
import { theme } from "../../theme";
import Button from "../Button";
import Grabbable from "../Logos/Grabbable";
import Cancel from "../Logos/Cancel";
import { WindowSizeContext } from "../../context/windowSizeContext";
import { contentPath } from "../../utils/constants";
const buttonStyle = {
    marginTop: "1rem",
    minWidth: "4rem",
    width: "auto",
};
const googleButtonStyle = Object.assign(Object.assign({}, buttonStyle), { fontSize: theme.fontSizes.huge, height: "5rem", borderRadius: "2.5rem" });
export default function Menu() {
    const { isMobile } = useContext(WindowSizeContext);
    const [menuIsVisible, setMenuIsVisible] = useState(false);
    useEffect(() => {
        if (menuIsVisible && !isMobile) {
            setMenuIsVisible(false);
        }
    }, [isMobile]);
    return (React.createElement(App, null,
        isMobile ? (React.createElement(MobileMenu, { menuIsVisible: menuIsVisible, setMenuIsVisible: setMenuIsVisible })) : (React.createElement(DesktopMenu, null)),
        isMobile && menuIsVisible ? null : (React.createElement(AppBody, null,
            React.createElement(Outlet, null)))));
}
function DesktopMenu() {
    const { GoogleAuthButton } = useContext(GoogleIdentityContext);
    return (React.createElement(NavBar, null,
        React.createElement(NavArea, null,
            React.createElement(Logo, { color: theme.linkColor, linkTo: "/", style: { width: "4rem", height: "4rem", marginRight: "1rem" } }),
            React.createElement(Button, { style: buttonStyle, linkTo: contentPath(), label: "modules" }, "Modules"),
            React.createElement(Button, { style: buttonStyle, linkTo: "/boards", label: "kanban boards" }, "Boards"),
            React.createElement(Button, { style: buttonStyle, linkTo: "/about", label: "about" }, "About"),
            React.createElement(Button, { style: buttonStyle, linkTo: "/faq", label: "frequently asked questions" }, "FAQ"),
            React.createElement(Button, { style: buttonStyle, linkTo: "/contact", label: "contact" }, "Contact"),
            React.createElement(Button, { style: buttonStyle, linkTo: "/policy", label: "user policy" }, "Policy")),
        React.createElement(GoogleAuthButton, { style: buttonStyle })));
}
function MobileMenu({ menuIsVisible, setMenuIsVisible, }) {
    const { GoogleAuthButton, loggedIn } = useContext(GoogleIdentityContext);
    return menuIsVisible ? (React.createElement(MenuDialog, { visible: menuIsVisible, "aria-hidden": !menuIsVisible },
        React.createElement(CircularMenuButton, { label: "Menu", onClick: () => setMenuIsVisible(!menuIsVisible) },
            React.createElement(Cancel, { style: { width: "65%", height: "65%" } })),
        React.createElement(Button, { size: "huge", style: buttonStyle, onClick: () => setMenuIsVisible(false), linkTo: contentPath(), label: "modules" }, "Modules"),
        React.createElement(Button, { size: "huge", style: buttonStyle, onClick: () => setMenuIsVisible(false), linkTo: "/boards", label: "kanban boards" }, "Boards"),
        React.createElement(Button, { size: "huge", style: buttonStyle, onClick: () => setMenuIsVisible(false), linkTo: "/about", label: "about" }, "About"),
        React.createElement(Button, { size: "huge", style: buttonStyle, onClick: () => setMenuIsVisible(false), linkTo: "/faq", label: "frequently asked questions" }, "FAQ"),
        React.createElement(Button, { size: "huge", style: buttonStyle, onClick: () => setMenuIsVisible(false), linkTo: "/contact", label: "contact" }, "Contact"),
        React.createElement(Button, { size: "huge", style: buttonStyle, onClick: () => setMenuIsVisible(false), linkTo: "/policy", label: "user policy" }, "Policy"),
        loggedIn ? React.createElement(GoogleAuthButton, { style: googleButtonStyle }) : null)) : (React.createElement(MobileNavArea, null,
        React.createElement(Logo, { color: theme.linkColor, linkTo: "/", style: { width: "4rem", height: "4rem", position: "fixed", top: "1rem", left: "1rem" } }),
        loggedIn ? null : (React.createElement(GoogleAuthButton, { style: Object.assign(Object.assign({}, googleButtonStyle), { transform: "translateX(-50%)", position: "fixed", top: "1rem", left: "50%" }) })),
        React.createElement(CircularMenuButton, { label: "Menu", onClick: () => setMenuIsVisible(!menuIsVisible) },
            React.createElement(Grabbable, { style: { width: "45%", height: "45%" } }))));
}
