import { createTheme } from "@uiw/codemirror-themes";
import { tags as t } from "@lezer/highlight";
import { theme } from "../../theme";
import { hexWithOpacity } from "../../utils/constants";
const neonVommitTheme = createTheme({
    theme: "dark",
    settings: {
        background: theme.colors.black,
        foreground: theme.colors.greenNeon,
        caret: theme.colors.pink,
        selection: theme.colors.grey,
        selectionMatch: hexWithOpacity("#3c3c3c", .5),
        gutterBackground: theme.colors.black,
        gutterForeground: theme.colors.greyLight,
        gutterBorder: theme.colors.blackDark,
        lineHighlight: hexWithOpacity("#3c3c3c", .5),
    },
    styles: [
        { tag: t.comment, color: theme.colors.beige },
        { tag: t.variableName, color: theme.colors.purple },
        { tag: [t.string, t.special(t.brace)], color: theme.colors.blueLight },
        { tag: t.number, color: theme.colors.blueIntenseLight },
        { tag: t.bool, color: theme.colors.blueIntenseLight },
        { tag: t.null, color: theme.colors.blueIntenseLight },
        { tag: t.keyword, color: theme.colors.pink },
        { tag: t.operator, color: theme.colors.yellowNeon },
        { tag: t.className, color: theme.colors.greenNeon },
        { tag: t.definition(t.typeName), color: theme.colors.redSoft },
        { tag: t.typeName, color: theme.colors.redSoft },
        { tag: t.tagName, color: theme.colors.pink },
        { tag: t.attributeName, color: theme.colors.greenNeon },
        { tag: t.attributeValue, color: theme.colors.greenNeon },
        { tag: t.logicOperator, color: theme.colors.pink },
        { tag: t.definitionKeyword, color: theme.colors.pink },
        { tag: t.bracket, color: theme.colors.white },
        { tag: t.angleBracket, color: theme.colors.pink },
        { tag: t.paren, color: theme.colors.white },
        { tag: t.brace, color: theme.colors.white },
        { tag: t.regexp, color: theme.colors.white },
        { tag: t.punctuation, color: theme.colors.white },
        { tag: t.arithmeticOperator, color: theme.colors.yellowNeon },
    ],
});
export default neonVommitTheme;
