import styled from "styled-components";
import { MAX_MOBILE_SCREEN_SIZE } from "../../utils/constants";
export const BreadCrumbAreaContainer = styled.div `
  display: flex;
  justify-content: space-between;
  margin: 3rem 0;

  @media only screen and (max-width: ${MAX_MOBILE_SCREEN_SIZE}px) {
    display: none;
    opacity: 0;
  }
`;
export const BreadCrumbList = styled.div `
  display: flex;
  align-items: center;
`;
export const LinkDelimiter = styled.div `
  padding: 0;
  margin: 0 0.25rem 0 0.25rem;
  font-size: ${p => p.theme.fontSizes.small};
  color: ${p => p.theme.fontColor};
`;
export const BreadCrumbLabel = styled.div `
  margin: 0;
  font-size: ${p => p.theme.fontSizes.medium};
  color: ${p => p.theme.linkColor};
  white-space: nowrap;

  &:hover {
    text-decoration: underline dotted;
    color: ${p => p.theme.fontColor};
  }
`;
export const PageTitle = styled.h1 `
  margin: 0;
  font-size: ${p => p.theme.fontSizes.medium};
  color: ${p => p.theme.fontColor};
  white-space: nowrap;
`;
