import styled from "styled-components";
import Button from "../Button";
export const ContentDetailCardContainer = styled.div `
  border: 4px solid ${({ active, theme }) => (active ? theme.linkColor : theme.colors["greyLight"])};
  border-radius: ${p => p.theme.borderRadius};
  width: 25vw;
  padding: 1rem 0.75rem;
  display: flex;
  flex-direction: column;
`;
export const ContentDetailCardTitle = styled.h1 `
  font-weight: normal;
  color: ${p => p.theme.colors["greyLight"]};
  font-size: ${p => p.theme.fontSizes.large};
  margin: 0.5rem 0 0;
`;
export const ClearButton = styled(Button) `
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  padding: 0;
  &:hover {
    border: none;
  }
  &:focus {
    border: none;
  }
`;
export const DescriptionArea = styled.div `
  overflow-y: auto;
  overflow-x: clip;
  h2:first-of-type {
    margin: 1rem 0px !important;
  }
`;
export const SelectedContentName = styled.h2 `
  font-family: ${p => p.theme.fonts.sasSerif};
  font-size: ${p => p.theme.fontSizes.medium};
  color: ${p => p.theme.fontColor};
`;
export const SelectedContentDescription = styled.p `
  font-family: ${p => p.theme.fonts.sasSerif};
  font-size: ${p => p.theme.fontSizes.small};
  color: ${p => p.theme.fontColor};
  margin: 0px;
`;
