import React from "react";
import { NotFoundContainer, Alert, DirectToHome, PageContainer } from "./styled";
import DevYourself from "../../components/Logos/DevYourself";
import { theme } from "../../theme";
import StyledLink from "../../components/StyledLink";
export default function NotFoundView() {
    return (React.createElement(PageContainer, null,
        React.createElement(NotFoundContainer, null,
            React.createElement(DevYourself, { linkTo: "/", "aria-label": "go home", style: { width: "12rem", fill: theme.secondaryColor }, color: theme.secondaryColor }),
            React.createElement(Alert, null, "404"),
            React.createElement(DirectToHome, null,
                React.createElement("p", { style: { margin: 0 } }, "Sorry, the page you were looking for doesn't exist"),
                React.createElement(StyledLink, { to: "/" }, "Return to module tree"),
                "."))));
}
